export const getShowHideEventInfo = showFilter => {
  const type = !showFilter ? "hide" : "show"
  return JSON.stringify({
    eventAction: `literature:filters:${type} filter`,
    eventName: `literature:filters:${type} filter`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${type} filters`,
    internalLinkPosition: "literature",
    internalLinkType: `literature:${type}`,
    internalLinkZoneName: "literature:filters",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  })
}

export const backToTopEventInfo = JSON.stringify({
  eventAction: "literature:main content area:back to top",
  eventName: "literature:main content area:back to top",
  eventType: "navigation",
  eventMsg: "n/a",
  eventStatus: "n/a",
  internalLinkName: "back to top",
  internalLinkPosition: "literature landing",
  internalLinkType: "literature:navigation",
  internalLinkZoneName: "literature:main content area",
  internalLinkURL: "n/a",
  clickInternalLinks: "true",
})

export const loadMoreEventInfo = JSON.stringify({
  eventAction: "literature landing:article list:load more",
  eventName: "literature landing:article list:load more",
  eventType: "navigation",
  eventMsg: "n/a",
  eventStatus: "n/a",
  internalLinkName: "load more",
  internalLinkPosition: "literature landing",
  internalLinkType: "literature landing:load more",
  internalLinkZoneName: "literature landing:article list",
  internalLinkURL: "n/a",
  clickInternalLinks: "true",
})

export const downloadEventInfo = title => {
  return JSON.stringify({
    eventAction: "literature pdf download",
    eventName: "literature pdf download",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "download",
    internalLinkPosition: "literature",
    internalLinkType: `literature :${title} `,
    internalLinkZoneName: "literature:main content area",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  })
}
