import React, { useState, useEffect, useCallback } from "react"
import debounce from "lodash/debounce"
import _isEmpty from "lodash/isEmpty"

import Button from "@/components/core/Button/Button"
import Checkbox from "@/components/core/Checkbox/Checkbox"
import Input from "@/components/core/Input/Input"
import Dropdown from "@/components/core/SelectDropdown/SelectDropdown"
import ReCaptcha from "@/components/core/ReCaptcha/ReCaptcha"
import RadioButton from "@/components/core/RadioButton/RadioButton"

import {
  getStateName,
  convertToPhoneNumber,
} from "@/components/RegisterOnlineProduct/v1/RegisterOnlineHelper"

import { validateZip } from "@/utils/location"

import {
  validateField,
  sanitizeInnerHtml,
  validatePhone,
  destructureAddress,
} from "@/utils/helper"

import { getAddressSuggestions } from "@/components/checkout/checkoutHelperFunction"
import {
  getRecaptchaAnalytics,
  addSubmitAnalytics,
  getEventInfo,
} from "@/components/KeySpecifier/v1/KeySpecifierAnalytics"

import styles from "@/components/KeySpecifier/v1/index.module.scss"

const KeySpecifierView = ({
  showModal = false,
  i18n = {},
  registerUser = () => {},
  loading = false,
  businessTypes = [],
  states = [],
  legalPages = "",
  countries = [],
  country = "",
  emailBrand = "",
}) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [emailAddress, setEmailAddress] = useState("")
  const [businessName, setBusinessName] = useState("")
  const [jobTitle, setJobTitle] = useState("")
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState("")
  const [streetAddress, setStreetAddress] = useState("")
  const [aptFloorSuite, setAptFloorSuite] = useState("")
  const [city, setCity] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [groupCode, setGroupCode] = useState("")
  const [firstNameError, setFirstNameError] = useState({
    show: false,
    message: "",
  })
  const [groupCodeError, setGroupCodeError] = useState({
    show: false,
    message: "",
  })
  const [lastNameError, setLastNameError] = useState({
    show: false,
    message: "",
  })
  const [emailAddressError, setEmailAddressError] = useState({
    show: false,
    message: "",
  })
  const [businessNameError, setBusinessNameError] = useState({
    show: false,
    message: "",
  })
  const [businessPhoneNumberError, setBusinessPhoneNumberError] = useState({
    show: false,
    message: "",
  })
  const [streetAddressError, setStreetAddressError] = useState({
    show: false,
    message: "",
  })
  const [cityError, setCityError] = useState({ show: false, message: "" })
  const [zipCodeError, setZipCodeError] = useState({ show: false, message: "" })
  const [policy1Error, setPolicy1Error] = useState({ show: false, message: "" })
  const [policy1, setPolicy1] = useState(false)
  const [policy2, setPolicy2] = useState(false)
  const [policy3, setPolicy3] = useState(false)
  const [type, setType] = useState("")
  const [subType, setSubType] = useState("")
  const [state, setState] = useState("")
  const [isShowSubType, setIsShowSubType] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [active, setActive] = useState(true)
  const [isAnchorEventLoaded, setAnchorEventLoaded] = useState(false)

  const {
    name: i18nName,
    description,
    termsAndConditions,
    privacyPolicy,
    subjectAccessRequest,
    termsOfService,
    yourInformation,
    businessInformation,
    primaryBusinessType,
    submit,
    businessAddress,
    emailAddress: i18nEmailAddress,
    policy1Error: i18nPolicy1Error,
    groupCodeError: i18nGroupCodeError,
    firstNameError: i18nFirstNameError,
    lastNameError: i18nLastNameError,
    businessNameError: i18nBusinessNameError,
    streetAddressError: i18nStreetAddressError,
    businessPhoneNumberError: i18nBusinessPhoneNumberError,
    cityError: i18nCityError,
    zipCodeError: i18nZipCodeError,
    codeInvalidError,
    firstName: i18nFirstName,
    lastName: i18nLastName,
    businessName: i18nBusinessName,
    jobTitle: i18nJobTitle,
    aptFloorSuite: i18nAptFloorSuite,
    city: i18nCity,
    zipCode: i18nZipCode,
    groupCode: i18nGroupCode,
    emailAddressError: i18nEmailAddressError,
    businessPhoneNumber: i18nBusinessPhoneNumber,
    streetAddress: i18nStreetAddress,
    state: i18nState,
    keySpecifierGroupCode,
    installingProductatBusinessAbove,
    yes,
    no,
    termsAndConditions1,
    getInspring,
    stay,
    need,
  } = i18n

  const {
    privacyPolicy: privacyPolicyLegal,
    subjectAccessRequest: subjectAccessRequestLegal,
    termsOfService: termsOfServiceLegal,
  } = legalPages

  useEffect(() => {
    if (showModal) {
      if (groupCode) {
        onBlurInput("groupCode", groupCode)
      }
      if (firstName) {
        onBlurInput("firstName", firstName)
      }
      if (lastName) {
        onBlurInput("lastName", lastName)
      }
      if (emailAddress) {
        validateEmail()
      }
      if (businessName) {
        onBlurInput("businessName", businessName)
      }
      if (businessPhoneNumber) {
        onBlurInput("businessPhoneNumber", businessPhoneNumber)
      }
      if (zipCode) {
        onBlurInput("zipCode", zipCode)
      }
      if (streetAddress) {
        onBlurInput("streetAddress", streetAddress)
      }
      if (city) {
        onBlurInput("city", city)
      }
    }
  }, [
    showModal,
    lastName,
    firstName,
    emailAddress,
    businessName,
    businessPhoneNumber,
    groupCode,
    streetAddress,
    zipCode,
    city,
  ])

  useEffect(() => {
    if (!showModal) {
      setFirstName("")
      setLastName("")
      setEmailAddress("")
      setBusinessName("")
      setJobTitle("")
      setType("")
      setSubType("")
      setBusinessPhoneNumber("")
      setStreetAddress("")
      setAptFloorSuite("")
      setCity("")
      setZipCode("")
      setState("")
      setGroupCode("")
      setPolicy1(false)
      setPolicy2(false)
      setPolicy3(false)
      clearError()
    } else if (!isAnchorEventLoaded) {
      setTimeout(() => {
        findElementAndAddEvent(
          "key-specifier__terms_policy1",
          "terms and conditions"
        )
        findElementAndAddEvent(
          "key-specifier__description1",
          "key specifier support"
        )
      }, 0)
    }
  }, [showModal])

  const validateEmail = async () => {
    const errorMessage = []
    const getElementIdList = []
    let valid = true
    if (await validateField("email", emailAddress)) {
      onBlurInput("emailAddress", emailAddress)
    } else {
      getElementIdList.push("emailAddress")
      setEmailAddressError({ show: true, message: i18nEmailAddressError })
      valid = false
      errorMessage.push(i18nEmailAddressError)
    }
    if (!valid) {
      onFocustSet(errorMessage, getElementIdList)
    }
  }

  const onFocustSet = (errorMessage, getElementIdList) => {
    addSubmitAnalytics(
      "failure",
      errorMessage.join("|").toLowerCase(),
      groupCode,
      policy1,
      policy2,
      policy3
    )
    document.getElementById(getElementIdList[0])?.focus()
    document.getElementById(`${getElementIdList[0]}-error`)?.focus()
  }

  const findElementAndAddEvent = (classes, type) => {
    const descriptionElm = document.getElementsByClassName(classes)
    if (descriptionElm?.length) {
      const aElm = descriptionElm[0].getElementsByTagName("a")
      for (const element of aElm) {
        const actualElm = element
        if (!actualElm.hasAttribute("data-gbh-data-layer")) {
          actualElm.classList.add("gbh-data-layer")
          actualElm.setAttribute(
            "data-gbh-data-layer",
            getEventInfo(type, actualElm.getAttribute("href"))
          )
          if (!isAnchorEventLoaded) {
            setAnchorEventLoaded(true)
          }
        }
      }
    }
  }

  useEffect(() => {
    setSubType("")
    if (!_isEmpty(type)) {
      const showSubType = businessTypes.find(el => el.text === type).value
      setIsShowSubType(Boolean(!_isEmpty(showSubType)))
    }
  }, [type])

  const clearError = () => {
    setGroupCodeError({ show: false, message: "" })
    setFirstNameError({ show: false, message: "" })
    setLastNameError({ show: false, message: "" })
    setEmailAddressError({ show: false, message: "" })
    setBusinessNameError({ show: false, message: "" })
    setBusinessPhoneNumberError({ show: false, message: "" })
    setStreetAddressError({ show: false, message: "" })
    setCityError({ show: false, message: "" })
    setZipCodeError({ show: false, message: "" })
  }

  const handleAddressChange = val => {
    setStreetAddress(val)
    getSuggestions(val)
  }

  const getSuggestions = useCallback(
    debounce(addr => {
      getAddressSuggestions(addr).then(suggData => setSuggestions(suggData))
    }, 300),
    []
  )

  const onSuggestionSelect = val => {
    setSuggestions([])
    const addr = destructureAddress(val)
    setStreetAddress(addr?.address)
    setCity(addr?.city)
    setZipCode(addr?.zipcode)
    setState(getStateName(addr?.state, country, countries))
    setZipCodeError({ show: false, message: "" })
    setCityError({ show: false, message: "" })
    setStreetAddressError({ show: false, message: "" })
  }

  const handleTermsPolicy = e => {
    setPolicy1(e.target.checked)
    if (e.target.checked) {
      setPolicy1Error(false)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    clearError()
    const errorMessage = []
    const getElementIdList = []
    let valid = true
    if (!policy1) {
      setPolicy1Error({ show: true, message: i18nPolicy1Error })
      valid = false
      errorMessage.push(i18nPolicy1Error)
    }
    if (!groupCode) {
      setGroupCodeError({ show: true, message: i18nGroupCodeError })
      getElementIdList.push("groupCode")
      valid = false
      errorMessage.push(i18nGroupCodeError)
    }
    if (!firstName || !(await validateField("name", firstName))) {
      getElementIdList.push("firstName")
      setFirstNameError({ show: true, message: i18nFirstNameError })
      valid = false
      errorMessage.push(i18nFirstNameError)
    }
    if (!lastName || !(await validateField("name", lastName))) {
      getElementIdList.push("lastName")
      setLastNameError({ show: true, message: i18nLastNameError })
      valid = false
      errorMessage.push(i18nLastNameError)
    }
    if (!emailAddress || !(await validateField("email", emailAddress))) {
      getElementIdList.push("emailAddress")
      setEmailAddressError({ show: true, message: i18nEmailAddressError })
      valid = false
      errorMessage.push(i18nEmailAddressError)
    }
    if (!businessName || !(await validateField("businessName", businessName))) {
      getElementIdList.push("businessName")
      setBusinessNameError({ show: true, message: i18nBusinessNameError })
      valid = false
      errorMessage.push(i18nBusinessNameError)
    }
    if (!businessPhoneNumber || !(await validatePhone(businessPhoneNumber))) {
      getElementIdList.push("businessPhoneNumber")
      setBusinessPhoneNumberError({
        show: true,
        message: i18nBusinessPhoneNumberError,
      })
      valid = false
      errorMessage.push(i18nBusinessPhoneNumberError)
    }
    if (!(await validateField("address", streetAddress))) {
      getElementIdList.push("streetAddress")
      setStreetAddressError({
        show: true,
        message: i18nStreetAddressError,
      })
      valid = false
      errorMessage.push(i18nStreetAddressError)
    }
    if (!(await validateField("city", city))) {
      getElementIdList.push("city")
      setCityError({ show: true, message: i18nCityError })
      valid = false
      errorMessage.push(i18nCityError)
    }
    if (!zipCode || zipCode.length < 5 || !(await validateZip(zipCode))) {
      getElementIdList.push("zipCode")
      setZipCodeError({ show: true, message: i18nZipCodeError })
      valid = false
      errorMessage.push(i18nZipCodeError)
    }
    if (!valid) {
      onFocustSet(errorMessage, getElementIdList)
    } else {
      const body = {
        groupCode: groupCode,
        emailBrand: emailBrand ?? "",
        business: {
          name: firstName + " " + lastName,
          email: emailAddress,
          addressLine1: streetAddress,
          addressLine2: aptFloorSuite,
          city: city,
          state: state,
          postalCode: zipCode,
          phone: businessPhoneNumber,
        },
        businessName: businessName,
        enrollKohlerKBKeySpecifierProgram: true,
        subscribeKohlerKBConsumer: true,
        subscribeKohlerKBCMRLNews: true,
        kitchenBathRecentSource: true,
        jobTitle: jobTitle,
        productInstalledAtBusiness: active,
        occupation: type,
        occupationOther: subType,
        installAddressLine1: streetAddress,
        installAddressLine2: aptFloorSuite,
        installCity: city,
        installState: state,
        installPostalCode: zipCode,
      }
      registerUser(
        body,
        () => setGroupCodeError({ show: true, message: codeInvalidError }),
        addSubmitAnalytics("", "", groupCode, policy1, policy2, policy3)
      )
    }
  }

  const checkDisabled = () => {
    if (isShowSubType) {
      if (
        firstName &&
        lastName &&
        emailAddress &&
        businessName &&
        type &&
        subType &&
        businessPhoneNumber &&
        streetAddress &&
        city &&
        state &&
        zipCode &&
        groupCode
      )
        return false
    } else if (
      firstName &&
      lastName &&
      emailAddress &&
      businessName &&
      type &&
      businessPhoneNumber &&
      streetAddress &&
      city &&
      state &&
      zipCode &&
      groupCode
    ) {
      return false
    }
    return true
  }

  const handleChange = e => {
    const name = e.target.getAttribute("name")
    let value
    if (name === i18nEmailAddress) {
      value = e.target.value.replace(/\s/, "")
    } else if (
      validateField("name", e.target.value) === false &&
      (name === i18nFirstName || name === i18nLastName)
    ) {
      const str = e.target.value.slice(0, -1)
      value = str.replace(/^\s/, "")
    } else {
      value = e.target.value.replace(/^\s/, "")
    }
    switch (name) {
      case i18nFirstName:
        setFirstName(value)
        break

      case i18nLastName:
        setLastName(value)
        break

      case i18nEmailAddress:
        setEmailAddress(value)
        break

      case i18nBusinessName:
        setBusinessName(value)
        break

      case i18nJobTitle:
        setJobTitle(value)
        break

      case i18nAptFloorSuite:
        setAptFloorSuite(value)
        break

      case i18nCity:
        setCity(value)
        break

      case i18nZipCode:
        setZipCode(value)
        break

      case i18nGroupCode:
        setGroupCode(value.toUpperCase())
        break

      default:
        break
    }
  }

  const onBlurInput = async (name, value) => {
    const setError = (errorName, errorValue) => {
      switch (name) {
        case "firstName":
          setFirstNameError(errorValue)
          break
        case "lastName":
          setLastNameError(errorValue)
          break
        case "emailAddress":
          setEmailAddressError(errorValue)
          break
        case "businessName":
          setBusinessNameError(errorValue)
          break
        case "businessPhoneNumber":
          setBusinessPhoneNumberError(errorValue)
          break
        case "streetAddress":
          setStreetAddressError(errorValue)
          break
        case "city":
          setCityError(errorValue)
          break
        case "zipCode":
          setZipCodeError(errorValue)
          break
        case "groupCode":
          setGroupCodeError(errorValue)
          break
        default:
          break
      }
    }

    switch (name) {
      case "firstName":
        return !value
          ? setError("firstName", { show: true, message: i18nFirstNameError })
          : setError("firstName", { show: false, message: "" })
      case "lastName":
        return !value
          ? setError("lastName", { show: true, message: i18nLastNameError })
          : setError("lastName", { show: false, message: "" })
      case "emailAddress":
        return !value || !(await validateField("email", value))
          ? setError("emailAddress", {
              show: true,
              message: i18nEmailAddressError,
            })
          : setError("emailAddress", { show: false, message: "" })
      case "businessName":
        return !value
          ? setError("businessName", {
              show: true,
              message: i18nBusinessNameError,
            })
          : setError("businessName", { show: false, message: "" })
      case "businessPhoneNumber":
        return !value || value.length < 14
          ? setError("businessPhoneNumber", {
              show: true,
              message: i18nBusinessPhoneNumberError,
            })
          : setError("businessPhoneNumber", { show: false, message: "" })
      case "streetAddress":
        return !value
          ? setError("streetAddress", {
              show: true,
              message: i18nStreetAddressError,
            })
          : setError("streetAddress", { show: false, message: "" })
      case "city":
        return !value
          ? setError("city", { show: true, message: i18nCityError })
          : setError("city", { show: false, message: "" })
      case "zipCode":
        return !value || value.length < 5
          ? setError("zipCode", { show: true, message: i18nZipCodeError })
          : setError("zipCode", { show: false, message: "" })
      case "groupCode":
        return !value
          ? setError("groupCode", { show: true, message: i18nGroupCodeError })
          : setError("groupCode", { show: false, message: "" })
      default:
        break
    }
  }

  return (
    <div className={`key-specifier ${styles.keySpecifierStyleWrapper}`}>
      <div className="key-specifier__header">
        <div className="key-specifier__title" id="key-specifier__title">
          {i18nName}
        </div>
      </div>
      <div
        className="key-specifier__description"
        id="key-specifier__description"
      >
        {description}
      </div>
      <div className="key-specifier__sub">{yourInformation}</div>
      <div className="key-specifier__personal-info-section">
        <Input
          id="firstName"
          type="text"
          value={firstName}
          maxLength={40}
          placeholder={`${i18nFirstName}*`}
          label={`${i18nFirstName}*`}
          name={i18nFirstName}
          onChange={handleChange}
          showError={firstNameError?.show}
          errorMessage={firstNameError?.message}
          onBlur={() => onBlurInput("firstName", firstName)}
        />
        <Input
          id="lastName"
          type="text"
          value={lastName}
          maxLength={40}
          placeholder={`${i18nLastName}*`}
          label={`${i18nLastName}*`}
          name={i18nLastName}
          onChange={handleChange}
          showError={lastNameError.show}
          errorMessage={lastNameError?.message}
          onBlur={() => onBlurInput("lastName", lastName)}
        />
        <Input
          id="emailAddress"
          type="text"
          value={emailAddress}
          maxLength={40}
          placeholder={`${i18nEmailAddress}*`}
          label={`${i18nEmailAddress}*`}
          name={i18nEmailAddress}
          onChange={handleChange}
          showError={emailAddressError?.show}
          errorMessage={emailAddressError?.message}
          onBlur={() => onBlurInput("emailAddress", emailAddress)}
        />
      </div>
      <div className="key-specifier__business-info-section">
        <div className="key-specifier__sub">{businessInformation}</div>
        <Input
          id="businessName"
          type="text"
          value={businessName}
          maxLength={40}
          placeholder={`${i18nBusinessName}*`}
          label={`${i18nBusinessName}*`}
          name={i18nBusinessName}
          onChange={handleChange}
          showError={businessNameError?.show}
          errorMessage={businessNameError?.message}
          onBlur={() => onBlurInput("businessName", businessName)}
        />
        <Input
          id="jobTitle"
          type="text"
          value={jobTitle}
          maxLength={40}
          placeholder={`${i18nJobTitle}`}
          label={`${i18nJobTitle}`}
          name={i18nJobTitle}
          onChange={handleChange}
        />
        <div className="key-specifier__drop">
          <Dropdown
            id="primaryBusinessType"
            data={businessTypes.map(el => el.text)}
            label={`${primaryBusinessType}*`}
            value={type}
            onChange={val => setType(val)}
          />
        </div>
        {isShowSubType && (
          <div className="key-specifier__drop">
            <Dropdown
              id="subType"
              data={
                type
                  ? businessTypes.find(el => el.text === type).value.split(",")
                  : []
              }
              label={type ? `Type of ${type}*` : `${subType}*`}
              value={subType}
              onChange={val => setSubType(val)}
            />
          </div>
        )}
        <Input
          id="businessPhoneNumber"
          type="text"
          value={businessPhoneNumber}
          maxLength={40}
          placeholder={`${i18nBusinessPhoneNumber}*`}
          label={`${i18nBusinessPhoneNumber}*`}
          name={i18nBusinessPhoneNumber}
          onChange={e =>
            setBusinessPhoneNumber(convertToPhoneNumber(e.target.value))
          }
          showError={businessPhoneNumberError?.show}
          errorMessage={businessPhoneNumberError?.message}
          onBlur={() => onBlurInput("businessPhoneNumber", businessPhoneNumber)}
        />
      </div>
      <div className="key-specifier__business-addr-section">
        <div className="key-specifier__sub">{businessAddress}</div>

        <Input
          id="streetAddress"
          type="text"
          value={streetAddress}
          maxLength={40}
          placeholder={`${i18nStreetAddress}*`}
          label={`${i18nStreetAddress}*`}
          name={i18nStreetAddress}
          onChange={e => handleAddressChange(e.target.value)}
          showError={streetAddressError?.show}
          errorMessage={streetAddressError?.message}
          suggestions={suggestions}
          onSelect={val => onSuggestionSelect(val)}
          onBlur={() => onBlurInput("streetAddress", streetAddress)}
        />
        <Input
          id="aptFloorSuite"
          type="text"
          value={aptFloorSuite}
          maxLength={40}
          placeholder={`${i18nAptFloorSuite}`}
          label={`${i18nAptFloorSuite}`}
          name={i18nAptFloorSuite}
          onChange={handleChange}
        />
        <Input
          id="city"
          type="text"
          value={city}
          maxLength={40}
          placeholder={`${i18nCity}*`}
          label={`${i18nCity}*`}
          name={i18nCity}
          onChange={handleChange}
          showError={cityError?.show}
          errorMessage={cityError?.message}
          onBlur={() => onBlurInput("city", city)}
        />
        <div className="key-specifier__state-zip">
          <div className="key-specifier__state">
            <Dropdown
              id="state"
              data={states.map(el => el.name)}
              label={`${i18nState}*`}
              value={state}
              minLength={20}
              onChange={val => setState(val)}
            />
          </div>
          <div className="key-specifier__zip">
            <Input
              id="zipCode"
              type="text"
              value={zipCode}
              maxLength={40}
              placeholder={`${i18nZipCode}*`}
              label={`${i18nZipCode}*`}
              name={i18nZipCode}
              onChange={handleChange}
              showError={zipCodeError?.show}
              errorMessage={zipCodeError?.message}
              onBlur={() => onBlurInput("zipCode", zipCode)}
            />
          </div>
        </div>
      </div>
      <div className="key-specifier__groupcode-section">
        <div className="key-specifier__sub">{keySpecifierGroupCode}</div>
        <Input
          id="groupCode"
          type="text"
          value={groupCode}
          maxLength={40}
          placeholder={`${i18nGroupCode}*`}
          label={`${i18nGroupCode}*`}
          name={i18nGroupCode}
          onChange={handleChange}
          showError={groupCodeError?.show}
          errorMessage={groupCodeError?.message}
          onBlur={() => onBlurInput("groupCode", groupCode)}
        />
      </div>

      <div className="key-specifier__description">
        {installingProductatBusinessAbove}
      </div>
      <div className="radio-wrapper">
        <RadioButton
          id={"installed-product"}
          name={"installed-product"}
          value={yes}
          checked={active}
          onClick={() => setActive(true)}
        />
        <RadioButton
          id={"uninstalled-product"}
          name={"installed-product"}
          value={no}
          checked={!active}
          onClick={() => setActive(false)}
        />
      </div>

      <div className="key-specifier__terms">
        <Checkbox
          checkBoxAria={termsAndConditions1}
          checked={policy1}
          onChange={e => handleTermsPolicy(e)}
        />
        <div className="key-specifier__terms_policy key-specifier__terms_policy1">
          {sanitizeInnerHtml(termsAndConditions)}
        </div>
      </div>
      {policy1Error?.show && (
        <div className="key-specifier__tnc">{policy1Error?.message}</div>
      )}
      <div className="key-specifier__terms">
        <Checkbox
          checked={policy2}
          onChange={e => setPolicy2(e.target.checked)}
        />
        {getInspring}
      </div>
      <div className="key-specifier__terms">
        <Checkbox
          checked={policy3}
          onChange={e => setPolicy3(e.target.checked)}
        />
        {stay}
      </div>
      <div className="key-specifier__description1">
        {sanitizeInnerHtml(need)}
      </div>

      <div className="key-specifier__button">
        <Button
          type="black"
          role="button"
          loading={loading}
          flexible
          label={submit}
          onClick={handleSubmit}
          disabled={checkDisabled()}
        />
      </div>
      <ReCaptcha getAnalyticsData={getRecaptchaAnalytics} />
      <div className="key-specifier__policies">
        <a
          tabIndex="0"
          href={privacyPolicyLegal}
          className="gbh-data-layer"
          data-gbh-data-layer={getEventInfo(
            "privacy policy",
            privacyPolicyLegal
          )}
        >
          {privacyPolicy}
        </a>
        <a
          tabIndex="0"
          href={subjectAccessRequestLegal}
          className="gbh-data-layer"
          data-gbh-data-layer={getEventInfo(
            "subject access request",
            subjectAccessRequestLegal
          )}
        >
          {subjectAccessRequest}
        </a>
        <a
          tabIndex="0"
          href={termsOfServiceLegal}
          className="gbh-data-layer"
          data-gbh-data-layer={getEventInfo(
            "terms of service",
            termsOfServiceLegal
          )}
        >
          {termsOfService}
        </a>
      </div>
    </div>
  )
}

export default KeySpecifierView
