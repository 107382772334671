import React, { useState } from "react"
import useLiteraturei18n from "@/i18n/useLiteraturei18n"
import { PRODUCTS_PER_PAGE } from "@/constants/index"
import LiteratureView from "@/components/Literature/v1/Literature.view"
import styles from "@/components/Literature/v1/Literature.view/index.module.scss"
import pageTitleStyle from "@/components/core/PageTitle/index.module.scss"

const Literature = ({ data: authorData = {} }) => {
  const [total, setTotal] = useState(0)
  const productsPerPage = PRODUCTS_PER_PAGE

  const staticTexts = useLiteraturei18n(authorData)
  const updateTotal = total => setTotal(total)

  const displayShare =
    authorData?.enableSocialShare !== undefined
      ? authorData.enableSocialShare
      : true

  return (
    <div className={`${styles.literatureWrapper}`}>
      <main className="literature">
        <div className="container kf-react-container">
          <div className={pageTitleStyle.pageTitleWrapper}>
            <p className="kf-page-title__total">
              {total ? `${total} ${staticTexts.resources}` : null}
            </p>
          </div>
        </div>
        <LiteratureView
          staticTexts={staticTexts}
          displayShare={displayShare}
          displayPrint={true}
          productsPerPage={productsPerPage}
          setTotal={updateTotal}
          type="Literature"
        />
      </main>
    </div>
  )
}
export default Literature
