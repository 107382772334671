import React, { useEffect, useRef, useState } from "react"
import { isMobile, isTablet } from "react-device-detect"
import Sticky from "react-sticky-el"
import cx from "classnames"
import Sort from "@/components/ProductList/v2/Sort"
import filterIcon from "@/public/icons/filter.svg"
import useWindowResize from "@/hooks/useWindowResize"
import {
  searchdropdowncloseAnalytics,
  showFIlterGetEventInfo,
} from "@/components/TechnicalSpecs/v1/analytics"
import SearchDropdown from "@/components/TechnicalSpecs/v2/SearchDropdown"

const StickyNavHeader = props => {
  const {
    staticTexts = {},
    page = "",
    sorts = [],
    suggestions = [],
    totalResults = "",
    selectedSort = {},
    onSort = () => {},
    showFilter = false,
    searchKeyword = "",
    onSearch = () => {},
    onSuggest = () => {},
    setShowFilter = () => {},
    clearSuggestions = () => {},
  } = props

  const {
    searchPlaceHolder = "",
    results = "",
    noResults = "",
    showFilter: showFilterText = "",
    hideFilter = "",
  } = staticTexts

  const [width] = useWindowResize()

  const [navSticky, setNavSticky] = useState(false)
  const filterModalWindow = useRef(null)

  const filterButtonClass = cx({
    "product-listing__filter-toggle-text": true,
    "product-listing__filter-toggle-text--show": !showFilter,
  })

  useEffect(() => {
    if (showFilter && (width < 992 || isMobile || isTablet)) {
      filterModalWindow?.current?.focus()
    } else if (!showFilter && (width < 992 || isMobile || isTablet)) {
      document.getElementById("showFilter")?.focus()
    }
  }, [showFilter])

  const renderSearchDropdown = (ccls = "") => (
    <div className={`technical-specs__search${ccls && `-${ccls}`}`}>
      <SearchDropdown
        id="tech-specs-search"
        closeAnalyticsData={searchdropdowncloseAnalytics}
        placeholder={`${searchPlaceHolder}`}
        onSelect={onSearch}
        onType={onSuggest}
        value={searchKeyword}
        data={suggestions}
        clearSuggestions={clearSuggestions}
      />
    </div>
  )

  return (
    <Sticky
      onFixedToggle={() => setNavSticky(!navSticky)}
      className="product-listing__sticky-nav-wrap"
      stickyStyle={{ zIndex: 9 }}
    >
      <div
        className={`container-fluid product-listing__sticky-nav ${
          navSticky && "product-listing__sticky-nav--shadow"
        }`}
      >
        <div className="product-listing__row technical-specs__search-flex">
          {navSticky && width < 813 && (
            <div className={`technical-specs__search-wrap sticky-order-search`}>
              {renderSearchDropdown("ml")}
            </div>
          )}
          <div className="container kf-react-plp-container">
            <div className="product-listing__row">
              {!navSticky && width < 625 && renderSearchDropdown("ml")}
              <div
                className={`technical-specs__sticky-search-pd ${
                  navSticky && "--sticky-pd-nav"
                } product-listing__col product-listing__controls`}
              >
                <div className="product-listing__row technical-specs__search-wrap-parent">
                  <h2 className="product-listing__total">
                    {totalResults === "..."
                      ? totalResults
                      : totalResults
                      ? totalResults + " " + results
                      : noResults}
                  </h2>
                  {width > 625 && !navSticky ? (
                    <div
                      className={`technical-specs__search-wrap sticky-order-search`}
                    >
                      {renderSearchDropdown()}
                    </div>
                  ) : null}
                  {width > 812 && navSticky && (
                    <div
                      className={`technical-specs__search-wrap sticky-order-search`}
                    >
                      {renderSearchDropdown()}
                    </div>
                  )}
                </div>
                <section className="sorting-section">
                  <button
                    id="showFilter"
                    aria-label={!showFilter ? showFilterText : hideFilter}
                    className="product-listing__filter-toggle gbh-data-layer"
                    data-gbh-data-layer={showFIlterGetEventInfo(showFilter)}
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    {width > 991 ? (
                      <ul className={filterButtonClass}>
                        <li className={showFilter ? "show" : "hide"}>
                          {hideFilter}
                        </li>
                        <li className={!showFilter ? "show" : "hide"}>
                          {showFilterText}
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                    <img
                      src={filterIcon?.src}
                      className="product-listing__filter-toggle-icon"
                      alt="Toggle Filter"
                    />
                  </button>
                  {width > 812 ? (
                    <Sort
                      tabIndex="0"
                      page={page || "technicalspecs"}
                      data={sorts}
                      texts={staticTexts}
                      selectedSort={selectedSort}
                      onChange={onSort}
                      isSearch={true}
                    />
                  ) : null}
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  )
}
export default StickyNavHeader
