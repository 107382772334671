import { useTranslation } from "next-i18next"

const useFindStorei18n = ({ authorData }) => {
  const { t } = useTranslation()
  const i18n = {
    userMyLocation: authorData.userMyLocation
      ? authorData.userMyLocation
      : t("kf.findstore.label.userMyLocation"),
    kohlerStores: authorData.kohlerStores
      ? authorData.kohlerStores
      : t("kf.findstore.label.kohlerStores"),
    showrooms: authorData.showrooms
      ? authorData.showrooms
      : t("kf.findstore.label.showrooms"),
    showroom: authorData.showroom
      ? authorData.showroom
      : t("kf.findstore.label.showrooms"),
    kohlerStore: authorData.kohlerStore
      ? authorData.kohlerStore
      : t("kf.findstore.label.kohlerStore"),
    annsacksShowroom: authorData.annsacksShowroom
      ? authorData.annsacksShowroom
      : t("kf.findstore.label.annsacksShowroom"),
    partsDealer: authorData.partsDealer
      ? authorData.partsDealer
      : t("kf.findstore.label.partsDealer"),
    homeCenter: authorData.homeCenter
      ? authorData.homeCenter
      : t("kf.findStore.label.homeCenter"),
    genuinePartsDealers: authorData.genuinePartsDealers
      ? authorData.genuinePartsDealers
      : t("kf.findstore.label.genuinePartsDealers"),
    kohler: t("kf.findStoreUtilNav.label.kohler"),
    annSacks: authorData.annSacks
      ? authorData.annSacks
      : t("kf.findstore.label.annSacks"),
    annSacksText: authorData.annSacksText
      ? authorData.annSacksText
      : t("kf.findstore.label.annSacksText"),
    countryPlaceholder: authorData.countryPlaceholder
      ? authorData.countryPlaceholder
      : t("kf.findstore.label.countryPlaceholder"),
    locationErrormessage: authorData.locationErrormessage
      ? authorData.locationErrormessage
      : t("kf.findstore.label.locationErrormessage"),
    clearAll: t("kf.plp.label.clearAll"),
    viewList: authorData.viewList
      ? authorData.viewList
      : t("kf.findstore.label.viewList"),
    viewMap: authorData.viewMap
      ? authorData.viewMap
      : t("kf.findstore.label.viewMap"),
    applyFilters: authorData.applyFilters
      ? authorData.applyFilters
      : t("kf.findstore.label.applyFilters"),
    filters: t("kf.plp.label.filters"),
    viewStoreDetail: t("kf.findStoreUtilNav.label.viewStoreDetail"),
    storeDetail: t("kf.findStoreUtilNav.label.storeDetail"),
    miles: authorData.miles ? authorData.miles : t("kf.findstore.label.miles"),
    away: authorData.away ? authorData.away : t("kf.findstore.label.away"),
    browseAllStore: authorData.browseAllStore
      ? authorData.browseAllStore
      : t("kf.findstore.label.browseAllStore"),
    setAsMyStore: t("kf.findStoreUtilNav.label.setAsMystore"),
    home: authorData.home ? authorData.home : t("kf.findstore.label.home"),
    findStore: authorData.findstore
      ? authorData.findstore
      : t("kf.findstore.label.findstore"),
    kohlerStoreImgAlt: t("kf.findstore.label.kohlerStoreImgAlt"),
    viewResults: t("kf.plp.label.viewResults"),
    view: t("kf.plp.label.view"),
    results: t("kf.plp.label.results"),
    filterImgAlt: t("kf.findstore.label.filterImgAlt"),
    myStore: t("kf.findStoreUtilNav.label.myStore"),
    retailStore: authorData.retailStore
      ? authorData.retailStore
      : t("kf.findstore.label.retailStore"),
    retailMobileText: t("kf.findstore.label.retailMobileText"),
    infoModalTitle: t("kf.findStoreUtilNav.messageTitleZipcode"),
    ariaLabel: {
      enterZipcode: t("kf.findStoreUtilNav.aria.label.enterZipCode"),
      showFilter: t("kf.findstore.aria.label.showFilter"),
      hideFilter: t("kf.findstore.aria.label.hideFilter"),
      browseAll: t("kf.findstore.aria.label.browseAll"),
      contact: phone => t("kf.findStore.aria.label.contact", { phone }),
      mapMarker: t("kf.findstore.aria.label.mapMarker"),
      mapGuidelines: t("kf.findstore.aria.label.mapGuidelines"),
      currentPage: t("kf.pdp.label.aria.currentPage"),
      page: t("kf.pdp.label.aria.page"),
    },
    messageZipcode: t("kf.findStoreUtilNav.MessageZipcode"),
    continue: t("kf.checkout.label.continue"),
    expanded: t("kf.productTab.aria.expanded"),
    collapsed: t("kf.productTab.aria.collapsed"),
    filterTitle: (facetName, facetCount) =>
      t("kf.plp.ariaLabel.filterTitle", {
        facetName: facetName,
        facetCount: facetCount,
      }),
  }

  return i18n
}

export default useFindStorei18n
