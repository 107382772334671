import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import { aemAxiosInstance } from "@/constants/api"
import { getConfig } from "@/constants/config"
import { GLOBAL_CONFIGURATION_ENDPOINT } from "@/constants/index"
import {
  getGenericListPath,
  getStoreCountriesList,
  selectGenericState,
} from "@/store/features/genericSlice"
import {
  locationState,
  setMyStore,
  setStoreAddress,
} from "@/store/features/locationSlice"
import { getStoreDataById } from "@/utils/location"
import BrowseAllStoresView from "@/components/BrowseAllStores/v1/BrowseAllStores.view"
import useMystorei18n from "@/i18n/useMystorei18n"
import { storeFilterData } from "@/utils/helper"

const BrowseAllStores = props => {
  const { data: authorData } = props
  const { storeCountryListPath } = useSelector(selectGenericState)
  const { myStoreId, browseLocationsStoreFilterData } =
    useSelector(locationState)
  const dispatch = useDispatch()
  const [browseStoreDetails, setBrowseStoreDetails] = useState({})
  const [browseStoreFilterData] = useState(
    _get(browseLocationsStoreFilterData, "storefilter", {})
  )
  const [countries, setCountries] = useState([])
  const [browseAllStoreCheckedId, setBrowseAllStoreCheckedId] = useState(
    localStorage.getItem("myStoreId") ?? 0
  )
  const [facets, setFacets] = useState({})
  const [info, setInfo] = useState({})
  const staticTexts = useMystorei18n({
    authorData,
  })

  useEffect(() => {
    getConfig().then(async config => {
      const locale = _get(config, "internationalization.locale", "")
      const currencySign = _get(config, "internationalization.currencySign", "")
      const currencyCode = _get(config, "internationalization.currencyCode", "")
      const browseStoreDetailsUrl = _get(
        config,
        "general.storeDetailsServletPath",
        ""
      )
      const findStores = _get(config, "apiEndpoints.findStores", "")
      const siteName = _get(config, "general.siteName", "")
      aemAxiosInstance({
        url: GLOBAL_CONFIGURATION_ENDPOINT,
        params: {
          path: browseStoreDetailsUrl,
        },
      })
        .then(response => {
          if (response && response.data) {
            setBrowseStoreDetails(response.data)
          }
        })
        .catch(err => {
          console.log("Failed to get compare attributes JSON file", err)
        })

      const info = {
        locale: locale,
        currencySign: currencySign,
        currencyCode: currencyCode,
        findStores: findStores,
        siteName: siteName,
      }
      setInfo(info)
    })
  }, [])

  useEffect(() => {
    dispatch(getGenericListPath())
  }, [])
  useEffect(() => {
    if (storeCountryListPath) {
      dispatch(getStoreCountriesList())
        .unwrap()
        .then(res => {
          setCountries(res)
        })
    }
  }, [storeCountryListPath])
  useEffect(() => {
    if (myStoreId !== browseAllStoreCheckedId) {
      localStorage.setItem("myStoreId", myStoreId)
      setBrowseAllStoreCheckedId(myStoreId)
    }
  }, [myStoreId])

  useEffect(() => {
    if (browseStoreFilterData) {
      const updatedFacets = storeFilterData(browseStoreFilterData)
      setFacets(updatedFacets)
    }
  }, [browseStoreFilterData])
  useEffect(() => {
    if (myStoreId !== 0 && !_isEmpty(info)) {
      getUtilityDataById()
    } else {
      dispatch(setStoreAddress({}))
    }
  }, [myStoreId, info])
  const { findStores = "" } = info

  const getUtilityDataById = async () => {
    const utilityData = await getStoreDataById({
      myStoreId: myStoreId,
      findStores: findStores,
      siteName: info.siteName,
    })
    dispatch(setStoreAddress(utilityData))
  }

  const handleBrowseAllStoreCheck = entity => {
    localStorage.setItem("myStoreId", entity)
    dispatch(setMyStore(entity))
    setBrowseAllStoreCheckedId(entity)
  }

  return (
    <div className="browseAllstores_Wrapper">
      <BrowseAllStoresView
        staticTexts={staticTexts}
        facets={facets}
        countries={countries}
        browseStoreDetails={browseStoreDetails}
        findStores={findStores}
        handleBrowseAllStoreCheck={entity => handleBrowseAllStoreCheck(entity)}
        browseAllStoreCheckedId={browseAllStoreCheckedId}
      />
    </div>
  )
}

export default BrowseAllStores
