import React, { useRef, useState, useEffect } from "react"
import Sticky from "react-sticky-el"
import { isMobile } from "react-device-detect"
import cx from "classnames"
import Accordion from "@/components/core/Accordion/Accordion"
import filterIcon from "@/public/icons/filter.svg"
import { apim } from "@/constants/api"
import { pageUrl, findAllStores } from "@/constants"
import Filter from "@/components/FindStoreLanding/Filter"
import { getShortenedUrl, removeMavenoidChat } from "@/utils/helper"
import Checkbox from "@/components/core/Checkbox/Checkbox"
import styles from "@/components/BrowseAllStores/v1/BrowseAllStores.view/index.module.scss"
import SelectDropdown from "@/components/core/SelectDropdown/SelectDropdown"
import { countryChangeDataAnalytics } from "@/components/BrowseAllStores/v1/browseStoreAnalytics"

const BrowseAllStoresView = props => {
  const {
    countries = [],
    staticTexts = {},
    facets = {},
    browseStoreDetails = {},
    handleBrowseAllStoreCheck = () => {},
    browseAllStoreCheckedId,
  } = props
  const [showFilter, setShowFilter] = useState(isMobile ? false : true)
  const [navSticky, setNavSticky] = useState(false)
  const [data, setData] = useState([])
  const [countryChange, setCountryChange] = useState("")
  const [activeFilters, setActiveFilters] = useState("")
  const [storeData, setStoreData] = useState([])
  const [selectedFilterCount, setSelectedFilterCount] = useState(0)
  const [shortenedUrls, setShortenedUrls] = useState({})
  const filterClass = cx({
    "view-all-stores__filter": true,
    "view-all-stores__filter--hide": isMobile ? false : !showFilter,
    "view-all-stores__filter--modal": isMobile ? showFilter : false,
  })
  const storeListClass = cx({
    "view-all-stores__tile-col": true,
    "view-all-stores__tile-col--full-width": !showFilter,
  })
  const locatioContainer = useRef(null)

  useEffect(() => {
    if (countryChange) {
      getAllStoreData(countryChange)
    }
  }, [countryChange])

  useEffect(() => {
    if (countries && countries.length && countryChange !== countries[0]?.text) {
      setCountryChange(countries[0]?.text)
    }
  }, [countries])

  useEffect(() => {
    const filteredData = storeData.filter(
      item =>
        activeFilters.length === 0 ||
        activeFilters.every(filter => item[filter])
    )
    const groupedList = groupBy(filteredData, "StateLong")
    setSelectedFilterCount(filteredData?.length)
    setData(groupedList)
  }, [storeData, activeFilters])

  useEffect(() => {
    Object.entries(data)
      .sort()
      .forEach(([district, stores]) => {
        stores.forEach(storeItem => {
          const url = browseStoreDetails[storeItem.BPnumber] ?? pageUrl
          fetchShortenedUrl(url)
        })
      })
  }, [data, browseStoreDetails])

  const fetchShortenedUrl = async url => {
    try {
      const shortenedUrl = await getShortenedUrl(url)
      setShortenedUrls(prevUrls => ({ ...prevUrls, [url]: shortenedUrl }))
    } catch (error) {
      console.error("Error occurred while fetching shortened URL:", error)
    }
  }

  const splitBrowseQuery = queries => {
    let queryData = []
    queries.length > 0 &&
      queries.forEach(q => {
        if (q.query && q.query.length > 0) {
          queryData = [...queryData, ...q.query]
        }
      })
    setActiveFilters(queryData)
    locatioContainer.current?.scrollIntoView()
  }

  const countryMap = {
    ["United States of America"]: "United States",
    Canada: "Canada",
  }

  const getAllStoreData = async cnt => {
    const countryText = cnt || countryChange
    const country =
      countryMap[[countryText]] || countryMap[Object.keys(countryMap)[0]]
    const payload = {
      params: {
        country,
        brand: "kohler",
        locationType: "kohler store",
        queryType: "all-stores",
        offset: 0,
        limit: 50,
      },
    }
    try {
      const { status, data = {} } = await apim.get(findAllStores, payload)
      if (status === 200) {
        setStoreData(data.info)
      }
      countryChangeDataAnalytics({ status, value: country })
      return { status }
    } catch (error) {
      countryChangeDataAnalytics({ status: 400, value: country })
      return { status: 400, message: error.message }
    }
  }

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      ;(result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      )
      return result
    }, {})
  }

  const formatPhoneNumber = phoneNumberString => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "")
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3]
    }
    return null
  }

  const dynamicSort = property => {
    const sortOrder = 1
    return function (a, b) {
      const result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
      return result * sortOrder
    }
  }

  const handleChange = async value => {
    setCountryChange(value)
  }

  const getEventInfo = (storeItem, district, isForStoreName) => {
    const stateName = district.toLowerCase()
    const cityName = storeItem.Locality?.toLowerCase()
    const storeName = storeItem.LocationName?.toLowerCase()

    const type = !isForStoreName
      ? props.browseAllStoreCheckedId !== storeItem.EntityID
        ? "set as preferred store"
        : "unset as preferred store"
      : storeName
    const link = props.browseStoreDetails[storeItem.BPnumber] ?? "/"
    const internalLinkURL = isForStoreName
      ? (link.indexOf("http") === -1 ? window.location.origin : "") + link
      : "n/a"
    return JSON.stringify({
      clickInternalLinks: "true",
      eventAction: `find a store:browse all stores:${
        isForStoreName ? "store name" : type
      }`,
      eventName: `find a store:browse all stores:${
        isForStoreName ? "store name" : type
      }`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: type,
      internalLinkPosition: "locations",
      internalLinkType: `find a store:${
        !isForStoreName ? storeName + ":" : ""
      }${cityName}:${stateName}`,
      internalLinkURL,
      internalLinkZoneName: "find a store:browse all stores",
    })
  }

  return (
    <div className={styles.myStoreWrapper}>
      <div className="view-all-stores">
        {/* view-all-stores */}
        <div className="kf-react-container view-all-stores__container">
          <div className="row no-gutters">
            <div className="col-12 col-lg-3 view-all-stores__sticky-container">
              {!isMobile ? (
                <Sticky
                  onFixedToggle={() => setNavSticky(!navSticky)}
                  className="view-all-stores__sticky-nav-wrap"
                  boundaryElement=".view-all-stores__sticky-container"
                >
                  <div className="view-all-stores__countries-list">
                    <SelectDropdown
                      label={staticTexts.countryLabel}
                      data={countries.map(item => item.text)}
                      value={countryChange}
                      onChange={handleChange}
                      id="browse-all-country-list-desktop"
                    />
                  </div>

                  <div className="view-all-stores__controls">
                    <img
                      src={filterIcon?.src}
                      className="view-all-stores__filter-toggle-icon"
                      alt={staticTexts.toggleFilter}
                    />
                    <span>{staticTexts.filters}</span>
                  </div>
                  <div className={filterClass}>
                    <div className="product-list__filter--sticky">
                      <Filter
                        texts={staticTexts}
                        facets={facets}
                        onFilter={curfilters => splitBrowseQuery(curfilters)}
                        close={setShowFilter}
                        displayShare={false}
                        showFilter={showFilter}
                        selectedFilterCount={selectedFilterCount}
                      />
                    </div>
                  </div>
                </Sticky>
              ) : (
                <React.Fragment>
                  <div className="view-all-stores__countries-list">
                    <SelectDropdown
                      label={staticTexts.countryLabel}
                      data={countries.map(item => item.text)}
                      value={countryChange}
                      onChange={handleChange}
                      id="browse-all-country-list-mobile"
                    />
                  </div>
                  <Sticky
                    onFixedToggle={() => setNavSticky(!navSticky)}
                    className="view-all-stores__sticky-nav-wrap"
                    boundaryElement=".view-all-stores__container"
                  >
                    <div className="view-all-stores__controls">
                      <button
                        className="view-all-stores__filter-toggle"
                        onClick={() => {
                          isMobile && removeMavenoidChat()
                          setShowFilter(!showFilter)
                        }}
                      >
                        <img
                          src={filterIcon?.src}
                          className="view-all-stores__filter-toggle-icon"
                          alt="Toggle Filter"
                        />
                        <span>{staticTexts.filters}</span>
                      </button>
                    </div>
                  </Sticky>
                  <div className={filterClass}>
                    <div className="product-list__filter--sticky">
                      <Filter
                        texts={staticTexts}
                        facets={facets}
                        onFilter={curfilters => splitBrowseQuery(curfilters)}
                        close={setShowFilter}
                        displayShare={false}
                        showFilter={showFilter}
                        selectedFilterCount={selectedFilterCount}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>

            <div className="col-12 col-lg-9 view-all-stores__data-container">
              <div ref={locatioContainer} className={storeListClass}>
                {Object.entries(data)
                  .sort()
                  .map(([district, stores], index) => (
                    <div className="view-all-stores__state-list" key={index}>
                      <Accordion
                        contentOverflow="visible"
                        title={district}
                        isOpen={!isMobile ? true : false}
                        triggerStyle={!isMobile ? { display: "none" } : null}
                        containerElementProps={{ id: "viewAllStore" }}
                      >
                        {!isMobile && (
                          <h2 className="view-all-stores__state-name">
                            {district}
                          </h2>
                        )}
                        <div className="row">
                          {stores
                            .sort(dynamicSort("Locality"))
                            .map((storeItem, idx) => {
                              return (
                                <div
                                  className="col-12 col-md-6 col-lg-4"
                                  key={idx}
                                >
                                  <h3 className="view-all-stores__store-name">
                                    {storeItem.Locality}
                                  </h3>
                                  <a
                                    href={
                                      shortenedUrls[
                                        props.browseStoreDetails[
                                          storeItem.BPnumber
                                        ] ?? pageUrl
                                      ]
                                    }
                                    className="view-all-stores__store-description gbh-data-layer"
                                    data-gbh-data-layer={getEventInfo(
                                      storeItem,
                                      district,
                                      true
                                    )}
                                  >
                                    {storeItem.LocationName}
                                  </a>
                                  <span className="view-all-stores__store-address-primary">
                                    {storeItem.AddressLine}
                                  </span>
                                  {storeItem.AddressLine2 && (
                                    <span className="view-all-stores__store-address-secondary">
                                      {storeItem.AddressLine2}
                                    </span>
                                  )}
                                  <span className="view-all-stores__store-address-zone">
                                    {storeItem.Locality},{" "}
                                    {storeItem.AdminDistrict}{" "}
                                    {storeItem.PostalCode}
                                  </span>
                                  <span className="view-all-stores__store-mobile">
                                    {formatPhoneNumber(storeItem.Phone)}
                                  </span>
                                  {storeItem.ContactEmail && (
                                    <a
                                      href={`mailto:${storeItem.ContactEmail}`}
                                      className="view-all-stores__store-email"
                                    >
                                      {storeItem.ContactEmail}
                                    </a>
                                  )}
                                  <span className="view-all-stores__set-as-my-store">
                                    <Checkbox
                                      analyticsData={getEventInfo(
                                        storeItem,
                                        district
                                      )}
                                      checked={
                                        browseAllStoreCheckedId ===
                                        storeItem.EntityID
                                      }
                                      value={
                                        browseAllStoreCheckedId ===
                                        storeItem.EntityID
                                          ? staticTexts.myStore
                                          : staticTexts.setAsMyStore
                                      }
                                      id={`view-all-stores__set-store${storeItem.BPnumber}`}
                                      onChange={e =>
                                        handleBrowseAllStoreCheck(
                                          e.target.checked
                                            ? storeItem.EntityID
                                            : 0
                                        )
                                      }
                                    />
                                  </span>
                                </div>
                              )
                            })}
                        </div>
                      </Accordion>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrowseAllStoresView
