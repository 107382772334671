import { useTranslation } from "next-i18next"
import { sanitizeText } from "@/utils/helper"

const useFriendsAndFamilyi18n = ({
  authorData: {
    description,
    description1,
    successTitle,
    successDescription,
    successCtaLabel,
    termsAndConditions,
    helpContact,
    getIdeas,
    codeInvalidError,
    code,
    codeError,
    name,
  } = {},
}) => {
  const { t } = useTranslation()
  const i18n = {
    description: description
      ? sanitizeText(description)
      : t("kf.friendsandfamily.label.description"),
    description1: description1
      ? sanitizeText(description1)
      : t("kf.friendsandfamily.label.description1"),
    successTitle: successTitle
      ? sanitizeText(successTitle)
      : t("kf.friendsandfamily.label.successTitle"),
    successMessage: successDescription
      ? sanitizeText(successDescription)
      : t("kf.friendsandfamily.label.successMessage"),
    continueShopping: successCtaLabel
      ? sanitizeText(successCtaLabel)
      : t("kf.friendsandfamily.label.continueShopping"),
    termsAndConditions:
      termsAndConditions || t("kf.friendsandfamily.label.termsAndConditions"),
    helpContact: helpContact || t("kf.friendsandfamily.label.helpContact"),
    getIdeas: getIdeas
      ? sanitizeText(getIdeas)
      : t("kf.friendsandfamily.label.getIdeas"),
    privacyPolicy: t("kf.friendsandfamily.label.privacyPolicy"),
    subjectAccessRequest: t("kf.friendsandfamily.label.subjectAccessRequest"),
    termsOfService: t("kf.friendsandfamily.label.termsOfService"),
    codeInvalidError: codeInvalidError
      ? sanitizeText(codeInvalidError)
      : t("kf.friendsandfamily.label.codeInvalidError"),
    firstName: t("kf.friendsandfamily.label.firstName"),
    lastName: t("kf.friendsandfamily.label.lastName"),
    email: t("kf.friendsandfamily.label.email"),
    zipcode: t("kf.friendsandfamily.label.zipcode"),
    firstNameError: t("kf.friendsandfamily.label.firstNameError"),
    lastNameError: t("kf.friendsandfamily.label.lastNameError"),
    emailError: t("kf.friendsandfamily.label.emailError"),
    zipcodeError: t("kf.friendsandfamily.label.zipcodeError"),
    validZipcode: t("kf.friendsandfamily.label.validZipcode"),
    code: code ? sanitizeText(code) : t("kf.friendsandfamily.label.code"),
    groupCodeError: codeError
      ? sanitizeText(codeError)
      : t("kf.friendsandfamily.label.groupCodeError"),
    termsError: t("kf.friendsandfamily.label.termsError"),
    name: name ? sanitizeText(name) : t("kf.friendsandfamily.label.name"),
    submit: t("kf.friendsandfamily.label.submit"),
    genericError: t("kf.error.message.genericError"),
  }

  return i18n
}

export default useFriendsAndFamilyi18n
