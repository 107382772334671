import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import useIsSsr from "@/hooks/useIsSsr"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"

import Modal from "@/components/core/Modal/Modal"
import { validateRecaptcha } from "@/components/core/ReCaptcha/ReCaptcha"
import AssociateMembershipView from "@/components/AssociateMembership/v1/AssociateMembershipView"
import Confirm from "@/components/AssociateMembership/v1/Confirm"
import { apim } from "@/constants/api"
import { getConfig } from "@/constants/config"
import CONSTANTS, {
  MEMBERSHIP_API_URL_ENDPOINT,
  MEMBERSHIP_QUERRY_SELECTOR_URL,
} from "@/constants"

import { selectGenericState, showToast } from "@/store/features/genericSlice"

import {
  selectAuthState,
  setPersona,
  setAuthModalVisibility,
} from "@/store/features/authSlice"
import { getUserPersona } from "@/utils/helper"

import useAssociateMembershipi18n from "@/i18n/useAssociateMembershipi18n"
import { redirectionToContentPage } from "@/components/FriendsAndFamily/v1/friendsAndFamilyHelper"

const AssociateMembership = props => {
  const isSsr = useIsSsr()

  const {
    isAuth,
    user,
    access_token: accessToken,
  } = useSelector(selectAuthState)

  const { pageIsInteractive } = useSelector(selectGenericState)

  const dispatch = useDispatch()
  useSelector(selectGenericState)

  const { data: authorData } = props

  const i18n = useAssociateMembershipi18n({
    authorData,
  })
  const { successMessage2, koError2, genericError, emailError } = i18n

  const [confirmModal, setConfirmModal] = useState(false)
  const [isRetired, setIsRetired] = useState(false)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [persona, setUserPersona] = useState("")
  const [legalPageLinks, setLegalPageLinks] = useState("")
  const [apiUrl, setAPIUrl] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [nodeList, setNodeList] = useState([])
  const [btnLoaded, setBtnLoaded] = useState(false)

  useEffect(() => {
    redirectionToContentPage()
  }, [])

  useEffect(() => {
    if (!_isEmpty(accessToken)) {
      getConfig().then(config => {
        const legalPageLinks = _get(config, "legalPageData.map", {})
        setLegalPageLinks(legalPageLinks)
      })
      setPersona(getUserPersona())
      setAPIUrl(MEMBERSHIP_API_URL_ENDPOINT)
    }
  }, [accessToken])

  useEffect(() => {
    if (user) {
      setUserPersona(getUserPersona())
    }
  }, [user])

  useEffect(() => {
    if (!showModal) {
      setBtnLoaded(false)
    } else {
      setBtnLoaded(true)
    }
  }, [showModal])

  useEffect(() => {
    if (!isSsr && pageIsInteractive) {
      if (!btnLoaded) {
        setTimeout(function () {
          getNodeList()
        }, 1000)
      }
    }
  }, [isSsr, pageIsInteractive, btnLoaded, nodeList])

  const getNodeList = () => {
    if (document.querySelectorAll(MEMBERSHIP_QUERRY_SELECTOR_URL)) {
      setNodeList(document.querySelectorAll(MEMBERSHIP_QUERRY_SELECTOR_URL))
      showMembershipPopupHandler()
    }
  }

  const showAuthModal = () => {
    dispatch(setAuthModalVisibility({ show: true }))
  }

  const showMembershipPopupHandler = () => {
    nodeList.forEach(el => {
      el.addEventListener("click", e => {
        e.preventDefault()
        if (isAuth) {
          setShowModal(true)
        } else {
          showAuthModal()
        }
      })
    })
  }

  const showToaster = msg => {
    dispatch(
      showToast({
        message: msg,
        isVisible: true,
      })
    )
  }

  const changeState = () => {
    setIsRetired(true)
  }

  const verifyCode = (code, cb) => {
    const body = {
      action: "validate",
      email: email,
      code: code,
    }
    setLoading(true)

    apim
      .post(`${apiUrl}`, body)
      .then(() => {
        setLoading(false)
        setConfirmModal(false)
        setShowModal(false)
        if (persona != CONSTANTS.KEMP_PERSONA) {
          setUserPersona(CONSTANTS.KEMP_PERSONA)
          dispatch(setPersona(CONSTANTS.KEMP_PERSONA))
        }
        showToaster(successMessage2)
      })
      .catch(() => {
        setLoading(false)
        cb?.()
      })
  }

  const verifyClockId = (id, cb, analyticsCB) => {
    const body = {
      action: "validate",
      email: user.sub,
      clockId: id,
    }
    setLoading(true)

    validateRecaptcha()
      .then(res => {
        if (res?.success) {
          apim
            .post(`${apiUrl}`, body)
            .then(() => {
              setConfirmModal(true)
              setLoading(false)
              analyticsCB?.("success", null, true)
            })
            .catch(() => {
              setLoading(false)
              analyticsCB?.("failure", koError2, true)
              cb?.()
            })
        } else {
          setLoading(false)
          analyticsCB?.("failure", genericError, true)
          showToaster(genericError)
        }
      })
      .catch(() => {
        analyticsCB?.("failure", genericError, true)
      })
  }

  const verifyEmail = (email, cb, analyticsCB) => {
    const body = {
      action: "generate",
      email: email,
    }

    setLoading(true)

    validateRecaptcha()
      .then(res => {
        if (res?.success) {
          apim
            .post(`${apiUrl}`, body)
            .then(() => {
              setEmail(email)
              setLoading(false)
              setConfirmModal(true)

              analyticsCB?.("success")
            })
            .catch(() => {
              setLoading(false)
              analyticsCB?.("failure", emailError)
              cb?.()
            })
        } else {
          setLoading(false)
          showToaster(genericError)
          analyticsCB?.("failure", genericError)
        }
      })
      .catch(() => {
        analyticsCB?.("failure", genericError)
      })
  }

  const resendCode = (email, cb) => {
    const body = {
      action: "generate",
      email: email,
    }
    apim
      .post(`${apiUrl}`, body)
      .then(() => {
        setConfirmModal(true)
      })
      .catch(() => {
        cb()
      })
  }

  const closeModal = () => {
    setShowModal(false)
    setConfirmModal(false)
    setIsRetired(false)
  }

  return (
    <Modal
      labelledBy="associate-membership__title"
      describedBy="associate-membership__description"
      showModal={showModal}
      onModalClose={() => closeModal()}
    >
      {!confirmModal ? (
        <AssociateMembershipView
          i18n={i18n}
          verifyEmail={verifyEmail}
          isRetired={isRetired}
          changeState={changeState}
          showModal={showModal}
          loading={loading}
          legalPages={legalPageLinks}
          verifyClockId={verifyClockId}
        />
      ) : (
        <Confirm
          i18n={i18n}
          verifyCode={verifyCode}
          loading={loading}
          resendCode={resendCode}
          email={email}
          legalPages={legalPageLinks}
        />
      )}
    </Modal>
  )
}

export default AssociateMembership
