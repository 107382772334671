import React, { useEffect, useRef, useState } from "react"
import CONSTANTS from "@/constants/index"
import { smoothScroll } from "@/utils/helper"
import Highlight from "@/components/core/Highlight/Highlight"
import closeIcon from "@/public/icons/close.svg"
import searchIcon from "@/public/icons/search.svg"

const SearchDropdown = props => {
  const {
    id = "",
    data = [],
    value = "",
    placeholder = "",
    analyticsData = "",
    onType = () => {},
    onSelect = () => {},
    closeAnalyticsData = "",
    clearSuggestions = () => {},
  } = props

  const [isShowList, setShowList] = useState(false)
  const [selectedListItem, setListItem] = useState("")
  const searchDropdownRef = useRef()

  useEffect(() => {
    if (value.length > 0 && data.length > 0) {
      setShowList(true)
      setActivedescendant()
    } else setShowList(false)
  }, [data, value])

  useEffect(() => {
    if (isShowList) setActivedescendant()
  }, [isShowList])

  const setActivedescendant = () =>
    setListItem(document.getElementById(`${id}-list`)?.childNodes[0].id ?? "")

  const handleChange = e => {
    const {
      target: { value },
    } = e
    onType(value)
  }

  const handleSelect = item => {
    setShowList(false)
    onSelect(item)
  }

  // hide dropdown when user clicks outide the component
  useEffect(() => {
    window.addEventListener("click", handleClickOutside)
    return () => window.removeEventListener("click", handleClickOutside)
  }, [])

  const handleClickOutside = e => {
    const { target } = e
    if (searchDropdownRef.current.contains(target)) setShowList(false)
  }

  const handleKeyEnter = event => {
    const {
      key = "",
      target: { value },
    } = event
    if (key === "Enter") {
      onSelect(value)
    }
  }

  const handleKeyDown = e => {
    const { keyCode = "" } = e
    if (isShowList) {
      const {
        KEYCODE: { ESC, UP, DOWN, SPACE, ENTER },
      } = CONSTANTS
      const ele = [...document.getElementById(`${id}-list`).childNodes]
      let index = 0
      const selectedItem = ele.find((item, i) => {
        if (item.id === selectedListItem) {
          index = i
          return item
        }
      })
      switch (keyCode) {
        case ESC:
          setShowList(false)
          break
        case UP:
          e.preventDefault()
          if (index > 0)
            ele.forEach((item, i) => {
              if (i === index - 1) {
                item.classList.add("--hover")
                smoothScroll(item)
                setListItem(item.id)
              } else item.classList.remove("--hover")
            })
          break
        case DOWN:
          e.preventDefault()
          if (index < ele.length - 1)
            ele.forEach((item, i) => {
              if (i === index + 1) {
                item.classList.add("--hover")
                smoothScroll(item)
                setListItem(item.id)
              } else item.classList.remove("--hover")
            })
          break
        case SPACE:
          break
        case ENTER:
          selectedItem.click()
          clearSuggestions()
          break
        default:
          break
      }
    }
  }

  return (
    <div
      className="search-dropdown"
      id="kf-tech-specs-dropdown"
      ref={searchDropdownRef}
    >
      <div className="search-dropdown__field">
        <span>
          <img
            role="presentation"
            className="search-dropdown__search-icon"
            src={searchIcon?.src}
            alt="search-icon"
          />
        </span>
        <input
          id={id}
          type="text"
          value={value}
          data-gbh-data-layer={analyticsData || null}
          placeholder={placeholder}
          className={
            "search-dropdown__input " + (analyticsData ? "gbh-data-layer" : "")
          }
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onKeyPress={handleKeyEnter}
          aria-haspopup="listbox"
          aria-activedescendant={selectedListItem}
          aria-readonly="true"
          aria-owns={`${id}-list`}
          autoComplete="off"
        />
        {value.length > 0 ? (
          <span onClick={() => onSelect("")}>
            <img
              className={
                "search-dropdown__close-icon " +
                (closeAnalyticsData ? "gbh-data-layer" : "")
              }
              data-gbh-data-layer={closeAnalyticsData || null}
              src={closeIcon?.src}
              alt="close-icon"
            />
          </span>
        ) : null}
      </div>
      {isShowList ? (
        <ul
          id={`${id}-list`}
          className="search-dropdown__dropdown"
          role="listbox"
        >
          {data.map((item, i) => (
            <li
              role="option"
              key={i}
              id={`search-dropdown-list-${i}`}
              className={`search-dropdown__list-item ${
                i === 0 ? "--hover" : ""
              }`}
              onClick={() => handleSelect(item["productName_s"])}
              aria-selected={item === value}
            >
              <span className="search-dropdown__list-text">
                <Highlight text={item["productName_s"]} highlight={value} />
              </span>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}

export default SearchDropdown
