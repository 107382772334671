import React, { useEffect, useState } from "react"
import { isIOS, isMobile } from "react-device-detect"
import _isEmpty from "lodash/isEmpty"
import { formatPhoneNumber, getShortenedUrl } from "@/utils/helper"
import { LOCATIONSCONST } from "@/constants"
import Checkbox from "@/components/core/Checkbox/Checkbox"
import {
  getEventStoreFilterInfo,
  getEventStorePhoneInfo,
  setStoreAnalytics,
} from "@/components/FindStore/v1/analytics"

const StoreList = ({
  data = {},
  active = false,
  staticTexts = {},
  findStoreDetails = {},
  findStoreCheckedId = "",
  handleFindStoreCheck = () => {},
  index = "",
  tabName,
}) => {
  const {
    LocationName = "",
    AddressLine = "",
    AddressLine2 = "",
    Locality = "",
    AdminDistrict = "",
    Latitude = 0,
    Longitude = 0,
    LocationType = "",
    travelDistance = 0,
    BPnumber = "",
    Phone = "",
    EntityID = "",
    OpenUntil = "",
    PostalCode = "",
  } = data
  const { kohlerStore } = staticTexts
  const [bpnumberURL, setBPnumberURL] = useState("")
  useEffect(() => {
    getShortenedUrl(findStoreDetails[BPnumber]).then(res => {
      setBPnumberURL(res)
    })
  }, [BPnumber])

  return (
    <div
      className={`store-list ${active && "store-list--active"}`}
      role="listitem"
    >
      <div className="row">
        <div className="col-8 col-md-4 store-list__name-col px-0">
          {LocationType.toLowerCase() !==
          LOCATIONSCONST.LocationType?.toLowerCase() ? (
            <span className="store-list__name store-list__genuine-dealers gbh-data-layer">
              {LocationName}
            </span>
          ) : (
            <a
              href={bpnumberURL ?? "/"}
              className="store-list__name gbh-data-layer"
              data-gbh-data-layer={getEventStoreFilterInfo(
                data,
                "store name",
                findStoreDetails[data.BPnumber] ?? "/"
              )}
            >
              {LocationName}
            </a>
          )}
        </div>
        <div className="col-12 col-md-4 store-list__address-col px-0">
          <div className="store-list__address">
            {!isMobile ? (
              <React.Fragment>
                <span>{AddressLine}</span>
                {_isEmpty(AddressLine2) ? <span>{AddressLine2}</span> : ""}
                <span>{`${Locality},${AdminDistrict} ${PostalCode}`}</span>
              </React.Fragment>
            ) : (
              <a
                href={
                  isIOS === true
                    ? `maps://maps.google.com/maps?daddr=${Latitude},${Longitude}&amp;ll=`
                    : `http://www.google.com/maps?q=${Latitude},${Longitude}`
                }
              >
                <span>{AddressLine}</span>
                {_isEmpty(AddressLine2) ? <span>{AddressLine2}</span> : ""}
                <span>{`${Locality},${AdminDistrict} ${PostalCode}`}</span>
              </a>
            )}
          </div>
          <a
            href={`tel:+${Phone}`}
            className="store-list__phone"
            onClickCapture={() => getEventStorePhoneInfo(Phone, tabName)}
          >
            {formatPhoneNumber(Phone)}
          </a>
        </div>
        <div className="col-3 col-md-2 store-list__distance-col px-0">
          <div className="store-list__distance">
            {`${travelDistance.toFixed(1)} mi`}
          </div>
          <div className="store-list__time">{OpenUntil}</div>
        </div>
      </div>

      <div className="row">
        <div className="col-7 px-0">
          {LocationType === kohlerStore.toLowerCase() ? (
            <div className="store-list__set-store">
              <Checkbox
                id={`store-list__set-store_${index}`}
                checked={findStoreCheckedId === EntityID}
                value={
                  findStoreCheckedId === EntityID
                    ? staticTexts.myStore
                    : staticTexts.setAsMyStore
                }
                onChange={e => {
                  handleFindStoreCheck(e.target.checked ? EntityID : 0)
                  setStoreAnalytics(e.target.checked, LocationName)
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="col-5 px-0">
          <div className="store-list__set-store-time">{OpenUntil}</div>
        </div>
      </div>
    </div>
  )
}
export default StoreList
