import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useRouter } from "next/router"
import debounce from "lodash/debounce"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import { aemAxiosInstance } from "@/constants/api"
import { GLOBAL_CONFIGURATION_ENDPOINT, LOCATIONSCONST } from "@/constants"
import { getConfig } from "@/constants/config"
import { getMyLocation, hasNumber, storeFilterData } from "@/utils/helper"
import {
  locationState,
  setMyStore,
  setFindAStoreSearchEvent,
  setStoreAddress,
} from "@/store/features/locationSlice"
import { getStoreDataById } from "@/utils/location"
import {
  addFindAStoreAnalytics,
  findAStoreLoadMoreAnalytics,
} from "@/components/FindStore/v1/analytics"
import useFindStorei18n from "@/i18n/useFindStorei18n"
import FindStoreLanding from "@/components/FindStore/v1/FindStoreLanding.View/FindStoreLanding"
import blackMarker from "@/public/icons/locator-big-chacrol.svg"
import redMarker from "@/public/icons/locator-big-red.svg"
import greenMarker from "@/public/icons/locator-big-green.svg"
import {
  getAutoSuggestionsInputBased,
  getFindAStoresResult,
} from "@/components/FindStore/v1/api"

const FindStore = props => {
  const { data: authorData } = props

  const staticTexts = useFindStorei18n({
    authorData,
  })

  const { distanceMiles = 0, maxStorePerPage = 10 } = authorData

  const { myStoreId, browseLocationsStoreFilterData, findAStoreSearchEvent } =
    useSelector(locationState)
  const [searchTerm, setSearchTerm] = useState("")
  const [brandStoreShowroomOrDealersData, setBrandStoreShowroomOrDealersData] =
    useState({})
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [findStoreDetails, setFindStoreDetails] = useState({})
  const [findStoreFilterData] = useState(
    _get(browseLocationsStoreFilterData, "storefilter", {})
  )
  const [citySuggestData, setCitySuggestData] = useState([])
  const [findStoreCheckedId, setFindStoreCheckedId] = useState(
    localStorage.getItem("myStoreId") ?? 0
  )
  const [infoModal, setInfoModal] = useState(false)
  const [infoModalError, setinfoModalError] = useState({})
  const [info, setInfo] = useState({})
  const [loadingState, setLoadingState] = useState(true)
  const [geolocationEvent, setGeolocationEvent] = useState(false)
  const dispatch = useDispatch()
  const locationRef = useRef(null)
  const router = useRouter()

  useEffect(() => {
    getConfig().then(async config => {
      const locale = _get(config, "internationalization.locale", "")
      const currencySign = _get(config, "internationalization.currencySign", "")
      const currencyCode = _get(config, "internationalization.currencyCode", "")
      const findStoreDetailsUrl = _get(
        config,
        "general.storeDetailsServletPath",
        ""
      )
      const findStores = _get(config, "apiEndpoints.findStores", "")
      const reverseGeo = _get(config, "apiEndpoints.reverseGeo", "")
      const findStoresBingKey = _get(
        config,
        "apiEndpoints.findStoresBingKey",
        ""
      )
      const siteName = _get(config, "general.siteName", "")
      const info = {
        locale: locale,
        currencySign: currencySign,
        currencyCode: currencyCode,
        findStores: findStores,
        reverseGeo: reverseGeo,
        findStoresBingKey: findStoresBingKey,
        siteName: siteName,
        findStoreDetailsUrl: findStoreDetailsUrl,
      }
      setInfo(info)
      aemAxiosInstance({
        url: GLOBAL_CONFIGURATION_ENDPOINT,
        params: {
          path: findStoreDetailsUrl,
        },
      })
        .then(response => {
          if (response && response.data) {
            setFindStoreDetails(response.data)
          }
        })
        .catch(err => {
          console.log("Failed to get compare attributes JSON file", err)
        })
    })
  }, [])
  const { findStores = "" } = info

  useEffect(() => {
    const urlSearchTerm = router.query.q

    if (urlSearchTerm !== undefined) {
      setSearchTerm(urlSearchTerm)
      localStorage.setItem("searchTerm", urlSearchTerm)
    } else {
      setSearchTerm(localStorage?.getItem("currentZipCode") || "")
      localStorage.removeItem("searchTerm")
    }
  }, [router.query.q])

  useEffect(() => {
    if (myStoreId !== findStoreCheckedId) {
      localStorage.setItem("myStoreId", myStoreId)
      setFindStoreCheckedId(myStoreId)
    }
  }, [myStoreId])

  useEffect(() => {
    if (myStoreId !== 0 && !_isEmpty(info)) {
      getUtilityDataById()
    } else {
      dispatch(setStoreAddress({}))
    }
  }, [myStoreId, info])

  const getUtilityDataById = async () => {
    const utilityData = await getStoreDataById({
      myStoreId: myStoreId,
      findStores: findStores,
      siteName: info.siteName,
    })
    dispatch(setStoreAddress(utilityData))
  }

  useEffect(() => {
    if (searchTerm && findStores) {
      debouncedFetchData(
        searchTerm,
        info,
        findAStoreSearchEvent,
        geolocationEvent
      )
    }
  }, [searchTerm, info, findAStoreSearchEvent, geolocationEvent])

  const debouncedFetchData = useCallback(
    debounce((keyword, infoval, findAStoreSearchEvt, geolocationEvent) => {
      getStoreDetails({
        isAutoComplete: true,
        preventUnwantedDataLayer: !findAStoreSearchEvt,
        type: "",
        info: infoval,
        key: keyword,
        activeTab: 0,
        page: 1,
        needCallToSuggestionAPI: true,
        geolocationEvent,
      })
      setUrlParam(keyword)
    }, 2000),

    []
  )

  const resetBrandData = (needToReturn = false) => {
    const brandData = { ...brandStoreShowroomOrDealersData }
    const tabKeys = tabList.map(item => item.key)
    tabKeys.forEach(item => {
      brandData[item] = {
        data: [],
        isAllLoaded: true,
        page: 0,
      }
    })
    if (needToReturn) return brandData
    setBrandStoreShowroomOrDealersData(brandData)
  }

  const getGeoLocation = type => {
    setGeolocationEvent(true)
    getMyLocation()
      .then(getLocation => {
        setSearchTerm(getLocation.zipcode ?? "")
      })
      .catch(({ cause }) => {
        addFindAStoreAnalytics({
          action: "cmp:click",
          status: "failure",
          total: 0,
          authorData,
          type,
          searchTerm,
          geolocationEvent: true,
          errorMessage: cause?.message ?? "",
        })
        setinfoModalError(cause ?? {})
        setInfoModal(true)
        setGeolocationEvent(false)
      })
    locationRef.current?.focus()
  }

  const getStoreDetails = async ({
    preventUnwantedDataLayer,
    type = "",
    info = {},
    key = "",
    activeTab = 0,
    page = 1,
    needCallToSuggestionAPI = false,
    geolocationEvent,
  }) => {
    const { findStores = "", siteName = "" } = info

    const { key: locationType = "" } = tabList[activeTab]

    if (key.length > 2 && findStores) {
      setLoadingState(true)
      let autoSuggestionAPIStatus = 0
      let autoSuggestionAPIResults = []
      if (needCallToSuggestionAPI) {
        if (!_isEmpty(brandStoreShowroomOrDealersData)) {
          resetBrandData()
        }

        if (!hasNumber(key)) {
          const suggestedData = await getAutoSuggestionsInputBased(
            findStores,
            siteName,
            key
          )
          const { status, data: { d: { results = [] } = {} } = {} } =
            suggestedData
          autoSuggestionAPIStatus = status
          autoSuggestionAPIResults = results
          if (autoSuggestionAPIStatus === LOCATIONSCONST.status) {
            const suggestedResult = autoSuggestionAPIResults.map(
              city => city.Locality
            )

            setCitySuggestData([...new Set(suggestedResult)])
          }
        }
      }
      let matchedStoresResult = {}
      try {
        matchedStoresResult = await getFindAStoresResult(
          findStores,
          key,
          siteName,
          distanceMiles,
          maxStorePerPage,
          locationType,
          page
        )
      } catch (err) {
        setLoadingState(false)
        // eslint-disable-next-line no-console
        console.log("failed in fetching the find a store result", err)
      }
      const {
        data: {
          info: filteredData = [],
          totalRecords = 0,
          totalPages = 1,
        } = {},
      } = matchedStoresResult

      const brandData = _isEmpty(brandStoreShowroomOrDealersData)
        ? resetBrandData(true)
        : { ...brandStoreShowroomOrDealersData }
      brandData[locationType].data = [
        ...brandData[locationType].data,
        ...filteredData,
      ]
      brandData[locationType].totalRecords = totalRecords
      brandData[locationType].isAllLoaded =
        totalRecords === 0 || totalPages === page
      brandData[locationType].page = page
      brandData.needTOReloadTabs = needCallToSuggestionAPI

      if (totalRecords === 0 && activeTab < 2) {
        return await getStoreDetails({
          isAutoComplete: false,
          preventUnwantedDataLayer,
          type: "",
          info,
          key,
          activeTab: activeTab + 1,
          geolocationEvent,
        })
      }

      if (!preventUnwantedDataLayer || geolocationEvent) {
        if (page > 1) {
          findAStoreLoadMoreAnalytics()
        } else {
          let status = "success"
          if (!totalRecords) {
            status = "failure"
          }
          addFindAStoreAnalytics({
            action: "cmp:click",
            status,
            total: totalRecords,
            authorData,
            type,
            searchTerm: key,
            geolocationEvent,
          })
        }
      }

      const newLatitude = filteredData.length ? filteredData[0].Latitude : 0
      const newLongitude = filteredData.length ? filteredData[0].Longitude : 0
      sessionStorage.setItem("brandData", JSON.stringify(brandData))
      setBrandStoreShowroomOrDealersData(brandData)
      setLatitude(newLatitude)
      setLongitude(newLongitude)
      setLoadingState(false)
      dispatch(setFindAStoreSearchEvent(false))
      setGeolocationEvent(false)
    } else {
      if (!preventUnwantedDataLayer) {
        addFindAStoreAnalytics({
          action: "cmp:click",
          status: "failure",
          total: 0,
          authorData,
          type,
          searchTerm: key,
          geolocationEvent,
        })
      }

      setLatitude(0)
      setLongitude(0)
      setCitySuggestData([])
      setLoadingState(false)
      dispatch(setFindAStoreSearchEvent(false))
      setGeolocationEvent(false)
    }
  }

  const handleChange = inputVal => {
    setSearchTerm(inputVal)
    dispatch(setFindAStoreSearchEvent(true))
    localStorage.setItem("searchTerm", inputVal)
  }

  const handleFindStoreCheck = entity => {
    localStorage.setItem("myStoreId", entity)
    dispatch(setMyStore(entity))
    setFindStoreCheckedId(entity)
  }

  const setUrlParam = keyword => {
    if (keyword && keyword.length >= 5) {
      const url = new URL(window?.location?.href)
      const searchParams = url?.searchParams
      searchParams?.set("q", keyword)
      const urlSearch = "?" + searchParams?.toString()
      hasNumber(keyword) && localStorage.setItem("currentZipCode", keyword)
      window?.history?.replaceState(
        window?.location?.pathname,
        document?.title,
        urlSearch
      )
    }
  }

  const resetFilterData = (parsedBrandData, activeTab) => {
    brandStoreShowroomOrDealersData[tabList[activeTab].key].data =
      parsedBrandData[tabList[activeTab].key]?.data
    setBrandStoreShowroomOrDealersData({ ...brandStoreShowroomOrDealersData })
  }
  const mapFilteredQueries = (dataItem, appliedFilters) => {
    const mappedItems = appliedFilters.filter(item => dataItem[item] === true)
    return mappedItems.length === appliedFilters.length
  }
  const applySelectedFilters = (curFilters, activeTab) => {
    const brandDataFromSession = sessionStorage.getItem("brandData")
    const parsedBrandData = JSON.parse(brandDataFromSession)
    if (!curFilters.length) resetFilterData(parsedBrandData, activeTab)
    const appliedFilters = curFilters.map(item => item.query).flat(1)
    const brandStoreData = { ...parsedBrandData }
    const appliedBrandStoreFilters = brandStoreData[
      tabList[activeTab].key
    ].data.filter(item => mapFilteredQueries(item, appliedFilters))
    brandStoreData[tabList[activeTab].key].data = appliedBrandStoreFilters
    setBrandStoreShowroomOrDealersData(brandStoreData)
  }

  let facets = {}
  if (findStoreFilterData) {
    facets = storeFilterData(findStoreFilterData)
  }

  const tabList = [
    {
      title: staticTexts.kohlerStore,
      key: staticTexts.kohlerStore,
      id: 0,
      icon: blackMarker?.src,
      locationNote: staticTexts.kohlerStore,
      locationNoteMobile: staticTexts.kohler,
    },

    {
      title: staticTexts.showrooms,
      key: staticTexts.showroom,
      id: 1,
      icon: redMarker?.src,
      locationNote: staticTexts.showrooms,
      locationNoteMobile: staticTexts.showrooms,
    },

    {
      title: staticTexts.genuinePartsDealers,
      key: staticTexts.partsDealer,
      id: 2,
      icon: greenMarker?.src,
      locationNote: staticTexts.genuinePartsDealers,
      locationNoteMobile: staticTexts.genuinePartsDealers,
    },
  ]

  const tabChangeListener = activeTab => {
    if (!brandStoreShowroomOrDealersData[tabList[activeTab].key]?.data.length) {
      getStoreDetails({
        isAutoComplete: false,
        preventUnwantedDataLayer: true,
        type: "",
        info,
        key: searchTerm,
        activeTab,
      })
    }
  }

  const loadMoreHandler = activeTab => {
    const { page = 1 } = brandStoreShowroomOrDealersData[tabList[activeTab].key]
    getStoreDetails({
      isAutoComplete: false,
      preventUnwantedDataLayer: false,
      type: "",
      info,
      key: searchTerm,
      activeTab,
      page: page + 1,
    })
  }

  return (
    <FindStoreLanding
      brandData={brandStoreShowroomOrDealersData}
      staticTexts={staticTexts}
      searchTerm={searchTerm}
      handleChange={inputVal => handleChange(inputVal)}
      facets={facets}
      getGeoLocation={getGeoLocation}
      tabList={tabList}
      latitude={latitude}
      longitude={longitude}
      getStoreDetails={(queries, preventUnwantedDataLayer) =>
        getStoreDetails({
          queries: queries,
          distanceVal: LOCATIONSCONST.paramSpatialFilter,
          isAutoComplete: true,
          preventUnwantedDataLayer: preventUnwantedDataLayer,
          info: info,
          key: searchTerm,
        })
      }
      findStoreDetails={findStoreDetails}
      findStoresBingKey={info.findStoresBingKey}
      citySuggestData={citySuggestData}
      handleFindStoreCheck={(checkedVal, entity) =>
        handleFindStoreCheck(checkedVal, entity)
      }
      findStoreCheckedId={findStoreCheckedId}
      infoModal={infoModal}
      setInfoModalClose={() => setInfoModal(false)}
      infoModalError={infoModalError}
      authorData={authorData}
      info={info}
      locationRef={locationRef}
      tabChangeListener={tabChangeListener}
      loadMoreHandler={loadMoreHandler}
      loadingState={loadingState}
      applySelectedFilters={applySelectedFilters}
    />
  )
}

export default FindStore
