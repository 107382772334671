import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { isMobile, withOrientationChange } from "react-device-detect"
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"

import { getConfig } from "@/constants/config"

import InstallDesignServicesView from "@/components/InstallDesignServices/v1/InstallDesignServices.view"
import { getProductDataFromSkuIds } from "@/components/InstallDesignServices/api"
import missingImage from "@/public/images/image-missing.png"

import {
  validateField,
  sanitizeTextForAnalytics,
  getUserPersona,
  getShortenedUrlwithGeneral,
} from "@/utils/helper"
import { aemAxiosInstance } from "@/constants/api"
import { GLOBAL_CONFIGURATION_ENDPOINT } from "@/constants"
import useDesignServicei18n from "@/i18n/useDesignServicei18n"

import { callInstallServiceAddToCartAnalytics } from "@/components/productCard/v1/analytics"
import {
  getEventInfoObject,
  getProductInfoForAnalytics,
} from "@/components/InstallDesignServices/analytics"

import { selectCartState } from "@/store/features/cartSlice"
import { showToast } from "@/store/features/genericSlice"

import styles from "@/components/InstallDesignServices/v1/index.module.scss"
import { productCardAddToCart } from "@/components/productCard/v1/productCardHelper"
const InstallDesignServices = props => {
  const dispatch = useDispatch()
  const i18n = useDesignServicei18n()
  const { cart: activeCart = {} } = useSelector(selectCartState)
  const [productData, setProductData] = useState([])
  const [zipCodeData, setZipCodeData] = useState({})
  const [quantity, setQuantity] = useState({})
  const [zipCodeError, setZipCodeError] = useState({
    show: false,
    message: "",
  })
  const [zipCodeInput, setZipCodeInput] = useState(
    localStorage?.getItem("currentZipCode") ?? ""
  )
  const [availableServices, setAvailableServices] = useState([])
  const [zipCodeChecked, setZipCodeChecked] = useState(false)
  const [persona, setPersona] = useState("GST")

  const [info, setInfo] = useState({})
  const { cartUrl = "", currencySign = "", general = {}, lwAppName = "" } = info
  const { staticTexts = {}, data: authorData } = props
  const {
    services = [],
    presetConfigs = "",
    swatchUrl = "",
    jsonFilePath = "",
  } = authorData

  const {
    sfLabel = "",
    lfLabel = "",
    boxLabel = "",
    yourCart = "",
    itemAdded = "",
    itemsAdded = "",
  } = staticTexts

  useEffect(() => {
    getConfig().then(CONFIG => {
      const { general } = CONFIG
      if (!_isEmpty(CONFIG)) {
        const {
          cartLandingPagePath = "/",
          siteName = "",
          lwAppName = "",
        } = general
        const cartUrl = getShortenedUrlwithGeneral(
          cartLandingPagePath ?? "/",
          CONFIG
        )
        const currencySign = _get(
          CONFIG,
          "internationalization.currencySign",
          ""
        )
        setInfo({ siteName, cartUrl, currencySign, general, lwAppName })
        setPersona(getUserPersona())
      }
    })
  }, [])

  useEffect(() => {
    if (!_isEmpty(authorData) && lwAppName) {
      getZipCodeJsonFile()
      getProductDataFromSkuIds(persona, services, lwAppName).then(res => {
        if (res && res?.data) {
          const productServiceData = _get(res?.data, "response.docs", [])
          const serviceSkus = services.map(item => item.serviceSkuId)
          productServiceData.sort(
            (a, b) =>
              serviceSkus.indexOf(a.masterSKU_s) -
              serviceSkus.indexOf(b.masterSKU_s)
          )
          const productProps = productServiceData.map(product =>
            getProductProps(product, presetConfigs, swatchUrl)
          )
          const quantity = {}
          productProps.forEach(({ skuId }) => {
            quantity[skuId] = 1
          })

          setQuantity(quantity)
          setProductData(productProps)
        }
      })
    }
  }, [lwAppName])

  const getZipCodeJsonFile = async () => {
    if (jsonFilePath) {
      aemAxiosInstance({
        url: GLOBAL_CONFIGURATION_ENDPOINT,
        params: {
          path: jsonFilePath,
        },
      })
        .then(response => {
          if (response) {
            const { data: { zipcode = [] } = {} } = response
            setZipCodeData(zipcode)
          }
        })
        .catch(err => {
          console.error("Failed to get zipcode from AEM", err)
        })
    }
  }

  const getProductProps = (
    productServiceData = {},
    presetConfigs = "",
    swatchUrl = ""
  ) => {
    const productCardProps = {
      presetConfigs: presetConfigs ? JSON.parse(presetConfigs) : "",
      swatchUrl: swatchUrl ?? "",
      images: missingImage,
    }
    if (!_isEmpty(productServiceData)) {
      // get product images
      let imgSrc = ""
      if (productServiceData["productImages.url_s"]) {
        imgSrc = productServiceData["productImages.url_s"] ?? ""
      } else if (productServiceData["productImages.url_ss"]) {
        imgSrc = productServiceData["productImages.url_ss"][0] ?? ""
      }

      if (imgSrc?.length) {
        const imgParts = imgSrc?.split("/")
        const imgId = imgParts[imgParts.length - 1]
        productCardProps.images = imgId
      }

      // get product colors
      if (productServiceData["Color.SKU.Details_ss"]) {
        const colors = productServiceData["Color.SKU.Details_ss"] ?? []
        productCardProps.colors = colors.filter(item => {
          const color = item?.split("|")
          return color[4] === "false" || Date.parse(color[4]) > Date.now()
        })
      } else {
        productCardProps.colors = [
          productServiceData["SKUColorSwatchFilename_s"] ?? "",
        ]
      }

      // get product web features
      if (productServiceData["ProductWebFeatures_s"]) {
        productCardProps.productFeatures =
          [productServiceData["ProductWebFeatures_s"]] ?? []
      } else {
        productCardProps.productFeatures =
          productServiceData["ProductWebFeatures_ss"] ?? []
      }
      productCardProps.currencySign = currencySign
      productCardProps.productCategory = _get(
        productServiceData,
        "Product_Category",
        ""
      )
      productCardProps.name = _get(productServiceData, "productName_s", "")
      productCardProps.brandName = _get(
        productServiceData,
        "RegionBrandName_s",
        ""
      )
      productCardProps.description = _get(
        productServiceData,
        "ProductNarrativeDescription_s",
        ""
      )
      productCardProps.narrativeDescription = _get(
        productServiceData,
        "ProductNarrativeDescription_s",
        ""
      )
      productCardProps.price = _get(
        productServiceData,
        `priceStartingAt.${persona}_d`,
        0
      )
      productCardProps.discountedPrice = _get(
        productServiceData,
        `discountedPriceStartingAt.${persona}_d`,
        0
      )
      productCardProps.discountPercent = parseInt(
        _get(productServiceData, `priceList.${persona}.saleOffer_s`, 0)
      )
      productCardProps.slug = _get(productServiceData, "slug_s", "")
      productCardProps.customerBrand = _get(
        productServiceData,
        "CustomerFacingBrand_s",
        ""
      )
      productCardProps.skuId = _get(productServiceData, "masterSKU_s", "")
      let tempColorDetails = _get(
        productServiceData,
        "Color.SKU.Details_s",
        _get(productServiceData, "Color.SKU.Details_ss", null)
      )
      if (tempColorDetails) {
        if (typeof tempColorDetails !== "string" && tempColorDetails[0]) {
          tempColorDetails = tempColorDetails[0]
        }
        productCardProps.customerFacingSKU =
          tempColorDetails.split("|")[5] || ""
      }
      productCardProps.badgeVal = _get(
        productServiceData,
        `priceList.${persona}.saleOffer_s`,
        ""
      )
      productCardProps.globalDiscount =
        productCardProps.price -
        _get(
          productServiceData,
          `priceList.${persona}.finalPrice_d`,
          productCardProps.price
        )
      const productCategory = productCardProps?.productCategory?.toLowerCase()
      productCardProps.priceType = ""

      if (
        productCategory === "field tiles" ||
        productCategory === "slabs" ||
        productCategory === "mosaics"
      ) {
        productCardProps.priceType = `/${sfLabel}`
      }
      if (productCategory === "trims & moldings") {
        productCardProps.priceType = `/${lfLabel}`
      }
      if (productCategory === "grout") {
        productCardProps.priceType = boxLabel
      }
    }
    return productCardProps
  }

  const checkUserZipCode = userZipCodeVal => {
    let services = []
    for (let i = 0; i < zipCodeData.length; i++) {
      const key = Object.keys(zipCodeData[i])[0]
      let isMatched = false
      for (let j = 0; j < zipCodeData[i][key].length; j++) {
        const zipcode = Object.keys(zipCodeData[i][key][j])[0]
        if (zipcode === userZipCodeVal) {
          services = zipCodeData[i][key][j][zipcode]
          isMatched = true
          break
        }
      }
      if (isMatched) break
    }
    setAvailableServices(services)
    setZipCodeChecked(true)
    services?.length
      ? addZipcodeAnalytics("success", "", services)
      : addZipcodeAnalytics("failure", "service not available", services)
  }

  const handleCheckAvailability = async () => {
    let valid = true
    const zipCodeErrObj = {
      show: false,
      message: "",
    }
    const { zipcodeEmptyError = "", validZipcode = "" } = i18n
    if (_isEmpty(zipCodeInput)) {
      zipCodeErrObj.show = true
      zipCodeErrObj.message = zipcodeEmptyError
      valid = false
    } else if (!(await validateField("zipcode", zipCodeInput))) {
      zipCodeErrObj.show = true
      zipCodeErrObj.message = validZipcode
      valid = false
    }

    setZipCodeError({ zipCodeErrObj })

    valid ? checkUserZipCode(zipCodeInput) : setZipCodeChecked(false)
  }

  const addZipcodeAnalytics = (status = "", message = "", services = []) => {
    const productInfo = null
    const productInfoArray = services.length
      ? productData.filter(product => services.includes(product.skuId))
      : productData
    const productNameArry = productInfoArray.map(product => product.name)
    const productNames = productNameArry.length
      ? productNameArry.join("|").toLowerCase()
      : ""
    const eventInfoObj = getEventInfoObject(
      "check availability",
      "search",
      general,
      zipCodeInput,
      productNames
    )
    eventInfoObj.eventMsg = message || "n/a"
    eventInfoObj.eventStatus = status
    callInstallServiceAddToCartAnalytics({
      resInfo: {},
      eventInfoObj,
      productInfo,
      cartID: props?.cart?.id,
    })
  }

  const eventInfoObj = getEventInfoObject(
    "change zip code",
    null,
    general,
    zipCodeInput,
    "toilet install service "
  )
  const handleClickChangeBtn = () => {
    setZipCodeChecked(false)
    callInstallServiceAddToCartAnalytics({
      resInfo: {},
      eventInfoObj,
      cartID: activeCart?.id,
    })
  }

  const handleChangeZipCode = async e => {
    const { value } = e.target
    if ((await validateField("zipCodeNumeric", value)) || value === "") {
      setZipCodeInput(value)
    }
    setZipCodeError({ show: false, message: "" })
  }
  const zipCodeCheck = async () => {
    if (!(await validateField("zipcode", zipCodeInput))) {
      setZipCodeError({ show: true, message: i18n?.validZipcode })
    } else {
      setZipCodeError({ show: false, message: "" })
    }
  }

  const addServiceToCart = (items = [], productName = "", skuId = "") => {
    let totalAdded = 0
    items.forEach(item => (totalAdded += item.quantity))
    const msg =
      totalAdded === 1
        ? `${totalAdded} ${itemAdded} `
        : `${totalAdded} ${itemsAdded} `
    productCardAddToCart(
      [...items],
      true,
      "",
      res => handleCallback(res, msg, productName, skuId),
      false
    )
  }
  const handleAddToCart = item => {
    const {
      skuId = "",
      customerBrand = "",
      name = "",
      productCategory = "",
      quantity = "",
    } = item
    if (productCategory === "Design Services") {
      addServiceToCart(
        [{ sku: skuId, quantity: quantity, brand: customerBrand }],
        name,
        skuId
      )
    } else {
      if (!_isEmpty(zipCodeInput)) {
        addServiceToCart(
          [
            {
              sku: skuId,
              quantity: quantity,
              userZipCode: zipCodeInput,
              brand: customerBrand,
            },
          ],
          name,
          skuId
        )
      }
    }
  }
  const handleChange = async (qty = 0, skuId = "") => {
    if (qty && qty !== 0) {
      const updateQuantity = { ...quantity }
      updateQuantity[skuId] = qty
      setQuantity(updateQuantity)
    }
  }
  const getToasterMsg = msg => (
    <>
      {msg}
      <a href={cartUrl}>{yourCart}</a>.
    </>
  )

  const handleCallback = (res = {}, msg = "", productName = "", skuId = "") => {
    const product = productData.find(
      productItem => productItem?.skuId === skuId
    )
    const productInfo = getProductInfoForAnalytics(
      product,
      quantity,
      general,
      activeCart
    )

    const eventInfoObj = getEventInfoObject(
      "add to cart",
      null,
      general,
      zipCodeInput,
      productName
    )
    const { status, message = "n/a" } = res

    eventInfoObj.eventStatus = status === 200 ? "success" : "failure"
    if (status !== 200) {
      eventInfoObj.eventMsg = message
        ? sanitizeTextForAnalytics(message)
        : "n/a"
    }
    callInstallServiceAddToCartAnalytics({
      resInfo: res,
      eventInfoObj,
      productInfo,
      cartID: activeCart?.id,
    })
    if (isMobile)
      dispatch(
        showToast({
          message: getToasterMsg(msg),
          isVisible: true,
        })
      )
  }

  return (
    <div className={styles?.installServiceWrapper}>
      <div className="install-design-services installdesignservices ">
        <InstallDesignServicesView
          handleCheckAvailability={handleCheckAvailability}
          authorData={authorData}
          zipCodeError={zipCodeError}
          handleChangeZipCode={handleChangeZipCode}
          zipCodeInput={zipCodeInput}
          i18n={i18n}
          handleChange={handleChange}
          handleAddToCart={handleAddToCart}
          quantity={quantity}
          zipCodeCheck={zipCodeCheck}
          availableServices={availableServices}
          productData={productData}
          handleClickChangeBtn={handleClickChangeBtn}
          zipCodeChecked={zipCodeChecked}
        />
      </div>
    </div>
  )
}
export default withOrientationChange(InstallDesignServices)
