import { useTranslation } from "next-i18next"

const useTechnicalSpecsi18n = ({
  componentProps = {},
  loading = false,
  keyword = "",
}) => {
  const { t } = useTranslation()
  const i18n = {
    technicalSpecs: componentProps?.techTitle
      ? componentProps?.techTitle
      : t("kf.technical.label.technicalSpecs"),
    searchPlaceHolder: t("kf.technical.label.searchPlaceHolder"),
    pageDescription: componentProps?.techDescription
      ? componentProps?.techDescription
      : t("kf.technical.label.pageDescription"),
    print: t("kf.plp.label.print"),
    share: t("kf.plp.label.share"),
    wishlist: t("kf.plp.label.wishlist"),
    showFilter: t("kf.plp.label.showFilter"),
    hideFilter: t("kf.plp.label.hideFilter"),
    filters: t("kf.plp.label.filters"),
    filterSort: t("kf.plp.label.filterSort"),
    addToCompare: t("kf.plp.label.addToCompare"),
    colors: t("kf.plp.label.colors"),
    color: t("kf.plp.label.color"),
    view: t("kf.plp.label.view"),
    all: t("kf.plp.label.all"),
    sort: t("kf.plp.label.sort"),
    sortbyExpanded: t("kf.plp.label.sortbyExpanded"),
    sortbyClosed: t("kf.plp.label.sortbyClosed"),
    selected: t("kf.plp.arialabel.selected"),
    altSort: t("kf.plp.label.img.altSort"),
    sortByAria: count => t("kf.plp.label.sortByAria", { sortByCount: count }),
    applyFilters: "Apply Filters",
    results: t("kf.plp.label.results"),
    noResults: t("kf.plp.label.noResults"),
    loadMore: t("kf.plp.label.loadMore"),
    backToTop: t("kf.plp.label.backToTop"),
    showMore: t("kf.plp.label.showMore"),
    showLess: t("kf.plp.label.showLess"),
    clearAll: t("kf.plp.label.clearAll"),
    category: t("kf.technical.label.category"),
    collection: t("kf.technical.label.collection"),
    certifications: t("kf.technical.label.certifications"),
    designFiles: t("kf.technical.label.designFiles"),
    technicalInfo: t("kf.pdp.label.specs"),
    installationSupport: t("kf.pdp.label.installationSupport"),
    addToFolder: t("kf.technical.label.addToFolder"),
    selectAll: t("kf.technical.label.selectAll"),
    download: t("kf.technical.label.download"),
    hideDiscontinued: t("kf.plp.label.hideDiscontinued"),
    discontinuedText: t("kf.pdp.label.discontinuedTxt"),
    file: t("kf.technical.label.file"),
    files: t("kf.technical.label.files"),
    specsSheet: t("kf.technical.label.specsSheet"),
    envDeclaration: t("kf.technical.label.envDeclaration"),
    installationGuide: t("kf.technical.label.installationGuide"),
    userGuide: t("kf.technical.label.userGuide"),
    homeOwnerGuide: t("kf.technical.label.homeOwnerGuide"),
    viewResults: t("kf.plp.label.viewResults"),
    twoDplan: t("kf.technical.label.twoDplan"),
    twoDside: t("kf.technical.label.twoDside"),
    twoDfront: t("kf.technical.label.twoDfront"),
    threeDfile: t("kf.technical.label.threeDfile"),
    bimFile: t("kf.technical.label.bimFile"),
    cutoutTemplate: t("kf.technical.label.cutoutTemplate"),
    partsDiagram: t("kf.pdp.label.partsDiagram"),
    guides: t("kf.technical.label.guides"),
    ariaLabel: {
      filterChips: t("kf.plp.label.aria.filterChips"),
    },
    notFoundText: loading || !keyword ? "..." : t("list.notfound", { keyword }),
    featured: t("kf.plp.label.featured"),
    atoz: t("kf.plp.label.atoz"),
    ztoa: t("kf.plp.label.ztoa"),
    // extra added statictexts
    discontinuedTxt: t("kf.pdp.label.discontinuedTxt"),
    filterTitle: (facetName, facetCount) =>
      t("kf.plp.ariaLabel.filterTitle", {
        facetName: facetName,
        facetCount: facetCount,
      }),
    arialLabelPrint: val => t("kf.plp.label.aria.print", { val: val }),
    ariaLabelShare: val =>
      t("kf.plp.label.aria.share", {
        val: `${val} page`,
      }),
  }

  return i18n
}

export default useTechnicalSpecsi18n
