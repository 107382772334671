/**
 *
 * @param {Object} res
 * Data Layer Tech Spec 2022'
 * file: Requirement#20 Country Option Selector
 * Batch 2 - Find a Store
 */
export const countryChangeDataAnalytics = res => {
  const { status, message = "n/a", value } = res
  const isStatus = status === 200
  const eventInfo = {
    eventAction: "find a store:browse all stores​:country option selector",
    eventName: "find a store:browse all stores​:country option selector",
    eventType: "navigation",
    eventMsg: message?.toLowerCase(),
    eventStatus: isStatus ? "success" : "failure",
    internalLinkName: value.toLowerCase(),
    internalLinkPosition: "browse all stores​",
    internalLinkType: "find a store:country option selector",
    internalLinkZoneName: "find a store:browse all stores",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo: eventInfo,
    page: page,
  })
}
