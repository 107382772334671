const getRecaptchaAnalytics = {
  pageTitle: "key-specifier-discount:group code",
  internalLinkPosition: "key-specifier-discount:group code form",
  internalLinkType: "key-specifier-discount",
  internalLinkTypeWithType: "key-specifier-discount",
}

const addSubmitAnalytics = (
  status = "",
  message = "",
  groupCode = "",
  policy1 = "",
  policy2 = "",
  policy3 = ""
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = getState?.("page") || {}
  const eventInfo = {
    ...getEventInfo("submit", null, true),
    eventStatus: status,
    eventMsg: message || "n/a",
    groupCode,
    termsAndConditions: policy1,
    getInspringIdeas: policy2,
    stayUptoDate: policy3,
  }

  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    page,
  })
}

const getEventInfo = (type, link, needsJSON = false) => {
  const eventInfo = {
    eventAction: "key-specifier-discount:group code:" + type,
    eventName: "key-specifier-discount:group code:" + type,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: type,
    internalLinkPosition: "key-specifier-discount:group code form",
    internalLinkType: "key-specifier-discount:" + type,
    internalLinkZoneName: "key-specifier-discount:overlay",
    internalLinkURL: link
      ? (link.indexOf("http") === -1 && link.indexOf("mailto:") === -1
          ? window.location.origin
          : "") + link
      : "n/a",
    clickInternalLinks: "true",
  }
  if (needsJSON) {
    return eventInfo
  }
  return JSON.stringify(eventInfo)
}

export { getRecaptchaAnalytics, addSubmitAnalytics, getEventInfo }
