const getEventInfo = (isRetired, type, link, needsJSON = false) => {
  let extractedLink = ""
  if (link) {
    if (link.indexOf("http") === -1 && link.indexOf("mailto:") === -1) {
      extractedLink = window.location.origin + link
    } else {
      extractedLink = link
    }
  } else {
    extractedLink = "n/a"
  }

  const eventActionType = type === "don't have a kohler email?"
  const eventFor = isRetired ? "Ko" : "email"
  const eventAction = eventActionType
    ? `insider-program-associate-discount:group code:${type}`
    : `insider-program-associate-discount:${eventFor}:${type}`
  const eventInfo = {
    eventAction,
    eventName: eventAction,
    eventType: "click",
    eventMsg: "n/a",
    eventStatus: "n/a",
    formName: "kohler insider program form",
    internalLinkName: type,
    internalLinkPosition:
      "insider-program-associate-discount:" +
      (eventActionType ? "group code form" : "form"),
    internalLinkType: "insider-program-associate-discount:" + type,
    internalLinkZoneName: "insider-program-associate-discount:overlay",
    internalLinkURL: extractedLink,
    clickInternalLinks: "true",
  }
  if (needsJSON) {
    return eventInfo
  }
  return JSON.stringify(eventInfo)
}

export { getEventInfo }
