import { useTranslation } from "next-i18next"

const useKnowledgeArticlei18n = () => {
  const { t } = useTranslation()
  const i18n = {
    print: t("kf.plp.label.print"),
    share: t("kf.plp.label.share"),
    wishlist: t("kf.plp.label.wishlist"),
    showFilter: t("kf.plp.label.showFilter"),
    hideFilter: t("kf.plp.label.hideFilter"),
    filters: t("kf.plp.label.filters"),
    view: t("kf.plp.label.view"),
    all: t("kf.plp.label.all"),
    sort: t("kf.plp.label.sort"),
    applyFilters: "Apply Filters",
    loadMore: t("kf.plp.label.loadMore"),
    backToTop: t("kf.plp.label.backToTop"),
    showMore: t("kf.plp.label.showMore"),
    showLess: t("kf.plp.label.showLess"),
    clearAll: t("kf.plp.label.clearAll"),
    relevancy: t("kf.plp.label.relevancy"),
    date: t("kf.plp.label.date"),
    featured: t("kf.plp.label.featured"),
    filterSort: t("kf.plp.label.filterSort"),
    atoz: t("kf.plp.label.atoz"),
    ztoa: t("kf.plp.label.ztoa"),
    loading: t("kf.search.label.loading"),
    articles: t("kf.ka.label.articles"),
    viewResults: t("kf.plp.label.viewResults"),
    results: t("kf.plp.label.results"),
    items: t("kf.plp.label.items"),
    etc: t("kf.plp.label.etc"),
    sortByAria: count => t("kf.plp.label.sortByAria", { sortByCount: count }),
    ariaLabel: {
      loadMore: t("kf.search.aria.label.loadMore"),
      loadMoreArticles: t("kf.search.aria.label.loadMoreArticles"),
    },
    arialLabelPrint: val => t("kf.plp.label.aria.print", { val: val }),
    ariaLabelShare: val =>
      t("kf.plp.label.aria.share", {
        val: `${val} page`,
      }),
    filterTitle: (facetName, facetCount) =>
      t("kf.plp.ariaLabel.filterTitle", {
        facetName: facetName,
        facetCount: facetCount,
      }),
  }

  return i18n
}

export default useKnowledgeArticlei18n
