export const getSortList = staticTexts => [
  { name: staticTexts.featured },
  { name: staticTexts.atoz, query: "productName_s+asc" },
  { name: staticTexts.ztoa, query: "productName_s+desc" },
]

export const getFLData = persona => [
  "id",
  "slug_s",
  "masterSKU_s",
  "masterCustomerFacingSKU_s",
  "productName_s",
  "Product_Category",
  "productImages.url_s",
  "productImages.url_ss",
  "ProductSustainability_s",
  "ProductBrandName_s",
  "ProductADACompliant_s",
  "ProductBrandNameDisplay_s",
  "productRegionAllVariantDiscontinued_b",
  "RegionDiscontinuedDate_dt",
  "ProductDescriptionProductShort_s",
  `Color.${persona}.Details_ss`,
  "ProductResource.DXFPlanView.resourceFullWebURL_ss",
  "ProductResource.DWGPlanView.resourceFullWebURL_ss",
  "ProductResource.DXFFrontView.resourceFullWebURL_ss",
  "ProductResource.DWGFrontView.resourceFullWebURL_ss",
  "ProductResource.DXFSideView.resourceFullWebURL_ss",
  "ProductResource.DWGSideView.resourceFullWebURL_ss",
  "ProductResource.3DDXFSymbol.resourceFullWebURL_ss",
  "ProductResource.3DDWGSymbol.resourceFullWebURL_ss",
  "ProductResource.3DRevit.resourceFullWebURL_ss",
  "ProductResource.3D3DS.resourceFullWebURL_ss",
  "ProductResource.3DSketchup.resourceFullWebURL_ss",
  "ProductResource.CutOutDXF.resourceFullWebURL_ss",
  "ProductResource.3DOBJ.resourceFullWebURL_ss",
  "ProductResource.SpecPDFFileName.resourceFullWebURL_ss",
  "ProductResource.SpecPDFFileName.resourceTitle_ss",
  "ProductResource.EnvironProdDecl.resourceFullWebURL_ss",
  "ProductResource.EnvironProdDecl.resourceTitle_ss",
  "ProductResource.HomeownersGuide.resourceFullWebURL_ss",
  "ProductResource.HomeownersGuide.resourceTitle_ss",
  "ProductResource.InstallationWithoutSPPDF.resourceFullWebURL_ss",
  "ProductResource.InstallationWithoutSPPDF.resourceTitle_ss",
  "ProductResource.InteractiveSVG.resourceName_ss",
  "ProductResource.InteractiveSVG.resourceTitle_ss",
  "ProductResource.PartsPDFFileName.resourceFullWebURL_ss",
  "ProductProductNo_s",
]
