import { apim } from "@/constants/api"
import qs from "qs"
import { getConfig } from "@/constants/config"
import { searchPlpEndPoint } from "@/constants/index"
import { getSessionIdObjWithEpoc } from "@/utils/ApiUtil"

const getListOfParamsToFetch = persona => {
  const fl = [
    "id",
    "slug_s",
    "productName_s",
    "masterSKU_s",
    "Product_Category",
    "ProductBrandName_s",
    "productImages.url_s",
    "productImages.url_ss",
    "SKUColorSwatchFilename_s",
    "SKUColorSwatchFilename_ss",
    "ProductDescriptionProductShort_s",
    `priceStartingAt.${persona}_d`,
    `discountedPriceStartingAt.${persona}_d`,
    `priceList.${persona}.saleOffer_s`,
    "Color.SKU.Details_ss",
    "Color.SKU.Details_s",
    "ProductWebFeatures_ss",
    "ProductWebFeatures_s",
    "ProductIsExclusive_s",
    "RegionReleaseforShipment_dt",
    "title_s",
    "ProductNarrativeDescription_s",
    `priceList.${persona}.finalPrice_d`,
    "CustomerFacingBrand_s",
    "RegionBrandName_s",
  ]

  return fl.join(",")
}

const getProductDataFromSkuIds = async (persona, services, lwAppName = "") => {
  const fl = getListOfParamsToFetch(persona)
  const fq = services.map(
    item =>
      `variantList.sku_s:("${item.serviceSkuId}") OR variantList.sku_ss:("${item.serviceSkuId}")`
  )

  const config = await getConfig()
  const language = config?.internationalization?.language ?? "en"

  return apim.get(searchPlpEndPoint, {
    params: {
      fl,
      q: "*:*",
      fq: [fq.join(" "), `language_s:("${language}")`],
      collections: lwAppName,
      profilename: `profile_${lwAppName}_General`,
      sessionId: getSessionIdObjWithEpoc(),
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: "repeat", encode: false })
    },
  })
}

export { getProductDataFromSkuIds }
