import React, { useEffect, useState } from "react"
import _get from "lodash/get"
import { isMobile } from "react-device-detect"
import { useDispatch, useSelector } from "react-redux"
import debounce from "lodash/debounce"
import _isEmpty from "lodash/isEmpty"
import TechnicalSpecsView from "@/components/TechnicalSpecs/v1/TechnicalSpecsView"
import useTechnicalSpecsi18n from "@/i18n/useTechnicalSpecsi18n"
import { getConfig } from "@/constants/config"
import usePrevious from "@/hooks/usePrevious"
import {
  getAllFilterQueries,
  getFacetsFromUrl,
  getUserPersona,
} from "@/utils/helper"
import {
  getFLData,
  getSortList,
} from "@/components/TechnicalSpecs/technicalSpecHelper"
import {
  DEFAULT_TECHNICAL_SPECT_COUNT,
  PRODUCTS_PER_PAGE,
  TECHNICAL_SPECT_SEARCH_COUNT,
} from "@/constants/index"
import {
  clearSuggestion,
  getProducts,
  selectProductListState,
} from "@/store/features/productListSlice"
import { addAnalyticsForSearch } from "@/components/TechnicalSpecs/v1/analytics"

const TechnicalSpecs = props => {
  const dispatch = useDispatch()
  const {
    data = [],
    total,
    fusion = {},
    facets = [],
    facetLabels,
    status: loading,
    colors,
    suggestions,
    suggestionExactMatch,
  } = useSelector(selectProductListState).plp

  const [searchedKeyword, setSearchedKeyword] = useState("")
  const [preFilterFlag, setPrefilterFlag] = useState(null)
  const { data: componentProps } = props
  const staticTexts = useTechnicalSpecsi18n({
    componentProps,
    loading,
    searchedKeyword,
  })
  const { featured = "", discontinuedTxt = "" } = staticTexts
  const productsPerPage = PRODUCTS_PER_PAGE

  const [isSearch, setIsSearch] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState("")
  const [rows, setRows] = useState()

  const [selectedSort, setSelectedSort] = useState({
    name: featured,
    query: ``,
  })
  const [curFilterQueries, setCurFilterQueries] = useState([])
  const [info, setInfo] = useState("")
  const sort = getSortList(staticTexts)
  const [start, setStart] = useState(0)

  const prevSort = usePrevious(selectedSort)
  const prevFilter = usePrevious(curFilterQueries)
  const prevPreSelect = usePrevious(preFilterFlag)
  const prevRows = usePrevious(rows)
  const prevSearchKeyword = usePrevious(searchKeyword)

  const selectedFilterCount = curFilterQueries
    ? curFilterQueries.reduce((acc, item) => {
        return acc + item.value.length
      }, 0)
    : 0
  const technicalArg = 'Technical:("Yes")'
  const hideDiscontinued = [
    {
      facet: "Hide_Discontinued",
      value: ["true"],
      display: [discontinuedTxt],
    },
  ]

  const emptyRow = 0

  const {
    currencySign = "",
    persona = "",
    marketParamValues = [],
    lwAppName = "",
  } = info

  useEffect(() => {
    getConfig().then(async config => {
      const brandName = config?.general?.siteName ?? ""
      const locale = _get(config, "internationalization.locale", "")
      const currencySign = _get(config, "internationalization.currencySign", "")
      const currencyCode = _get(config, "internationalization.currencyCode", "")
      const marketParamValues = _get(config, "marketParamValues", "")
      const language = _get(config, "internationalization.language", "en")

      const { general: { lwAppName = "" } = {} } = config ?? {}

      const persona = getUserPersona()
      const info = {
        marketParamValues,
        brandName,
        locale,
        currencySign,
        currencyCode,
        persona,
        language,
        lwAppName,
      }
      const rowMob = isMobile
        ? DEFAULT_TECHNICAL_SPECT_COUNT
        : parseInt(sessionStorage.getItem("rows")) ||
          DEFAULT_TECHNICAL_SPECT_COUNT

      setInfo(info)
      setRows(rowMob)
    })
  }, [])

  useEffect(() => {
    // will check for url filter
    if (info) handleFetchFacetFromUrl()
  }, [info])

  useEffect(() => {
    if (preFilterFlag != null && info) {
      // will call filter in url exists
      if (preFilterFlag) {
        generateListingUrl("preSelectFIlter")
      }
      // will call after preselectFilter/ Initial call
      else if (!preFilterFlag && preFilterFlag != prevPreSelect) {
        // prevPreSelect null indicate no url filters
        prevPreSelect === null ? generateListingUrl() : updateUrlFilterQueries()
      }
      // will trigger from handleLoadMore
      else if (start) {
        generateListingUrl(null, true)
      }
      // will trigger from handleRows
      else if (start == 0 && rows && !!prevRows && prevRows != rows) {
        generateListingUrl(null, false)
      }
      // when there is a change in sort
      else if (prevSort && prevSort?.query != selectedSort?.query) {
        generateListingUrl()
      }
      // when there is a change in filter
      else if (prevFilter && prevFilter != curFilterQueries) {
        generateListingUrl()
      }
    }
  }, [preFilterFlag, info, selectedSort, curFilterQueries, start, rows])

  const updateUrlFilterQueries = () => {
    let currFilters = hideDiscontinued
    const urlFilters = getAllFilterQueries(
      { attributeName: "" }, // this arg to avoid inner condition
      fusion,
      marketParamValues
    )
    currFilters = [...currFilters, ...urlFilters]
    setCurFilterQueries(currFilters)
  }

  useEffect(() => {
    if (
      (searchKeyword.length > 2 && prevSearchKeyword != searchKeyword) ||
      isSearch
    )
      handleSearchSuggestion(!isSearch ? "suggestion" : "")
  }, [searchKeyword, isSearch])

  const handleFetchFacetFromUrl = () => {
    let currFilters = hideDiscontinued
    const urlFilters = getFacetsFromUrl(marketParamValues)
    currFilters = [...currFilters, ...urlFilters]
    // not setting filterquery here, fusion required
    if (urlFilters.length > 0) setPrefilterFlag(true)
    else {
      // update only on no prefilter
      setCurFilterQueries(currFilters)
      setPrefilterFlag(false)
    }
  }

  const generateListingUrl = (genarate = "", update = false) => {
    const queries = curFilterQueries
    const params = {
      profileName: `profile_${lwAppName.toLowerCase()}${
        genarate === "suggestion" ? "_Typeahead" : "_Search"
      }`,
      collections: lwAppName.toLowerCase(),
      isFaq: true,
    }
    // pre-filter param data
    if (genarate === "preSelectFIlter") {
      params.fl = "id"
      params.q = "*:*"
      params.rows = emptyRow
    }
    // use for suggestion
    else if (genarate === "suggestion") {
      params.q = searchKeyword
      params.fq = technicalArg
      params.rows = TECHNICAL_SPECT_SEARCH_COUNT
      params.fl = "productName_s"
      params.isSuggestion = searchKeyword
    } else {
      // all other calls
      const fl = getFLData(persona)
      const fq = [technicalArg]
      queries.forEach(q => {
        const tag = `{!tag="${q.facet}"}`
        fq.push(
          `${encodeURIComponent(tag + q.facet)}:("${encodeURIComponent(
            q.value.join('","')
          )}")`
        )
      })

      params.q = "*"
      params.fl = fl.join(",")
      params.rows = rows
      params.fq = fq
      params.start = start ?? emptyRow
      params.selectedTitle = searchKeyword ?? ""
      params.update = update
      if (searchKeyword.length > 2) {
        params.q = suggestionExactMatch ?? searchKeyword
      }
      if (selectedSort.name !== staticTexts?.featured && selectedSort.name) {
        params.sort = selectedSort.query
      }
    }

    if (!params.fq) {
      params.fq = `language_s:("${info.language}")`
    } else {
      if (typeof params.fq === "string" && params.fq.length > 0) {
        params.fq = [params.fq, `language_s:("${info.language}")`]
      } else if (Array.isArray(params.fq)) {
        params.fq = [...params.fq, `language_s:("${info.language}")`]
      }
    }

    dispatch(getProducts({ ...params }))
      .unwrap()
      .then(() => {
        if (preFilterFlag) {
          setPrefilterFlag(false)
        }
        if (isSearch) {
          setIsSearch(false)
          addAnalyticsForSearch(searchedKeyword, total, searchKeyword)
        }
      })
      .catch(err => console.error(err))
  }

  const handleSearchSuggestion = debounce(actionkey => {
    setSearchedKeyword(searchKeyword)
    generateListingUrl(actionkey)
  }, 500)

  const handleSort = item => {
    setStart(0)
    setSelectedSort(item)
  }

  const handleRows = rows => {
    setStart(0)
    setRows(rows)
    sessionStorage.setItem("rows", rows)
  }

  const handleSearch = item => {
    setIsSearch(true)
    setSearchKeyword(item)
    handleClearSuggestion()
  }

  const handleClearSuggestion = () => {
    dispatch(clearSuggestion())
  }

  const handleLoadMore = () => setStart(parseInt(start) + parseInt(rows))

  return !_isEmpty(staticTexts) ? (
    <TechnicalSpecsView
      staticTexts={staticTexts}
      searchKeyword={searchKeyword}
      suggestions={suggestions}
      curRows={rows}
      data={data}
      filters={facets}
      onFilter={setCurFilterQueries}
      facetLabels={facetLabels}
      sorts={sort}
      selectedSort={selectedSort}
      onSearch={handleSearch}
      onSuggest={setSearchKeyword}
      clearSuggestions={handleClearSuggestion}
      onSort={handleSort}
      onRows={handleRows}
      onLoadMore={handleLoadMore}
      productsPerPage={productsPerPage}
      totalResults={total}
      currencySign={currencySign}
      colors={colors}
      currFilters={curFilterQueries}
      selectedFilterCount={selectedFilterCount}
    />
  ) : null
}

export default TechnicalSpecs
