import React, { useEffect, useState } from "react"
import { useTranslation } from "next-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useRouter } from "next/router"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"

import { getConfig } from "@/constants/config"
import useIsSsr from "@/hooks/useIsSsr"
import { cleanText, getShortenedUrl } from "@/utils/helper"
import KnowledgeArticleFiller from "@/components/KnowledgeArticle/v1/KnowledgeArticleFiller"
import InspirationTab from "@/components/Search/v1/InspirationTab"
import PageTitle from "@/components/core/PageTitle/PageTitle"
import { PRODUCTS_PER_PAGE } from "@/constants/index"
import useKnowledgeArticlei18n from "@/i18n/useKnowledgeArticlei18n"

import {
  getProducts,
  selectProductListState,
} from "@/store/features/productListSlice"

import AemGrid from "@/components/AemGrid"

const KnowledgeArticle = ({ data = {}, urlNames = "", data404 = {} }) => {
  const isSsr = useIsSsr()
  const router = useRouter()
  const dispatch = useDispatch()
  const [lwAppName, setLwAppName] = useState("")
  const [state, setState] = useState({
    id: "",
  })
  const [knowledgeArticlePath, setKnowledgeArticlePath] = useState("")
  const [show404, setShow404] = useState(false)
  const staticTexts = useKnowledgeArticlei18n()
  const { t } = useTranslation()
  const { total = 0 } = useSelector(selectProductListState).plp
  const { title = "", description = "" } = data

  const handle404 = async (queryParams = {}) => {
    const { payload: { data: { data: finalData = [] } = {} } = {} } =
      await dispatch(getProducts({ ...queryParams }))

    if (!finalData.length) {
      setShow404(true)
    }
  }

  const executeProductsAPI = (urlNames = "", appName = "") => {
    if (urlNames && appName) {
      const fl = [
        "id",
        "url_s",
        "urlName_s",
        "title_s",
        "pageTitle_s",
        "eyeBrowText_s",
        "thumbNailImage_s",
        "searchDescription_s",
        "layoutItems.Article_Type__c.value_s",
        "layoutItems.KM_Article_Content__c.value_s",
        "layoutItems.KBNA_3C_Image_Video__c.label_s",
        "layoutItems.KBNA_3C_Image_Video__c.value_s",
      ]
      const queryParams = {
        q: "*:*",
        start: 0,
        rows: 30,
        isFaq: true,
        displaytype: "",
        fl: fl.join(","),
        fq: `urlName_s:("${urlNames}")`,
        collections: appName.toLowerCase(),
        profileName: `profile_${appName.toLowerCase()}_PLP_Knowledge_Article`,
      }

      handle404(queryParams)
    }
  }
  useEffect(() => {
    getConfig().then(config => {
      const knowledgeArticlePath = _get(
        config,
        "general.knowledgeArticlePath",
        ""
      )
      const currPage =
        knowledgeArticlePath.split("/").pop().replace(".html", "") ?? ""
      const isArticleUrlPath = !urlNames?.match(currPage)
      const { general: { lwAppName: appName = "" } = {} } = config ?? {}
      getShortenedUrl(knowledgeArticlePath).then(url => {
        setKnowledgeArticlePath(url)
      })
      setLwAppName(appName)
      if (data) {
        setState({ ...state, id: isArticleUrlPath ? urlNames : "" })
      } else {
        console.error("Knowledge Articles: Get URL Property not found")
      }
      executeProductsAPI(urlNames, appName)
    })

    return () => {}
  }, [router, urlNames])

  return show404 ? (
    !isSsr ? (
      <AemGrid data={data404} />
    ) : null
  ) : !_isEmpty(staticTexts) && !_isEmpty(lwAppName) ? (
    <main
      tabIndex="-1"
      label="KnowledgeArticle"
      className={`knowledge-article product-listing `}
    >
      {!urlNames ? (
        <PageTitle
          title={title}
          desc={cleanText(description)}
          total={`${total} ${staticTexts.articles}`}
        />
      ) : null}
      {isSsr ? (
        <KnowledgeArticleFiller />
      ) : (
        <InspirationTab
          page="article"
          type="Help"
          staticTexts={staticTexts}
          translate={t}
          displayShare={true}
          displayPrint={true}
          articlesText={staticTexts.articles}
          totalSearchResults={total}
          productsPerPage={PRODUCTS_PER_PAGE}
          profileName={`profile_${lwAppName.toLowerCase()}_PLP_Knowledge_Article`}
          pageId={state.id}
          knowledgeArticleUrl={knowledgeArticlePath}
          disableThumbnail={true}
          lwAppName={lwAppName}
          isReplace
          isUpdateUrl
          isSearchTab={false}
          isFaq={true}
          showTotal={false}
        />
      )}
    </main>
  ) : null
}

export default KnowledgeArticle
