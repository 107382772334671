import React from "react"
import axios from "axios"
import { sanitizeInnerHtml } from "@/utils/helper"
import fallbackImage from "@/public/images/image-missing.png"
import downloadIcon from "@/public/icons/download-dark-gray.svg"

import { downloadEventInfo } from "@/components/Literature/v1/literatureAnalytics"
import DynamicImage from "@/components/core/Image/DynamicImage"

const LiteratureTile = props => {
  const {
    image = "",
    imgId = "",
    brandName = "",
    title = "",
    externalLink = "",
    i18n = {},
  } = props
  const imgPlaceholder = image
    ? image
    : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"

  const handleImgErr = e => (e.target.src = fallbackImage)
  const getImg = img => {
    let imgURL
    const hostName = img.split(".").splice(0, 2).join(".")
    if (brandName.toLowerCase() !== "kohler" && img.includes(hostName)) {
      imgURL = img.split(".").splice(0, 3).join(".")
    }
    return imgURL
  }

  const checkImg = e => {
    e.persist()
    const ele = e.target
    if (ele.src !== fallbackImage && ele.src === imgPlaceholder) {
      axios
        .head(ele.dataset.src)
        .then(res => {
          if (!res) handleImgErr(e)
        })
        .catch(() => handleImgErr(e))
    }
  }

  return (
    <div className="literature-tile">
      <DynamicImage
        id={imgId}
        onLoad={checkImg}
        imgUrl={getImg(image) || image}
        alt={title}
        className="literature-tile__image"
        uniqueId={imgId}
        onError={handleImgErr}
      />
      <div className="literature-tile__title">{sanitizeInnerHtml(title)}</div>
      {externalLink && (
        <a
          aria-label={`${i18n.download} ${title} ${i18n.ariaLabel.pdf}`}
          href={externalLink}
          className="literature-tile__download-cta gbh-data-layer"
          target="_blank"
          rel="noopener noreferrer"
          data-gbh-data-layer={downloadEventInfo(title)}
        >
          <img src={downloadIcon.src} alt={i18n.download} />
          {i18n.download}
        </a>
      )}
    </div>
  )
}
export default LiteratureTile
