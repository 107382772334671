import React from "react"

import pageTitleStyle from "@/components/core/PageTitle/index.module.scss"

const PageTitle = ({ title = "", desc = "", total = 0 }) => {
  return (
    <div className={pageTitleStyle.pageTitleWrapper}>
      <div className="container kf-react-plp-container kf-react-plp-container__container-border">
        <div className="kf-page-title">
          <div className="kf-page-title__titleBox">
            <h1 className="kf-page-title__title">{title}</h1>
            <p
              aria-label={total}
              className="kf-page-title__total d-none d-lg-block"
            >
              {total}
            </p>
          </div>
          <div className="kf-page-title__descBox">
            <h2 className="kf-page-title__desc">{desc}</h2>
          </div>
        </div>
        <p className="kf-page-title__total d-lg-none">{total}</p>
      </div>
    </div>
  )
}

export default PageTitle
