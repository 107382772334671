import React, { useState, useEffect } from "react"
import cx from "classnames"
import LazyLoad from "react-lazy-load"
import { isMobile, isTablet } from "react-device-detect"
import Sticky from "react-sticky-el"
import { scroller } from "react-scroll"
import { useDispatch, useSelector } from "react-redux"

import useWindowResize from "@/hooks/useWindowResize"
import usePrevious from "@/hooks/usePrevious"

import Sort from "@/components/ProductList/v2/Sort/index"
import Filter from "@/components/ProductList/v2/Filter"
import {
  scrollToProduct,
  scrollToFirstProduct,
} from "@/components/Search/v1/ProductsTab/helper"

import {
  getShowHideEventInfo,
  backToTopEventInfo,
  loadMoreEventInfo,
} from "@/components/Literature/v1/literatureAnalytics"
import LiteratureTile from "@/components/Literature/v1/Literature.view/LiteratureTile"
import ProductsPerPage from "@/components/ProductList/v2/ProductListView/ProductsPerPage"

import {
  getProducts,
  selectProductListState,
} from "@/store/features/productListSlice"
import { selectAuthState } from "@/store/features/authSlice"

import { DEFAULT_PRODUCT_COUNT_PLP } from "@/constants"
import { getConfig } from "@/constants/config"

import {
  getFacetsFromUrl,
  getSortItemBasedOnURL,
  removeMavenoidChat,
} from "@/utils/helper"

import shareIcon from "@/public/icons/share.svg"
import filterIcon from "@/public/icons/filter.svg"
import printIcon from "@/public/icons/print.svg"
import backToTop from "@/public/icons/back-top-arrow.svg"
import columnOffIcon from "@/public/icons/column-off.svg"
import columnOnIcon from "@/public/icons/column-on.svg"
import gridOffIcon from "@/public/icons/grid-off.svg"
import gridOnIcon from "@/public/icons/grid-on.svg"

const LiteratureView = props => {
  const {
    staticTexts = {},
    displayShare = false,
    displayPrint = false,
    isLandscape = false,
    productsPerPage = [],
    setTotal = () => {},
  } = props

  const selectedSortItem = getSortItemBasedOnURL(staticTexts, {
    name: staticTexts.atoz,
    query: "title_s+asc",
  })
  const dispatch = useDispatch()

  const [generalInfo, setGeneralInfo] = useState({})
  const [width] = useWindowResize()
  const [urlCheck, setUrlCheck] = useState(false)

  const [showFilter, setShowFilter] = useState(width >= 1024 ? true : false)
  const [rowCols, setRowCols] = useState(width < 1024 ? 1 : 3)
  const [navSticky, setNavSticky] = useState(false)
  const [filters] = useState([])
  const [curRows, setCurRows] = useState(DEFAULT_PRODUCT_COUNT_PLP)
  const [start, setStart] = useState(0)

  const [selectedSort, setSelectedSort] = useState(selectedSortItem)
  const [filterQueries, setFilterQueries] = useState([])
  const [selectedFilterCount, setSelectedFilterCount] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [scrollToSku, setScrollToSku] = useState(
    sessionStorage?.plpScrollSku ?? ""
  )
  const prevRows = usePrevious(curRows)
  const [dataPopulated, setDataPopulated] = useState(false)

  const {
    data = [],
    total = 0,
    facets = {},
    facetLabels = {},
    colors = {},
  } = useSelector(selectProductListState).plp

  const { access_token: accessToken } = useSelector(selectAuthState)

  useEffect(() => {
    getConfig().then(async config => {
      const { general = {} } = config ?? {}
      setGeneralInfo(general)
    })
  }, [])
  const filterClass = cx({
    "product-list__filter": true,
    "product-list__filter--hide": !showFilter,
    "product-list__filter--modal": showFilter,
  })
  const filterButtonClass = cx({
    "product-list__filter-toggle-text": true,
    "product-list__filter-toggle-text--show": !showFilter,
  })
  const tileClass = cx({
    "product-list__tile-col": true,
    "product-list__tile-col--full-width": !showFilter,
    "literature-list__tile-col": true,
  })

  const productListTileClass = cx({
    container: true,
    "kf-react-container": true,
    "product-list__tiles": false,
    "product-list__tiles-literature": true,
  })
  const sorts = [
    { name: staticTexts.atoz, query: "title_s+asc" },
    { name: staticTexts.ztoa, query: "title_s+desc" },
    { name: staticTexts.newToOld, query: "last_updated_date+desc" },
    { name: staticTexts.oldToNew, query: "last_updated_date+asc" },
  ]

  useEffect(() => {
    if (isLandscape && width) {
      switch (true) {
        case width > 1024:
          setRowCols(3)
          break
        case width < 668:
          setRowCols(2)
          break
        default:
          setRowCols(3)
      }
    }
  }, [isLandscape, width])

  useEffect(() => {
    const filterCount = filterQueries.reduce((acc, item) => {
      return acc + item.value.length
    }, 0)
    setSelectedFilterCount(filterCount)
  }, [filters])

  useEffect(() => {
    const urlFilters = getFacetsFromUrl()
    if (urlFilters.length > 0) setFilterQueries(urlFilters)
    setUrlCheck(true)
  }, [])
  useEffect(() => {
    setTotal(total)
  }, [total])

  useEffect(() => {
    // To scrollup the product list on back
    if (dataPopulated) scrollToProduct()
  }, [dataPopulated])

  const onRows = rows => {
    setStart(0)
    setCurRows(rows)
  }
  const executeScroll = () => {
    scroller.scrollTo("products-tab", {
      duration: 1500,
      delay: 0,
      smooth: "easeInOutQuart",
    })
  }

  const onLoadMore = () => {
    setStart(start + curRows)
  }

  const onSort = selectedSort => {
    setStart(0)
    setSelectedSort(selectedSort)
    setIsOpen(false)
  }
  useEffect(() => {
    if (accessToken && filterQueries != null) {
      // will trigger from onLoadMore
      if (start) {
        generateListingUrl(true, false)
        // Will trigger when user coming back from PDP page
      } else if (!start && scrollToSku) {
        setScrollToSku("")
        generateListingUrl(false, true)
      }
      // When downgrade pagination
      else if (!start && curRows && !!prevRows && prevRows > curRows) {
        generateListingUrl(false, true)
      }
      // will trigger from onRows. And rest of the dependencies
      else if (!start && curRows) {
        generateListingUrl(false, false)
      }
    }
  }, [accessToken, start, curRows, selectedSort, filterQueries, urlCheck])
  const generateListingUrl = (update, enableScroll = true, queries) => {
    let callStart = 0
    if (queries) {
      setFilterQueries(queries)
    } else {
      queries = filterQueries
      callStart = start
    }

    const fq = [""]
    queries.forEach(q => {
      const tag = `{!tag="${q?.facet}"}`
      if (!q?.facet?.match(/^\*{2}/)) {
        fq.push(
          encodeURIComponent(`${tag + q.facet}:("${q.value.join('" OR "')}")`)
        )
        return
      }
      fq.push(
        `${q.facet.replace(/^\*{2}/, "")}:("${encodeURIComponent(
          q.value.join('","')
        )}")`
      )
    })

    const query = {
      fq,
      rows: curRows,
      start: callStart,
    }
    if (selectedSort.name !== staticTexts.featured) {
      if (selectedSort.name) {
        query.sort = selectedSort.query
      }
    }
    const fl = [
      "id",
      "url_s",
      "title_s",
      "thumbnailImageURL_s",
      "externalRedirectUrl_s",
    ]

    const { lwAppName = "" } = generalInfo

    const queryParams = {
      q: "*:*",
      fl: fl.join(","),
      fq: query.fq,
      sort: query.sort,
      rows: query.rows || 30,
      start: query.start || 0,
      collections: lwAppName?.toLowerCase(),

      update,
      query: {},
      isFaq: true,
      profileName: `profile_${lwAppName?.toLowerCase()}_PLP_Literature`,
    }

    if (enableScroll) setDataPopulated(false)

    dispatch(getProducts(queryParams))
      .then(() => {
        if (enableScroll) setDataPopulated(true)
      })
      .catch(() => {
        if (enableScroll) setDataPopulated(false)
      })
  }

  const isOnlyMobile = isMobile && !isLandscape && !isTablet
  const isMobileLandscape = isMobile && isLandscape && !isTablet
  const isMobileLandscapeTablet =
    isMobile && !isLandscape && isTablet && width < 1024
  const handleFilter = queries => {
    setFilterQueries(queries)
    scrollToFirstProduct()
  }

  return (
    <main className="products-tab">
      <Sticky
        onFixedToggle={() => setNavSticky(!navSticky)}
        className={`product-list__sticky-nav-wrap ${
          navSticky && "product-list__sticky-nav-container"
        }`}
        stickyStyle={{ top: "0px", zIndex: 9 }}
      >
        <div
          className={`container-fluid product-list__sticky-nav ${
            navSticky && "product-list__sticky-nav--shadow"
          }`}
        >
          <div className="row mx-0 px-0">
            <div className="container kf-react-container">
              <div className="row">
                <div className="col">
                  <div
                    className={`product-list__grid-control ${
                      navSticky && "product-list__grid-control-literature"
                    } literature-list__grid-control`}
                  >
                    <div className="d-block d-sm-none">
                      <button
                        tabIndex="0"
                        aria-label={
                          rowCols > 1
                            ? staticTexts.ariaLabel.columnViewNotSelected
                            : staticTexts.ariaLabel.columnViewSelected
                        }
                        className="product-list__transparent-button"
                        onClick={() => setRowCols(1)}
                      >
                        <img
                          role="presentation"
                          className="product-list__grid-control-icon"
                          src={
                            rowCols > 1 ? columnOffIcon.src : columnOnIcon.src
                          }
                          alt={staticTexts.columnView}
                        />
                      </button>
                      <button
                        tabIndex="0"
                        aria-label={
                          rowCols > 1
                            ? staticTexts.ariaLabel.gridViewSelected
                            : staticTexts.ariaLabel.gridViewNotSelected
                        }
                        className="product-list__transparent-button"
                        onClick={() => setRowCols(2)}
                      >
                        <img
                          role="presentation"
                          className="product-list__grid-control-icon"
                          src={rowCols > 1 ? gridOnIcon.src : gridOffIcon.src}
                          alt={staticTexts.gridView}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="product-list__controls">
                    <button
                      role="link"
                      aria-label={
                        !showFilter
                          ? staticTexts.showFilter
                          : staticTexts.hideFilter
                      }
                      className="product-list__filter-toggle gbh-data-layer"
                      data-gbh-data-layer={getShowHideEventInfo(showFilter)}
                      onClick={() => {
                        isMobile && removeMavenoidChat()
                        setShowFilter(!showFilter)
                      }}
                    >
                      <img
                        src={filterIcon.src}
                        className="product-list__filter-toggle-icon"
                        alt="Toggle Filter"
                      />
                      {isOnlyMobile ? (
                        <span>
                          {staticTexts.filters}{" "}
                          {selectedFilterCount > 0 &&
                            ` (${selectedFilterCount})`}
                        </span>
                      ) : isMobileLandscape ? (
                        <span>
                          {staticTexts.filters}{" "}
                          {selectedFilterCount > 0 &&
                            ` (${selectedFilterCount})`}
                        </span>
                      ) : isMobileLandscapeTablet ? (
                        <span>
                          {staticTexts.filters}{" "}
                          {selectedFilterCount > 0 &&
                            ` (${selectedFilterCount})`}
                        </span>
                      ) : (
                        <ul className={filterButtonClass}>
                          <li className={showFilter ? "show" : "hide"}>
                            {staticTexts.hideFilter}
                          </li>
                          <li className={!showFilter ? "show" : "hide"}>
                            {staticTexts.showFilter}
                          </li>
                        </ul>
                      )}
                    </button>

                    {
                      <section>
                        <Sort
                          tabIndex="0"
                          data={sorts}
                          selectedSort={selectedSort}
                          texts={staticTexts}
                          onChange={onSort}
                          page="literature"
                          isOpen={isOpen}
                          setIsOpen={setIsOpen}
                        />
                      </section>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sticky>

      <div className={productListTileClass}>
        <div className="row">
          <div className="col">
            <div>
              <aside className={filterClass}>
                <div className="product-list__filter--sticky">
                  <Filter
                    rowCols={rowCols}
                    texts={staticTexts}
                    sorts={sorts}
                    selectedSort={selectedSort}
                    onSortChange={onSort}
                    currFilters={filterQueries}
                    selectedFilterCount={
                      filterQueries &&
                      filterQueries.reduce((acc, item) => {
                        return acc + item.value.length
                      }, 0)
                    }
                    onFilter={handleFilter}
                    close={setShowFilter}
                    colors={colors}
                    displayShare={displayShare}
                    displayPrint={displayPrint}
                    showFilter={showFilter}
                    handleScroll={executeScroll}
                    totalResults={total}
                    showSort={width <= 991}
                    page="literature"
                    facetData={facets}
                    facets={filters}
                    facetLabels={facetLabels}
                    addToUrl={false}
                    hidePriceRange
                    wrapperClass=".literature-list-wrapper"
                  />
                </div>
              </aside>
              <div className={tileClass}>
                <div
                  role="listbox"
                  className={`literature-list ${
                    rowCols === 2 && "literature-list__two-col"
                  } ${rowCols === 1 && "literature-list__one-col"}`}
                >
                  {data.map((element, i) => {
                    return (
                      <div
                        role="listitem"
                        key={i}
                        className="literature-list-wrapper"
                      >
                        <LazyLoad>
                          <LiteratureTile
                            key={element.id}
                            imgId={`s7img-${element.id}`}
                            title={element.title_s}
                            image={element.thumbnailImageURL_s}
                            url={element.url_s}
                            i18n={staticTexts}
                            brandName={generalInfo?.siteName}
                            externalLink={element.externalRedirectUrl_s}
                            data={element}
                            displayShare={displayShare}
                          />
                        </LazyLoad>
                      </div>
                    )
                  })}
                </div>
                {
                  <>
                    <div>
                      {total > curRows && total > data.length && (
                        <button
                          aria-label={staticTexts.ariaLabel.loadMore}
                          className="product-list__load-more gbh-data-layer"
                          data-gbh-data-layer={loadMoreEventInfo}
                          onClick={onLoadMore}
                        >
                          {staticTexts.loadMore}
                        </button>
                      )}
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="product-list__back-to-top">
                          {isMobile && (
                            <div className="product-list__print-and-share">
                              <span
                                role="button"
                                onClick={() => window.print()}
                              >
                                {staticTexts.print}{" "}
                                <img
                                  src={printIcon.src}
                                  alt={staticTexts.print}
                                />
                              </span>
                              {displayShare && (
                                <span role="button">
                                  {staticTexts.share}{" "}
                                  <img
                                    src={shareIcon.src}
                                    alt={staticTexts.share}
                                  />
                                </span>
                              )}
                            </div>
                          )}
                          {!isMobile && (
                            <ProductsPerPage
                              page="literature"
                              curRows={curRows}
                              data={productsPerPage}
                              totalResults={total}
                              texts={props.staticTexts}
                              onChange={onRows}
                            />
                          )}

                          <button
                            className="product-list__go-back gbh-data-layer"
                            role="link"
                            aria-label={staticTexts.backToTop}
                            data-gbh-data-layer={backToTopEventInfo}
                            onClick={() => {
                              window.scrollTo({ top: 0, behavior: "smooth" })
                              document
                                .querySelector('a[href]:not([href^="#"]')
                                .focus()
                            }}
                          >
                            {staticTexts.backToTop}{" "}
                            <img
                              aria-hidden="true"
                              src={backToTop.src}
                              alt={staticTexts.backToTop}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default LiteratureView
