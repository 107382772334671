import React, { useState, useEffect, useRef } from "react"
import cx from "classnames"
import Collapsible from "react-collapsible"
import { isMobile } from "react-device-detect"

import { preventBodyScroll } from "@/utils/helper"
import {
  addAnalyticsData,
  addFilterExpandCollapseAnalytics,
  getEventClearFilterInfo,
} from "@/components/FindStore/v1/analytics"
import Checkbox from "@/components/core/Checkbox/Checkbox"
import filterIcon from "@/public/icons/filter.svg"
import closeIcon from "@/public/icons/close.svg"

const Filter = props => {
  const {
    texts = {},
    onFilter = () => {},
    showFilter = false,
    close = () => {},
    selectedFilterCount = "",
    facets = {},
    locationRef = "",
  } = props

  const filterRef = useRef(null)
  const [collapse, setCollapse] = useState({})
  const [appliedFilters, setAppliedFilters] = useState([])

  const formatFacetName = (name, filter) => {
    let val = name
      .replace(/\./g, "/")
      .replace(/_/g, " ")
      .replace(/^\*{2}/, "")
    if (filter) val += ` - ${filter}`
    return val
  }
  const facetFilter = (arr, obj) => {
    return arr.find(filter => filter.facet === obj.facet)
  }

  const isSelected = obj => {
    const facet = facetFilter(appliedFilters, obj)
    if (!facet) return false
    return facet.display.includes(obj.display)
  }

  const handleFilter = (checked, obj, isFilterCancel) => {
    let curFilters = [...appliedFilters]
    const facet = facetFilter(curFilters, obj)

    if (checked) {
      if (facet) {
        facet.value.push(obj.value.replace(/"/g, '\\"'))
        facet.display.push(obj.value)
        facet.query.push(obj.query)
      } else
        curFilters.push({
          facet: obj.facet,
          value: [obj.value.replace(/"/g, '\\"')],
          display: [obj.value],
          query: [obj.query],
        })
    } else {
      const index = facet.display.indexOf(obj.value)
      facet.value.splice(index, 1)
      facet.display.splice(index, 1)
      facet.query.splice(index, 1)

      if (!facet.value.length) {
        curFilters = curFilters.filter(f => f.facet !== obj.facet)
      }
    }
    setAppliedFilters(curFilters)
    addAnalyticsData(curFilters, checked, obj, isFilterCancel)
    onFilter(curFilters, true)
  }

  const handleCollapse = (facet, state) => {
    setCollapse({ ...collapse, [facet]: state })
    addFilterExpandCollapseAnalytics(facet, formatFacetName(facet), {
      [facet]: state,
    })
  }

  const clearAllFilters = () => {
    setAppliedFilters([])
    onFilter([], true)
  }

  useEffect(() => {
    if (showFilter) {
      window.addEventListener("keydown", handleKeyPress)
    }
    if (isMobile && window.innerWidth < 1024) {
      preventBodyScroll(showFilter)
    }
    return () => {
      window.removeEventListener("keydown", handleKeyPress)
      preventBodyScroll(false)
    }
  }, [showFilter])

  /**
   *
   * @param {Event} e
   */
  const handleKeyPress = e => {
    const focusElements = '[tabindex]:not([tabindex="-1"])'
    const focusContent = filterRef.current?.querySelectorAll(focusElements)
    const lastElem = focusContent[focusContent.length - 1]
    if (e.key === "Tab" && !e.shiftKey && document.activeElement === lastElem) {
      e.preventDefault()
      locationRef.current?.focus()
    }
  }

  return (
    <div className={cx("filter", "filter--show-less")}>
      <div className="filter__inner-container">
        <div className="product-list__controls filter__mobile-header">
          <button className="product-list__filter-toggle">
            <img
              aria-hidden
              role="presentation"
              src={filterIcon?.src}
              className="product-list__filter-toggle-icon"
              alt="Toggle Filter"
            />
            <span>{texts.filters}</span>
          </button>
          <img
            role="button"
            tabIndex="0"
            src={closeIcon?.src}
            className="product-list__close-icon"
            alt="Close Filter"
            onClick={() => close(false)}
          />
        </div>

        <div className="filter__chips">
          {appliedFilters.map(facet => {
            return facet.display.map(filter => (
              <div className="filter__chips-tag" key={filter}>
                <span>{filter}</span>
                <img
                  role="button"
                  tabIndex="0"
                  src={closeIcon?.src}
                  alt="Close"
                  className="filter__chips-close-icon"
                  onClick={() =>
                    handleFilter(
                      false,
                      { facet: facet.facet, value: filter, query: filter },
                      true
                    )
                  }
                />
              </div>
            ))
          })}
          {appliedFilters.length ? (
            <div
              className="filter__chips-clear-all gbh-data-layer"
              data-gbh-data-layer={getEventClearFilterInfo()}
              onClick={clearAllFilters}
              role="button"
              tabIndex="0"
              aria-label={texts.clearAll}
            >
              {texts.clearAll}
            </div>
          ) : null}
        </div>
        <div id="kf-store-filter" ref={filterRef}>
          {Object.entries(facets).map(facet => {
            const facetName = facet[0]
            const facetCount = facet[1].length
            return (
              <Collapsible
                open={collapse[facet[0]]}
                onTriggerOpening={() => handleCollapse(facet[0], true)}
                onTriggerClosing={() => handleCollapse(facet[0], false)}
                key={facet[0]}
                openedClassName="is-open"
                trigger={
                  <div
                    role="list"
                    tabIndex="0"
                    aria-label={`${
                      collapse[facet[0]] ? texts.expanded : texts.collapsed
                    } ${texts.filterTitle(facetName, facetCount)})}`}
                    className="gbh-data-layer"
                  >
                    <span aria-hidden="true">{formatFacetName(facet[0])}</span>{" "}
                    <section className="plus">
                      <div className="line-1 line"></div>
                      <div className="line line-2"></div>
                    </section>
                  </div>
                }
                transitionTime={400}
                easing="ease-in-out"
              >
                {facet[1].map(filter => (
                  <Checkbox
                    key={filter.label}
                    id={facet[0] + filter.label}
                    value={filter.label}
                    onChange={e =>
                      handleFilter(e.target.checked, {
                        facet: formatFacetName(facet[0]),
                        value: filter.label,
                        query: filter.query,
                      })
                    }
                    checked={isSelected({
                      facet: formatFacetName(facet[0]),
                      display: filter.label,
                      query: filter.query,
                    })}
                    tabIndex={collapse[facet[0]] ? 0 : -1}
                    checkBoxAria={filter.label}
                  />
                ))}
              </Collapsible>
            )
          })}
        </div>
      </div>
      <div className="filter__footer">
        <button
          className={cx(
            "filter__apply",
            appliedFilters.length > 0 && "filter__apply--active"
          )}
          disabled={appliedFilters.length < 1}
          onClick={() => close(false)}
        >
          {selectedFilterCount > 0 && appliedFilters.length > 0
            ? `${texts.view} ${texts.results} (${selectedFilterCount})`
            : texts.viewResults}
        </button>
      </div>
    </div>
  )
}

export default Filter
