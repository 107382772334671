import {
  getCountryList,
  getGenericListPath,
  getplaceOfPurchaseList,
  getstateList,
  mapStateToCountry,
} from "@/store/features/genericSlice"
import { isShowAnalyticsEventLoaded, validateField } from "@/utils/helper"
import { store } from "@/store"

// Analytics *********************

const editButtonAnalyticsEventInfo = type => {
  return JSON.stringify({
    eventAction: `my account:register offline kohler products:${type}:edit`,
    eventName: `my account:register offline kohler products:${type}:edit`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "edit",
    internalLinkPosition: "register products",
    internalLinkType: "my account:navigation",
    internalLinkZoneName: `my account:register offline kohler products:${type}`,
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  })
}

const getBackToProductsAnalytics = (isSuccess, myProductsPath) => {
  const pageName = isSuccess
    ? "thank you for registering the products"
    : "top navigation"
  return JSON.stringify({
    clickInternalLinks: "true",
    eventAction: `my account:register offline kohler products:${pageName}:back to my products`,
    eventName: `my account:register offline kohler products:${pageName}:back to my products`,
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "back to my products",
    eventType: "navigation",
    internalLinkPosition: "register offline kohler products",
    internalLinkType: "my account:navigation",
    internalLinkURL:
      (myProductsPath?.indexOf("http") === -1 ? window.location.origin : "") +
      myProductsPath,
    internalLinkZoneName: `my account:register offline kohler products:${pageName}`,
  })
}

const getProductConfirmationEventInfo = linkUrl => {
  return JSON.stringify({
    clickInternalLinks: "true",
    eventAction:
      "my account:register offline kohler products:product registration summary:review products",
    eventName:
      "my account:register offline kohler products:product registration summary:review products",
    eventMsg: "n/a",
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: "review products",
    internalLinkPosition: "register products",
    internalLinkType: "my account:navigation",
    internalLinkURL:
      (linkUrl.indexOf("http") === -1 ? window.location.origin : "") + linkUrl,
    internalLinkZoneName:
      "my account:register offline kohler products:product registration summary",
  })
}

const getOfflineReviewProductsAnalytics = myProductsPath => {
  return JSON.stringify({
    clickInternalLinks: "true",
    eventAction:
      "my account:register offline kohler products:thank you for registering the products:review products",
    eventMsg: "n/a",
    eventName:
      "my account:register offline kohler products:thank you for registering the products:review products",
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: "review products",
    internalLinkPosition: "register offline kohler products",
    internalLinkType: "my account:navigation",
    internalLinkURL:
      (myProductsPath?.indexOf("http") === -1 ? window.location.origin : "") +
      myProductsPath,
    internalLinkZoneName:
      "my account:register offline kohler products:thank you for registering the products",
  })
}

// step product information **********
const addProductInformationAnalytics = (
  action,
  productData,
  status,
  errorsList
) => {
  if (status && status !== "success") {
    errorsList = errorsList.join(":")?.toLowerCase() || "n/a"
  }

  const eventInfo = {
    eventAction:
      "my account:register offline kohler products:product information:next step",
    eventName:
      "my account:register offline kohler products:product information:next step",
    eventType: "navigation",
    eventMsg: errorsList || "n/a",
    eventStatus: status,
    internalLinkName: "next step",
    internalLinkPosition: "register products",
    internalLinkType: "my account:navigation",
    internalLinkZoneName:
      "my account:register offline kohler products:product information",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  const ProductInfo = []
  productData?.map(data => {
    const obj = {
      customerImage: data.customerImage || "n/a",
      modelNumber: data.modelNumber.value || "n/a",
      placeOfPurchase: data.placeOfPurchase.value || "n/a",
      serialNumber: data.serialNumber.value || "n/a",
      uploadedImage: data.uploadedImage || "n/a",
    }
    ProductInfo.push(obj)
  })

  const { adobeDataLayer: { getState } = {} } = window
  const page =
    action === "cmp:show"
      ? window.pageObj
      : (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event: action,
    page,
    eventInfo,
    productInfo: ProductInfo,
  })
  // delete window.pageObj
}
// product form
const getRemoveOfflineProductsAnalytics = () => {
  return JSON.stringify({
    clickInternalLinks: "true",
    eventAction:
      "my account:register offline kohler products:product information:remove",
    eventName:
      "my account:register offline kohler products:product information:remove",
    eventType: "informative",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "remove",
    internalLinkPosition: "register offline kohler products",
    internalLinkType: "my account:navigation",
    internalLinkZoneName:
      "my account:register offline kohler products:product information",
    internalLinkURL: "n/a",
  })
}

const modalNumberSupportLinkEventInfo = JSON.stringify({
  eventAction:
    "my account:register offline kohler products:product information:how to find your model number",
  eventName:
    "my account:register offline kohler products:product information:how to find your model number",
  eventType: "navigation",
  eventMsg: "n/a",
  eventStatus: "n/a",
  internalLinkName: "how to find your model number",
  internalLinkPosition: "register products",
  internalLinkType: "my account:navigation",
  internalLinkZoneName:
    "my account:register offline kohler products:product information",
  internalLinkURL: "n/a",
  clickInternalLinks: "true",
})

const getAddAnotherProductAnalytics = () => {
  return JSON.stringify({
    clickInternalLinks: "true",
    eventAction:
      "my account:register offline kohler products:product information:add another product to register",
    eventName:
      "my account:register offline kohler products:product information:add another product to register",
    eventType: "informative",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "add another product to register",
    internalLinkPosition: "register offline kohler products",
    internalLinkType: "my account:navigation",
    internalLinkURL: "n/a",
    internalLinkZoneName:
      "my account:register offline kohler products:product information",
  })
}

// step registrationInformation***
const addRegistrationInformationAnalytics = (
  productDetails,
  action,
  status,
  errorsList
) => {
  if (status && status !== "success") {
    errorsList = errorsList.join("|")?.toLowerCase() || "n/a"
  }
  const eventInfo = {
    eventAction:
      "my account:register offline kohler products:personal information:next step",
    eventName:
      "my account:register offline kohler products:personal information:next step",
    eventType: "navigation",
    eventMsg: errorsList || "n/a",
    eventStatus: status,
    internalLinkName: "next step",
    internalLinkPosition: "register products",
    internalLinkType: "my account:navigation",
    internalLinkZoneName:
      "my account:register offline kohler products:personal information",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }

  const ProductInfo = []
  productDetails?.map(data => {
    const obj = {
      customerImage: data.customerImage || "n/a",
      modelNumber: data.modelNumber.value || "n/a",
      placeOfPurchase: data.placeOfPurchase.value || "n/a",
      serialNumber: data.serialNumber.value || "n/a",
      uploadedImage: data.uploadedImage || "n/a",
    }
    ProductInfo.push(obj)
  })

  const { adobeDataLayer: { getState } = {} } = window
  const page =
    action === "cmp:show"
      ? window.pageObj
      : (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event: action,
    page,
    eventInfo,
    productInfo: ProductInfo,
  })
  // delete window.pageObj
}

// step reviewInformation
const getReviewRegistrationEventInfo = (type, linkUrl) => {
  return JSON.stringify(addReviewRegistrationEventInfo(type, linkUrl))
}

const addReviewRegistrationEventInfo = (
  type,
  linkUrl,
  clickInternalLinks = "true"
) => {
  const linkFor = type?.toLowerCase()
  return {
    eventAction: `my account:register offline kohler products:review and register:${linkFor}`,
    eventName: `my account:register offline kohler products:review and register:${linkFor}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: linkFor,
    internalLinkPosition: "register products",
    internalLinkType: "my account:navigation",
    internalLinkZoneName: `my account:register offline kohler products:review and register`,
    internalLinkURL: linkUrl,
    clickInternalLinks,
  }
}

const addReviewRegistrationAnalytics = (
  action,
  status,
  productData,
  message
) => {
  const eventInfo = {
    eventAction:
      "my account:register offline kohler products:review and register:submit registration",
    eventName:
      "my account:register offline kohler products:review and register:submit registration",
    eventType: "navigation",
    eventMsg: message || "n/a",
    eventStatus: status || "n/a",
    internalLinkName: "submit registration",
    internalLinkPosition: "register products",
    internalLinkType: "my account:navigation",
    internalLinkZoneName:
      "my account:register offline kohler products:review and register",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }

  const ProductInfo = []
  productData?.map(data => {
    const obj = {
      customerImage: data.customerImage || "n/a",
      modelNumber: data.modelNumber.value || "n/a",
      placeOfPurchase: data.placeOfPurchase.value || "n/a",
      serialNumber: data.serialNumber.value || "n/a",
      uploadedImage: data.uploadedImage || "n/a",
    }
    ProductInfo.push(obj)
  })

  const { adobeDataLayer: { getState } = {} } = window
  const page =
    action === "cmp:show"
      ? window.pageObj
      : (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event: action,
    page,
    eventInfo,
    productInfo: ProductInfo,
  })
  // delete window.pageObj
}

const addReviewRegistrationSubmitDataLayer = productData => {
  const eventInfo = {
    eventAction:
      "my account:register offline kohler products:product information:submit registration",
    eventName:
      "my account:register offline kohler products:review and register:submit registration",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "success",
    internalLinkName: "submit registration",
    internalLinkPosition: "register products",
    internalLinkType: "my account:navigation",
    internalLinkZoneName:
      "my account:register offline kohler products:product information",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }

  const productInfoData = productData?.map(data => {
    return {
      customerImage: data.customerImage || "n/a",
      modelNumber: data.modelNumber.value || "n/a",
      placeOfPurchase: data.placeOfPurchase.value || "n/a",
      serialNumber: data.serialNumber.value || "n/a",
      uploadedImage: data.uploadedImage || "n/a",
    }
  })

  const { adobeDataLayer: { getState } = {} } = window
  const page = getState && getState("page")
  window.adobeDataLayer.push({
    event: "cmp:click",
    page,
    eventInfo,
    productInfo: productInfoData,
  })
}

// END--Analytics *********************

// step product information **********
const productInfoHandleSubmit = (data, staticText) => {
  let valid = true
  const newData = []
  const errorMessage = []
  data.map(formItem => {
    const obj = {}
    if (!formItem.modelNumber.value) {
      obj.modelNumber = {
        ...formItem.modelNumber,
        error: true,
        message: staticText.modelNumberError,
      }
      valid = false
      errorMessage.push(staticText.modelNumberError)
    } else if (!validateField("modelNumber", formItem.modelNumber.value)) {
      obj.modelNumber = {
        ...formItem.modelNumber,
        error: true,
        message: staticText.modelNumberError,
      }
      valid = false
      errorMessage.push(staticText.modelNumberError)
    } else if (formItem.modelNumber.value.length < 3) {
      obj.modelNumber = {
        ...formItem.modelNumber,
        error: true,
        message: staticText.modelNumberError,
      }
      valid = false
      errorMessage.push(staticText.modelNumberError)
    }
    if (!formItem.placeOfPurchase.value) {
      obj.placeOfPurchase = {
        ...formItem.placeOfPurchase,
        error: true,
        message: staticText.placeOfPurchaseError,
      }
      valid = false
      errorMessage.push(staticText.placeOfPurchaseError)
    }
    newData.push({ ...formItem, ...obj })
  })
  return { newData: newData, valid: valid, errorMessage: errorMessage }
}

const getLocations = async () => {
  await store.dispatch(getGenericListPath())
  const { payload: countryList } = await store.dispatch(getCountryList())
  const { payload: stateList } = await store.dispatch(getstateList())
  await store.dispatch(getplaceOfPurchaseList())
  if (countryList.length)
    store.dispatch(mapStateToCountry({ countryList, stateList }))
}

// register-offline-products
const getProductDetails = (productDetail = []) => {
  const productDetails = []
  if (productDetail && productDetail.length > 0) {
    productDetail.forEach(item => {
      const productList = {}
      productList.modelNumber = item.modelNumber?.value.startsWith("K-")
        ? item.modelNumber?.value.slice(2)
        : item.modelNumber?.value
      productList.serialNumber = item.serialNumber?.value
      productList.placeOfPurchaseType = item.placeOfPurchase?.value
      productList.customerImage = item.customerImage

      productDetails.push(productList)
    })
  }
  return productDetails
}

const submitRegistrationGenericError = (productDetails, errorMsg) => {
  const isShowEvent = isShowAnalyticsEventLoaded()
  if (!isShowEvent) {
    addReviewRegistrationAnalytics(
      "cmp:click",
      "failure",
      productDetails,
      errorMsg
    )
  }
}

export {
  getBackToProductsAnalytics,
  getProductConfirmationEventInfo,
  getOfflineReviewProductsAnalytics,
  addProductInformationAnalytics,
  productInfoHandleSubmit,
  getRemoveOfflineProductsAnalytics,
  modalNumberSupportLinkEventInfo,
  getAddAnotherProductAnalytics,
  getLocations,
  addRegistrationInformationAnalytics,
  editButtonAnalyticsEventInfo,
  getReviewRegistrationEventInfo,
  addReviewRegistrationSubmitDataLayer,
  getProductDetails,
  addReviewRegistrationAnalytics,
  submitRegistrationGenericError,
  addReviewRegistrationEventInfo,
}
