import React, { useState, useEffect, useRef } from "react"
import Button from "@/components/core/Button/Button"
import Checkbox from "@/components/core/Checkbox/Checkbox"
import Input from "@/components/core/Input/Input"
import ReCaptcha from "@/components/core/ReCaptcha/ReCaptcha"
import { sanitizeInnerHtml } from "@/utils/helper"
import { getEventInfo } from "@/components/AssociateMembership/v1/AssociateMemberShipAnalytics"
import {
  MEMBERSHIP_CODE_ERROR_REGEX,
  MEMBERSHIP_VALID_MAIL_ERROR_REGEX,
} from "@/constants/regex"

import styles from "@/components/AssociateMembership/v1/index.module.scss"

const AssociateMembershipView = ({
  i18n = {},
  verifyEmail = () => {},
  isRetired = false,
  changeState = () => {},
  showModal = false,
  loading = false,
  legalPages = {},
  verifyClockId = () => {},
}) => {
  const [code, setCode] = useState("")
  const [codeError, setCodeError] = useState({ show: false, message: "" })
  const [policy1Error, setPolicy1Error] = useState(false)
  const [policy1, setPolicy1] = useState(false)
  const clearError = () => {
    setCodeError({ show: false, message: "" })
  }
  const {
    policy1Error: i18nPolicy1Error,
    koError,
    mailError,
    validMailError,
    codeErrorKO,
    codeError: i18nCodeError,
    resendCodeKOError,
    resendCodeEmailError,
    name: i18nName,
    description,
    requiredFields,
    codeVerification,
    code: i18nCode,
    noKohlerEmail,
    termsAndConditions,
    privacyPolicy,
    subjectAccessRequest,
    termsOfService,
    submit,
  } = i18n

  const {
    privacyPolicy: legalPagesPrivacyPolicy,
    subjectAccessRequest: legalPagesSubjectAccessRequest,
    termsOfService: legalPagesTermsOfService,
  } = legalPages

  const inputCodeText = useRef(null)
  useEffect(() => {
    if (!showModal) {
      setCode("")
      setPolicy1(false)
      setPolicy1Error(false)
      setCodeError(false)
    }
  }, [showModal])

  const handleTermsPolicy = e => {
    setPolicy1(e.target.checked)
    if (e.target.checked) {
      setPolicy1Error(false)
    }
  }

  const handleSubmit = async () => {
    clearError()
    let valid = true
    const errorList = []

    const setPolicy1ErrorAndPush = message => {
      setPolicy1Error({ show: true, message })
      valid = false
      errorList.push(message)
    }

    const setCodeErrorAndPush = message => {
      setCodeError({ show: true, message })
      inputCodeText.current.focus()
      valid = false
      errorList.push(message)
    }

    if (!policy1) {
      setPolicy1ErrorAndPush(i18nPolicy1Error)
    } else if (!code) {
      setCodeErrorAndPush(isRetired ? koError : mailError)
    } else if (!isRetired && !MEMBERSHIP_VALID_MAIL_ERROR_REGEX.test(code)) {
      setCodeErrorAndPush(validMailError)
    } else if (!isRetired && !MEMBERSHIP_CODE_ERROR_REGEX.test(code)) {
      setCodeErrorAndPush(isRetired ? codeErrorKO : i18nCodeError)
    } else if (isRetired && code) {
      valid = "KO"
    }
    if (valid == "KO") {
      verifyClockId(
        code,
        () => setCodeErrorAndPush(resendCodeKOError),
        addSubmitAnalytics
      )
    } else if (valid) {
      verifyEmail(
        code,
        () => setCodeErrorAndPush(resendCodeEmailError),
        addSubmitAnalytics
      )
    } else {
      addSubmitAnalytics("failure", errorList.join("|"), isRetired)
    }
  }

  const handleInputChange = () => {
    clearError()
    changeState()
    const { adobeDataLayer: { getState } = {} } = window
    const page = getState?.("page") || {}
    const eventInfo = getEventInfo(
      isRetired,
      "don't have a kohler email?",
      null,
      true
    )
    window.adobeDataLayer.push({
      event: "cmp:click",
      eventInfo,
      page,
    })
  }

  const addSubmitAnalytics = (status, message, isKO) => {
    const { adobeDataLayer: { getState } = {} } = window
    const page = getState?.("page") || {}
    const eventInfo = {
      ...getEventInfo(isRetired, "submit", null, true),
      eventStatus: status,
      eventMsg: message ? message.toLowerCase() : "n/a",
      termsAndConditions: policy1,
    }

    if (isKO) {
      // user entered KO id
      eventInfo.groupCode = code
    }

    window.adobeDataLayer.push({
      event: "cmp:click",
      eventInfo,
      page,
    })
  }

  const getRecaptchaAnalytics = {
    pageTitle: `insider-program-associate-discount:${
      isRetired ? "ko" : "email"
    }`,
    internalLinkPosition: "insider-program-associate-discount:form",
    internalLinkType: "insider-program-associate-discount",
    internalLinkTypeWithType: "insider-program-associate-discount",
  }

  return (
    <div className={`associate-membership ${styles.membershipStyleWrapper}`}>
      <div className="associate-membership__header">
        <div
          className="associate-membership__title"
          id="associate-membership__title"
        >
          {i18nName}
        </div>
      </div>
      <div
        className="associate-membership__description"
        id="associate-membership__description"
      >
        {description}
      </div>
      <div className="associate-membership__description1">{requiredFields}</div>
      <Input
        ref={inputCodeText}
        id="asm-code"
        type="text"
        value={code}
        maxLength={40}
        placeholder={isRetired ? `${codeVerification}*` : `${i18nCode}*`}
        label={isRetired ? `${codeVerification}*` : `${i18nCode}*`}
        onChange={e => setCode(e.target.value)}
        showError={codeError.show}
        errorMessage={codeError.message}
        autoFocus={true}
        ariaRequried={true}
      />
      <div className="associate-membership__retired">
        {!isRetired && (
          <a href="#" onClick={handleInputChange}>
            {noKohlerEmail}
          </a>
        )}
      </div>
      <div className="associate-membership__terms">
        <Checkbox checked={policy1} onChange={e => handleTermsPolicy(e)} />
        <div>{sanitizeInnerHtml(termsAndConditions)}</div>
      </div>
      {policy1Error ? (
        <div className="associate-membership__policy-term-error">
          {i18nPolicy1Error}
        </div>
      ) : null}
      <div className="associate-membership__button">
        <Button
          role="button"
          type="black"
          flexible
          loading={loading}
          label={submit}
          onClick={handleSubmit}
        />
      </div>
      <ReCaptcha getAnalyticsData={getRecaptchaAnalytics} />
      <div className="associate-membership__policies">
        <a
          tabIndex="0"
          className="gbh-data-layer"
          data-gbh-data-layer={getEventInfo(
            isRetired,
            "privacy policy",
            legalPagesPrivacyPolicy
          )}
          href={legalPagesPrivacyPolicy}
        >
          {privacyPolicy}
        </a>
        <a
          tabIndex="0"
          className="gbh-data-layer"
          data-gbh-data-layer={getEventInfo(
            isRetired,
            "subject access request",
            legalPagesSubjectAccessRequest
          )}
          href={legalPagesSubjectAccessRequest}
        >
          {subjectAccessRequest}
        </a>
        <a
          tabIndex="0"
          className="gbh-data-layer"
          data-gbh-data-layer={getEventInfo(
            isRetired,
            "terms of service",
            legalPagesTermsOfService
          )}
          href={legalPagesTermsOfService}
        >
          {termsOfService}
        </a>
      </div>
    </div>
  )
}

export default AssociateMembershipView
