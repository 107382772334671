import filterIcon from "@/public/icons/filter.svg"
import carret from "@/public/icons/arrow-up.svg"
import searchIcon from "@/public/icons/search.svg"

const TechnicalSpecPLPLoader = props => {
  const { showNavHeader = true, isProductTile = false } = props

  const renderProductListingTile = () => {
    return (
      <div className="technical product-listing__tile-col">
        <div className="product-listing__row row-cols-1">
          <div className="product-listing__tile">
            <div className="LazyLoad is-visible kf-lazyload-wrapper">
              <div className="TechnicalTile_technicalTileWrapper__h1BM8">
                <div className="technical-tile technical-tile__grid-showfilter wow animate__fadeInUp">
                  <div className="technical-tile__image position-relative">
                    <a className="d-block technical-tile__image--product-image gbh-data-layer"></a>
                  </div>
                  <div className="technical-tile__collapsible technical-tile__collapsible-grid">
                    <div className="technical-tile__specs-container">
                      <div className="width-full">
                        <p className="shimmer-loading__para shimmer width-170 mt60"></p>
                        <p className="shimmer-loading__headpara shimmer  mb20 width-full"></p>
                        <p className="shimmer-loading__para shimmer width-100"></p>
                      </div>
                    </div>
                    <div>
                      <div className="Accordion_accordionWrapper__vZrfq">
                        <div className="collapsible-accordion">
                          <div className="Collapsible-facet">
                            <span
                              className="Collapsible__trigger is-closed"
                              aria-expanded="false"
                              aria-disabled="false"
                              aria-controls="collapsible-content-6674974705000"
                              role="button"
                            >
                              <div className="width-full">
                                <p className="shimmer-loading__para shimmer width-170 mt60"></p>
                                <p className="shimmer-loading__headpara shimmer  mb20 width-full"></p>
                                <p className="shimmer-loading__para shimmer width-100"></p>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="product-listing__tile">
            <div className="LazyLoad is-visible kf-lazyload-wrapper">
              <div className="TechnicalTile_technicalTileWrapper__h1BM8">
                <div className="technical-tile technical-tile__grid-showfilter wow animate__fadeInUp">
                  <div className="technical-tile__image position-relative">
                    <a className="d-block technical-tile__image--product-image gbh-data-layer"></a>
                  </div>
                  <div className="technical-tile__collapsible technical-tile__collapsible-grid">
                    <div className="technical-tile__specs-container">
                      <div className="width-full">
                        <p className="shimmer-loading__para shimmer width-170 mt60"></p>
                        <p className="shimmer-loading__headpara shimmer  mb20 width-full"></p>
                        <p className="shimmer-loading__para shimmer width-100"></p>
                      </div>
                    </div>
                    <div>
                      <div className="Accordion_accordionWrapper__vZrfq">
                        <div className="collapsible-accordion">
                          <div className="Collapsible-facet">
                            <span
                              className="Collapsible__trigger is-closed"
                              aria-expanded="false"
                              aria-disabled="false"
                              aria-controls="collapsible-content-6674974705000"
                              role="button"
                            >
                              <div className="width-full">
                                <p className="shimmer-loading__para shimmer width-170 mt60"></p>
                                <p className="shimmer-loading__headpara shimmer  mb20 width-full"></p>
                                <p className="shimmer-loading__para shimmer width-100"></p>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return isProductTile ? (
    renderProductListingTile()
  ) : (
    <>
      {showNavHeader ? (
        <div
          className="product-listing__sticky-nav-wrap"
          style={{ minHeight: "130px" }}
        >
          <div className=" ">
            <div className="container-fluid product-listing__sticky-nav false">
              <div className="product-listing__row technical-specs__search-flex">
                <div className="container kf-react-plp-container">
                  <div className="product-listing__row">
                    <div className="technical-specs__sticky-search-pd false product-listing__col product-listing__controls">
                      <div className="product-listing__row technical-specs__search-wrap-parent">
                        <h2 className="product-listing__total shimmer-loading__para30 shimmer width-240"></h2>
                        <div className="technical-specs__search-wrap sticky-order-search">
                          <div className={`technical-specs__search`}>
                            <div
                              className="search-dropdown"
                              id="kf-tech-specs-dropdown"
                            >
                              <div className="search-dropdown__field">
                                <span>
                                  <img
                                    role="presentation"
                                    className="search-dropdown__search-icon"
                                    src={searchIcon?.src}
                                    alt="search-icon"
                                  />
                                </span>
                                <input
                                  id="tech-specs-search"
                                  type="text"
                                  placeholder="Search Technical Specs"
                                  className="search-dropdown__input "
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <section className="sorting-section">
                        <button
                          id="showFilter"
                          className="product-listing__filter-toggle gbh-data-layer"
                        >
                          <ul className="product-listing__filter-toggle-text">
                            <li className="show">Hide Filters</li>
                            <li className="hide">Show Filters</li>
                          </ul>
                          <img
                            src={filterIcon?.src}
                            className="product-listing__filter-toggle-icon"
                            alt="Toggle Filter"
                          />
                        </button>
                        <div className="sort">
                          <button className="sort__toggle">
                            Sort By : Featured
                            <img
                              src={carret?.src}
                              alt="Sort"
                              className="sort__toggle-icon"
                              aria-hidden="true"
                            />
                          </button>
                          <ul className="sort__drop-down" aria-hidden="true">
                            <li
                              id="select-options-0"
                              role="option"
                              ndex="-1"
                              aria-selected="true"
                              className="sort__item gbh-data-layer active"
                            >
                              Featured
                            </li>
                            <li
                              id="select-options-1"
                              role="option"
                              ndex="-1"
                              aria-selected="false"
                              className="sort__item gbh-data-layer "
                            >
                              A - Z
                            </li>
                            <li
                              id="select-options-2"
                              role="option"
                              ndex="-1"
                              aria-selected="false"
                              className="sort__item gbh-data-layer "
                            >
                              Z - A
                            </li>
                          </ul>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="container kf-react-plp-container product-listing__tiles">
        <div className="product-listing__row d-block">
          <div className="filter-col">
            <div
              className="product-listing__filter product-listing__filter--modal"
              aria-modal="true"
            >
              <div
                className="product-listing__filter--sticky"
                aria-hidden="false"
              >
                <div className="product-listing filter">
                  <div className="filter__inner-container">
                    <div className="product-listing__controls filter__mobile-header">
                      <div className="product-listing__filter-toggle gbh-data-layer">
                        <span>Filter &amp; Sort</span>
                      </div>
                      <img
                        ndex="0"
                        role="button"
                        src="/_next/static/media/close-filter.155c83ba.svg"
                        className="product-listing__close-icon gbh-data-layer"
                        alt="Close Filter"
                      />
                    </div>
                    <div role="list" className="filter__filter-list">
                      <div className="collapsible-outer" role="listitem">
                        <div className="Collapsible-facet">
                          <span
                            className="Collapsible__trigger is-closed shimmer-loading__headpara shimmer mb20 width-full "
                            ndex="0"
                            aria-expanded="false"
                            aria-disabled="false"
                            aria-controls="collapsible-content-1690168099620"
                            role="button"
                          ></span>
                          <div
                            className="Collapsible__contentOuter"
                            role="region"
                          >
                            <div className="Collapsible__contentInner"></div>
                          </div>
                        </div>
                      </div>
                      <div className="collapsible-outer" role="listitem">
                        <div className="Collapsible-facet">
                          <span
                            className="Collapsible__trigger is-closed shimmer-loading__headpara shimmer mb20 width-full"
                            ndex="0"
                            aria-expanded="false"
                            aria-disabled="false"
                            aria-controls="collapsible-content-1690168099621"
                            role="button"
                          ></span>
                          <div
                            className="Collapsible__contentOuter"
                            role="region"
                          >
                            <div className="Collapsible__contentInner"></div>
                          </div>
                        </div>
                      </div>
                      <div className="collapsible-outer" role="listitem">
                        <div className="Collapsible-facet">
                          <span
                            className="Collapsible__trigger is-closed shimmer-loading__headpara shimmer mb20 width-full"
                            ndex="0"
                            aria-expanded="false"
                            aria-disabled="false"
                            aria-controls="collapsible-content-1690168099622"
                            role="button"
                          ></span>
                          <div
                            className="Collapsible__contentOuter"
                            role="region"
                          >
                            <div className="Collapsible__contentInner"></div>
                          </div>
                        </div>
                      </div>
                      <div className="collapsible-outer" role="listitem">
                        <div className="Collapsible-facet">
                          <span
                            className="Collapsible__trigger is-closed shimmer-loading__headpara shimmer mb20 width-full"
                            ndex="0"
                            aria-expanded="false"
                            aria-disabled="false"
                            aria-controls="collapsible-content-1690168099622"
                            role="button"
                          ></span>
                          <div
                            className="Collapsible__contentOuter"
                            role="region"
                          >
                            <div className="Collapsible__contentInner"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {renderProductListingTile()}
          </div>
        </div>
      </div>
    </>
  )
}

export default TechnicalSpecPLPLoader
