import { useTranslation } from "next-i18next"
import { sanitizeText } from "@/utils/helper"

const useAssociateMembershipi18n = ({
  authorData: {
    formSubhead,
    formRequired,
    insiderTermsAndConditions,
    formHeadline,
    confirmRequired,
    formKohlerCo,
    confirmSubhead,
    resendCodeCta,
    confirmHeadline,
    formSuccessMessage,
    confirmSuccessMessage,
    emailError,
    koError,
    formCta,
    confirmCta,
    formMemberLink,
    successMessage,
    genericError,
  } = {},
}) => {
  const { t } = useTranslation()

  const getDescription = () => {
    return formSubhead
      ? sanitizeText(formSubhead)
      : t("kf.associatemembership.label.description")
  }

  const getRequiredFields = () => {
    return formRequired
      ? sanitizeText(formRequired)
      : t("kf.associatemembership.label.requiredFields")
  }

  const getTermsAndConditions = () => {
    return (
      insiderTermsAndConditions ||
      t("kf.associatemembership.label.termsAndConditions")
    )
  }

  const getTermsAndConditions1 = () => {
    return t("kf.associatemembership.label.termsAndConditions1")
  }

  const getPrivacyPolicy = () => {
    return t("kf.associatemembership.label.privacyPolicy")
  }

  const getSubjectAccessRequest = () => {
    return t("kf.associatemembership.label.subjectAccessRequest")
  }

  const getTermsOfService = () => {
    return t("kf.associatemembership.label.termsOfService")
  }

  const getCodeInvalidError = () => {
    return t("kf.associatemembership.label.codeInvalidError")
  }

  const getCode = () => {
    return t("kf.associatemembership.label.code")
  }

  const getCodeError = () => {
    return t("kf.associatemembership.label.codeError")
  }

  const getName = () => {
    return formHeadline || t("kf.associatemembership.label.name")
  }

  const getCodeVerification = () => {
    return formKohlerCo || t("kf.associatemembership.label.codeVerification")
  }

  const getCodeErrorKO = () => {
    return t("kf.associatemembership.label.codeErrorKO")
  }

  const getDescriptionConfirm = () => {
    return confirmSubhead
      ? sanitizeText(confirmSubhead)
      : t("kf.associatemembership.label.descriptionConfirm")
  }

  const getCodeConfirm = () => {
    return t("kf.associatemembership.label.codeConfirm")
  }

  const getResendCode = () => {
    return resendCodeCta || t("kf.associatemembership.label.resendCode")
  }

  const getConfirmCodeError = () => {
    return t("kf.associatemembership.label.confirmCodeError")
  }

  const getNameConfirm = () => {
    return confirmHeadline || t("kf.associatemembership.label.nameConfirm")
  }

  const getSuccessMessage = () => {
    return formSuccessMessage
      ? sanitizeText(formSuccessMessage)
      : t("kf.associatemembership.label.successMessage")
  }

  const getConfirmationSuccessMessage = () => {
    return confirmSuccessMessage
      ? sanitizeText(confirmSuccessMessage)
      : t("kf.associatemembership.label.confirmSuccessMessage")
  }

  const getMailError = () => {
    return t("kf.associatemembership.label.mailError")
  }

  const getKoError = () => {
    return t("kf.associatemembership.label.koError")
  }

  const getErrorCode = () => {
    return t("kf.associatemembership.label.errorCode")
  }

  const getPolicy1Error = () => {
    return t("kf.keyspecifier.label.policy1Error")
  }

  const getKohlerMailError = () => {
    return t("kf.associatemembership.label.kohlerMailError")
  }

  const getValidMailError = () => {
    return t("kf.associatemembership.label.validMailError")
  }

  const getResendCodeEmailError = () => {
    return emailError || t("kf.associatemembership.label.resendEmailCodeError")
  }

  const getResendCodeKOError = () => {
    return koError || t("kf.associatemembership.label.resendKOCodeError")
  }

  const getProtectedByGoogle = () => {
    return t("kf.contactus.message.protectedByGoogle")
  }

  const getSubmit = () => {
    return formCta || t("kf.associatemembership.label.submit")
  }

  const getConfirmBtn = () => {
    return confirmCta || t("kf.associatemembership.label.confirmBtn")
  }

  const getTermsAndConditionLink = () => {
    return formMemberLink || "/"
  }

  const getConfirmFieldsRequired = () => {
    return confirmRequired
      ? sanitizeText(confirmRequired)
      : t("kf.associatemembership.label.confirmFieldsRequired")
  }

  const getNoKohlerEmail = () => {
    return t("kf.associatemembership.label.noKohlerEmail")
  }

  const getSuccessMessage2 = () => {
    return successMessage ?? t("kf.associatemembership.label.successMessage")
  }

  const getGenericError = () => {
    return genericError ?? t("kf.error.message.genericError")
  }

  const getKoError2 = () => {
    return koError || t("kf.associatemembership.label.resendKOCodeError")
  }

  const getEmailError = () => {
    return emailError || t("kf.associatemembership.label.resendEmailCodeError")
  }

  const i18n = {
    description: getDescription(),
    requiredFields: getRequiredFields(),
    termsAndConditions: getTermsAndConditions(),
    termsAndConditions1: getTermsAndConditions1(),
    privacyPolicy: getPrivacyPolicy(),
    subjectAccessRequest: getSubjectAccessRequest(),
    termsOfService: getTermsOfService(),
    codeInvalidError: getCodeInvalidError(),
    code: getCode(),
    codeError: getCodeError(),
    name: getName(),
    codeVerification: getCodeVerification(),
    codeErrorKO: getCodeErrorKO(),
    descriptionConfirm: getDescriptionConfirm(),
    codeConfirm: getCodeConfirm(),
    resendCode: getResendCode(),
    confirmCodeError: getConfirmCodeError(),
    nameConfirm: getNameConfirm(),
    successMessage: getSuccessMessage(),
    confirmationSuccessMessage: getConfirmationSuccessMessage(),
    mailError: getMailError(),
    koError: getKoError(),
    errorCode: getErrorCode(),
    policy1Error: getPolicy1Error(),
    kohlerMailError: getKohlerMailError(),
    validMailError: getValidMailError(),
    resendCodeEmailError: getResendCodeEmailError(),
    resendCodeKOError: getResendCodeKOError(),
    protectedByGoogle: getProtectedByGoogle(),
    submit: getSubmit(),
    confirmBtn: getConfirmBtn(),
    termsAndConditionLink: getTermsAndConditionLink(),
    confirmFieldsRequired: getConfirmFieldsRequired(),
    noKohlerEmail: getNoKohlerEmail(),
    successMessage2: getSuccessMessage2(),
    genericError: getGenericError(),
    koError2: getKoError2(),
    emailError: getEmailError(),
  }

  return i18n
}

export default useAssociateMembershipi18n
