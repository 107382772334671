export const searchdropdowncloseAnalytics =
  '{"eventAction":"technical specs:hero:search technical specs close","eventName":"technical specs:hero:search technical specs close","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"close","internalLinkPosition":"tech specs","internalLinkType":"technical specs:search technical specs","internalLinkZoneName":"technical specs:hero","internalLinkURL":"n/a","clickInternalLinks":"true"}'

export const showFIlterGetEventInfo = showFilter => {
  const hideOrShow = showFilter ? "hide" : "show"
  return JSON.stringify({
    eventAction: `technical specs:filters:${hideOrShow} filters`,
    eventName: `technical specs:filters:${hideOrShow} filters`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${hideOrShow} filters`,
    internalLinkPosition: "tech specs",
    internalLinkType: `technical specs:${hideOrShow}`,
    internalLinkZoneName: "technical specs:filters",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  })
}

export const addAnalyticsForSearch = (
  searchedKeyword = "",
  totalResults = 0,
  searchKeyword = ""
) => {
  const eventInfo = {
    eventAction: "technical specs:hero:search technical specs",
    eventName: "technical specs:hero:search technical specs",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: searchKeyword?.toLowerCase() || "",
    internalLinkPosition: "technical specs",
    internalLinkType: "technical specs:search",
    internalLinkZoneName: "technical specs:hero",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
    searchCount: sessionStorage.getItem("technicalspecSearch")
      ? Number(sessionStorage.getItem("technicalspecSearch")) + 1
      : 1,
    searchResult: totalResults,
    searchTerm: searchedKeyword,
  }

  sessionStorage.setItem("technicalspecSearch", eventInfo.searchCount)

  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event: "cmp:click",
    page,
    eventInfo,
  })
}
