const entityToCharacter = str => {
  return str?.replace(/&amp;/g, "&")
}

const redirectionToContentPage = () => {
  if (window.location.href.includes("#")) {
    const hashString = window.location.href.split("#")
    if (hashString) {
      const cleanUrl = window.location.href.split("#")[0]
      window.history.replaceState(null, "", cleanUrl)
    }
  }
  window.scrollTo({
    top: 1,
    behavior: "smooth",
  })
}

export { redirectionToContentPage, entityToCharacter }
