import { useTranslation } from "next-i18next"

const useDesignServicei18n = () => {
  const { t } = useTranslation()
  const i18n = {
    zipCode: t("kf.productCard.zipCode"),
    findaPro: t("kf.productCard.findaPro"),
    search: t("kf.productCard.search"),
    zipcodeEmptyError: t("kf.productCard.zipcodeEmptyError"),
    validZipcode: t("kf.productCard.validZipcode"),
    addToCart: t("kf.productCard.addToCart"),
    change: t("kf.productCard.change"),
  }

  return i18n
}

export default useDesignServicei18n
