import { useTranslation } from "next-i18next"
import { sanitizeText } from "@/utils/helper"

const useKeySpecifieri18n = ({
  authorData: {
    keySubhead,
    successTitle,
    successDescription,
    successCtaLabel,
    emailBrand,
    keyTerms,
    keyIdeas,
    keyUpToDate,
    keySupport,
    keyTitleOne,
    keyTitleTwo,
    keyTitleThree,
    keyTitleFour,
    radialButtons,
    keyHeadline,
    keySuccessMessage,
    keyCta,
  } = {},
}) => {
  const { t } = useTranslation()
  const i18n = {
    description: keySubhead
      ? sanitizeText(keySubhead)
      : t("kf.keyspecifier.label.description"),
    successTitle: successTitle
      ? sanitizeText(successTitle)
      : t("kf.keyspecifier.label.successTitle"),
    successMessage: successDescription
      ? sanitizeText(successDescription)
      : t("kf.keyspecifier.label.successMessage"),
    continueShopping: successCtaLabel
      ? sanitizeText(successCtaLabel)
      : t("kf.keyspecifier.label.continueShopping"),
    termsAndConditions:
      keyTerms || t("kf.keyspecifier.label.termsAndConditions"),
    termsAndConditions1: t("kf.keyspecifier.label.termsAndConditions1"),
    getInspring: keyIdeas
      ? sanitizeText(keyIdeas)
      : t("kf.keyspecifier.label.getIdeas"),
    stay: keyUpToDate
      ? sanitizeText(keyUpToDate)
      : t("kf.keyspecifier.label.stay"),
    need: keySupport || t("kf.keyspecifier.label.need"),
    privacyPolicy: t("kf.keyspecifier.label.privacyPolicy"),
    subjectAccessRequest: t("kf.keyspecifier.label.subjectAccessRequest"),
    termsOfService: t("kf.keyspecifier.label.termsOfService"),
    codeInvalidError: t("kf.keyspecifier.label.codeInvalidError"),
    yourInformation: keyTitleOne || t("kf.keyspecifier.label.yourInformation"),
    businessInformation:
      keyTitleTwo || t("kf.keyspecifier.label.businessInformation"),
    businessAddress:
      keyTitleThree || t("kf.keyspecifier.label.businessAddress"),
    keySpecifierGroupCode:
      keyTitleFour || t("kf.keyspecifier.label.keySpecifierGroupCode"),
    firstName: t("kf.keyspecifier.label.firstName"),
    firstNameError: t("kf.keyspecifier.label.firstNameError"),
    lastName: t("kf.keyspecifier.label.lastName"),
    lastNameError: t("kf.keyspecifier.label.lastNameError"),
    emailAddress: t("kf.keyspecifier.label.emailAddress"),
    emailAddressError: t("kf.keyspecifier.label.emailAddressError"),
    businessName: t("kf.keyspecifier.label.businessName"),
    businessNameError: t("kf.keyspecifier.label.businessNameError"),
    jobTitle: t("kf.keyspecifier.label.jobTitle"),
    primaryBusinessType: t("kf.keyspecifier.label.primaryBusinessType"),
    primaryBusinessTypeError: t(
      "kf.keyspecifier.label.primaryBusinessTypeError"
    ),
    typeofDesigner: t("kf.keyspecifier.label.typeofDesigner"),
    typeofDesignerError: t("kf.keyspecifier.label.typeofDesignerError"),
    businessPhoneNumber: t("kf.keyspecifier.label.businessPhoneNumber"),
    businessPhoneNumberError: t(
      "kf.keyspecifier.label.businessPhoneNumberError"
    ),
    streetAddress: t("kf.keyspecifier.label.streetAddress"),
    streetAddressError: t("kf.keyspecifier.label.streetAddressError"),
    aptFloorSuite: t("kf.keyspecifier.label.aptFloorSuite"),
    city: t("kf.keyspecifier.label.city"),
    cityError: t("kf.keyspecifier.label.cityError"),
    state: t("kf.keyspecifier.label.state"),
    stateError: t("kf.keyspecifier.label.stateError"),
    zipCode: t("kf.keyspecifier.label.zipCode"),
    zipCodeError: t("kf.keyspecifier.label.zipCodeError"),
    groupCode: t("kf.keyspecifier.label.groupCode"),
    groupCodeError: t("kf.keyspecifier.label.groupCodeError"),
    installingProductatBusinessAbove:
      radialButtons ||
      t("kf.keyspecifier.label.installingProductatBusinessAbove"),
    name: keyHeadline || t("kf.keyspecifier.label.name"),
    subType: t("kf.keyspecifier.label.subType"),
    policy1Error: t("kf.keyspecifier.label.policy1Error"),
    message: keySuccessMessage
      ? sanitizeText(keySuccessMessage)
      : t("kf.keyspecifier.label.message"),
    sampleProps: t("kf.keyspecifier.label.sampleProps"),
    subTypeSet: t("kf.keyspecifier.label.subTypeSet"),
    primaryBusinessTypeSet: t("kf.keyspecifier.label.primaryBusinessTypeSet"),
    stateSet: t("kf.keyspecifier.label.stateSet"),
    yes: t("kf.keyspecifier.label.yes"),
    no: t("kf.keyspecifier.label.no"),
    submit: keyCta || t("kf.keyspecifier.label.submit"),
    needSupport: t("kf.keyspecifier.label.needSupport"),
    genError: t("kf.error.message.genericError"),
  }

  return i18n
}

export default useKeySpecifieri18n
