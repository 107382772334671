import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import useIsSsr from "@/hooks/useIsSsr"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"

import Modal from "@/components/core/Modal/Modal"
import Button from "@/components/core/Button/Button"

import { validateRecaptcha } from "@/components/core/ReCaptcha/ReCaptcha"
import { apim } from "@/constants/api"
import { getConfig } from "@/constants/config"

import {
  selectGenericState,
  showToast,
  getBusinessList,
  getGenericListPath,
} from "@/store/features/genericSlice"
import { getLocations } from "@/components/RegisterOfflineProduct/v1/RegisterOfflineHelper"
import KeySpecifierView from "@/components/KeySpecifier/v1/KeySpecifierView"
import useKeySpecifieri18n from "@/i18n/useKeySpecifieri18n"

import { sanitizeInnerHtml } from "@/utils/helper"

import {
  KEY_SPECIFIER_API_URL_ENDPOINT,
  KEY_SPECIFIER_QUERRY_SELECTOR_URL,
} from "@/constants"
import { redirectionToContentPage } from "@/components/FriendsAndFamily/v1/friendsAndFamilyHelper"

const KeySpecifier = props => {
  const isSsr = useIsSsr()
  const { data: authorData } = props
  const { emailBrand = "" } = authorData

  const i18n = useKeySpecifieri18n({
    authorData,
  })

  const { pageIsInteractive } = useSelector(selectGenericState)

  const {
    successMessage,
    successTitle,
    genError,
    codeInvalidError,
    continueShopping,
  } = i18n

  const {
    countryListPath,
    stateListPath,
    businessListPath,
    stateToCountryList: countries,
    countryList: countryLists,
  } = useSelector(selectGenericState)

  const [showSuccess, setShowSuccess] = useState(false)
  const [applyForm, setApplyForm] = useState(false)
  const [showAlertModal, setShowAlertModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [businessTypes, setBusinessTypes] = useState([])
  const [stateList, setStateList] = useState([])
  const [country, setCountry] = useState("")
  const [legalPageLinks, setLegalPageLinks] = useState("")
  const [nodeList, setNodeList] = useState([])
  const [btnLoaded, setBtnLoaded] = useState(false)
  const [general, setGeneral] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    redirectionToContentPage()
  }, [])

  useEffect(() => {
    dispatch(getGenericListPath())
    getConfig().then(config => {
      const legalPageLinks = _get(config, "legalPageData.map", {})
      const country = _get(config, "internationalization.country", "")
      const general = {
        country,
      }
      setGeneral(general)
      setLegalPageLinks(legalPageLinks)
      setCountry(country)
    })
  }, [])

  useEffect(() => {
    if (!showAlertModal || !showSuccess) {
      setBtnLoaded(false)
    } else {
      setBtnLoaded(true)
    }
  }, [showAlertModal, showSuccess])

  useEffect(() => {
    if (!isSsr && pageIsInteractive) {
      if (!btnLoaded) {
        setTimeout(function () {
          getNodeList()
        }, 1000)
      }
    }
  }, [isSsr, pageIsInteractive, btnLoaded, nodeList])

  const getNodeList = () => {
    if (document.querySelectorAll(KEY_SPECIFIER_QUERRY_SELECTOR_URL)) {
      setNodeList(document.querySelectorAll(KEY_SPECIFIER_QUERRY_SELECTOR_URL))
      showMembershipPopupHandler()
    }
  }

  const showMembershipPopupHandler = () => {
    nodeList.forEach(el => {
      el.addEventListener("click", e => {
        e.preventDefault()
        setShowAlertModal(true)
        setShowSuccess(false)
        setApplyForm(true)
      })
    })
  }

  useEffect(() => {
    try {
      if (!_isEmpty(countryListPath) && !_isEmpty(stateListPath)) {
        getLocations()
        getBusinessTypes()
      }
    } catch (error) {
      console.error(error)
    }
  }, [countryListPath, stateListPath, businessListPath])

  useEffect(() => {
    if (countries?.length) {
      const country =
        countryLists.find(country => country.value === general?.country)
          ?.text ?? ""
      const stateList =
        countries.find(country => country.code === "US")?.states ?? []
      setCountry(country)
      setStateList(stateList)
    }
  }, [countries])

  const showToaster = msg => {
    dispatch(
      showToast({
        message: msg,
        isVisible: true,
      })
    )
  }

  const handleClose = () => {
    setShowAlertModal(false)
    setShowSuccess(false)
  }

  const registerUser = (body, cb, analyticsCB) => {
    setLoading(true)

    validateRecaptcha()
      .then(res => {
        if (res.success) {
          apim
            .post(KEY_SPECIFIER_API_URL_ENDPOINT, body)
            .then(() => {
              setLoading(false)
              setShowAlertModal(false)
              setShowSuccess(true)
              setApplyForm(false)
              analyticsCB?.("success")
            })
            .catch(err => {
              console.error(err)
              setLoading(false)

              cb()
              analyticsCB?.("failure", codeInvalidError?.toLowerCase())
            })
        } else {
          setLoading(false)

          showToaster(genError)

          analyticsCB("failure", genError?.toLowerCase())
        }
      })
      .catch(err => {
        console.error(err)

        analyticsCB?.("failure", genError?.toLowerCase())
      })
  }
  const getBusinessTypes = async () => {
    const { payload: businessType } = await dispatch(getBusinessList())
    setBusinessTypes(businessType)
  }

  return (
    <Modal
      labelledBy="key-specifier__title"
      describedBy="key-specifier__description"
      showModal={showAlertModal || showSuccess}
      onModalClose={handleClose}
    >
      {applyForm ? (
        <KeySpecifierView
          showModal={showAlertModal}
          i18n={i18n}
          registerUser={registerUser}
          loading={loading}
          businessTypes={businessTypes}
          states={stateList}
          legalPages={legalPageLinks}
          countries={countries}
          country={country}
          emailBrand={emailBrand}
        />
      ) : null}
      {showSuccess ? (
        <div
          id="success-modelId"
          tabIndex="0"
          className="success-message-content"
        >
          <section className="success-modal-header">
            <h4>{successTitle}</h4>
            <div className="success-message-model">
              <span>{sanitizeInnerHtml(successMessage)}</span>
            </div>
          </section>
          <Button
            type="black"
            role="button"
            label={continueShopping}
            onClick={handleClose}
            className="gbh-data-layer"
          />
        </div>
      ) : null}
    </Modal>
  )
}

export default KeySpecifier
