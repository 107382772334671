import React, { useEffect, useRef, useState } from "react"
import LazyLoad from "react-lazy-load"
import { isMobile, withOrientationChange } from "react-device-detect"
import cx from "classnames"
import useWindowResize from "@/hooks/useWindowResize"
import useIsSsr from "@/hooks/useIsSsr"
import TechnicalSpecsNavHeader from "@/components/TechnicalSpecs/v1/TechnicalSpecsNavHeader"
import TechnicalTile from "@/components/Search/v1/TechnicalTile"
import Filter from "@/components/ProductList/v2/Filter"
import sortStyle from "@/components/ProductList/v2/Sort/index.module.scss"
import filterStyle from "@/components/ProductList/v2/Filter/index.module.scss"
import styles from "@/components/ProductList/v2/ProductListView/index.module.scss"
import technicalSpecsStyle from "@/components/TechnicalSpecs/v1/index.module.scss"
import productTileStyles from "@/components/ProductList/v2/ProductTile/index.module.scss"
import ProductPagination from "@/components/ProductList/v2/ProductListView/Pagination"
import TechnicalSpecPLPLoader from "@/components/TechnicalSpecs/TechnicalSpecLoader"
import { getOffsetTop } from "@/utils/helper"
import { DEFAULT_SCROLL } from "@/constants/index"

const TechnicalSpecsView = props => {
  const {
    data = [],
    sorts = [],
    curRows = 0,
    colors = {},
    filters = {},
    staticTexts = {},
    facetLabels = {},
    suggestions = [],
    currFilters = [],
    currencySign = "",
    selectedSort = {},
    totalResults = "",
    displayPrint = "",
    displayShare = "",
    onRows = () => {},
    onSort = () => {},
    isLoading = false,
    searchKeyword = "",
    onFilter = () => {},
    onSearch = () => {},
    productsPerPage = [],
    onSuggest = () => {},
    onLoadMore = () => {},
    page = "technicalspecs",
    selectedFilterCount = 0,
    clearSuggestions = () => {},
  } = props

  const [width] = useWindowResize()
  const isSsr = useIsSsr()

  const [showFilter, setShowFilter] = useState(
    isMobile || width <= 992 ? false : true
  )
  const filterModalWindow = useRef(null)
  const plpRef = useRef(null)
  const { backToTop } = DEFAULT_SCROLL

  const facets = { ...filters }
  delete facets["Products"]
  delete facets["Technical"]

  const tileClass = cx({
    technical: true,
    "product-listing__tile-col": true,
    "product-listing__tile-col--full-width": !showFilter,
  })

  const filterClass = cx({
    "product-listing__filter": true,
    "product-listing__filter--hide": !showFilter,
    "product-listing__filter--modal": showFilter,
  })

  useEffect(() => {
    const mainElement = document.getElementById("main-content")
    mainElement.addEventListener("keydown", removeTabIndex)
    return () => {
      mainElement.removeEventListener("keydown", removeTabIndex)
    }
  })

  const removeTabIndex = () =>
    document.getElementById("main-content").removeAttribute("tabindex")

  const handleKeyPress = e => {
    if (width < 992) {
      const focusElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), button:not([disabled]) [role="button]:not([hidden=true])'
      const focusContent =
        filterModalWindow.current.querySelectorAll(focusElements)
      const firstElem = focusContent[0]
      const lastElem = focusContent[focusContent.length - 1].disabled
        ? focusContent[focusContent.length - 2]
        : focusContent[focusContent.length - 1]
      if (e.key === "Escape") {
        setShowFilter(false)
      }
      if (e.key === "Tab") {
        if (
          e.shiftKey &&
          (document.activeElement === firstElem ||
            document.activeElement === filterModalWindow.current)
        ) {
          e.preventDefault()
          lastElem.focus()
        }
        if (!e.shiftKey && document.activeElement === lastElem) {
          e.preventDefault()
          firstElem.focus()
        }
      }
    }
  }

  const handleBackToTop = () => {
    const mainContent = document.getElementById("main-content")
    mainContent.tabIndex = 0
    mainContent.focus()
    window.scrollTo({
      top: getOffsetTop(mainContent) + backToTop,
      behavior: "smooth",
    })
  }

  const executeScroll = () => {
    plpRef?.current?.scrollIntoView({ behavior: "smooth" })
  }
  const handleSort = sort => {
    onSort(sort)
    executeScroll()
  }

  return (
    <div
      className={`${technicalSpecsStyle.technicalSpecsWrapper} ${styles.productListWrapper} ${productTileStyles.productTileWrapper} ${sortStyle.productListingSearchWrapper} ${filterStyle.productListingFilterWrapper}`}
    >
      <main
        className="product-list product-listing technical-specs"
        ref={plpRef}
      >
        {/* hero-banner */}
        <div className="kf-react-plp-container product-list__header">
          <div className="row">
            <div className="col-12 title">
              <div
                className="technical-specs__list__title"
                dangerouslySetInnerHTML={{ __html: staticTexts.technicalSpecs }}
              ></div>
            </div>
            <div className="col-12 description">
              <p className="product-list__description">
                {staticTexts.pageDescription}
              </p>
            </div>
          </div>
        </div>
        {isSsr ? (
          <TechnicalSpecPLPLoader />
        ) : (
          <>
            {/* controls */}
            <TechnicalSpecsNavHeader
              staticTexts={staticTexts}
              onSort={onSort}
              totalResults={totalResults}
              page={page}
              sorts={sorts}
              selectedSort={selectedSort}
              onSearch={onSearch}
              onSuggest={onSuggest}
              searchKeyword={searchKeyword}
              suggestions={suggestions}
              clearSuggestions={clearSuggestions}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />

            {/* product-list */}
            <div className="container kf-react-plp-container product-listing__tiles">
              <div className="product-listing__row d-block">
                <div className="filter-col">
                  {
                    <div
                      className={filterClass}
                      aria-modal
                      ref={filterModalWindow}
                    >
                      <div
                        className={`product-listing__filter--sticky`}
                        aria-hidden={!showFilter}
                        onKeyDown={handleKeyPress}
                      >
                        {facets && (
                          <Filter
                            page="technicalspecs"
                            texts={staticTexts}
                            currencySign={currencySign}
                            facetData={facets}
                            facetLabels={facetLabels}
                            onFilter={onFilter}
                            close={setShowFilter}
                            colors={colors}
                            displayShare={true}
                            displayPrint={true}
                            discontinuedCheck={true}
                            showFilter={showFilter}
                            showSort={width <= 991}
                            sort={sorts}
                            selectedSort={selectedSort}
                            onSortChange={handleSort}
                            hidePriceRange={true}
                            currFilters={currFilters}
                            selectedFilterCount={selectedFilterCount}
                            totalResults={totalResults}
                            addToUrl={true}
                            type="technical"
                          />
                        )}
                      </div>
                    </div>
                  }
                  {isLoading ? (
                    <TechnicalSpecPLPLoader isProductTile={true} />
                  ) : (
                    <div className={tileClass}>
                      <div className="product-listing__row row-cols-1">
                        {data.length ? (
                          data.map((product, i) => (
                            <React.Fragment key={i}>
                              <div className="product-listing__tile">
                                <LazyLoad className="kf-lazyload-wrapper">
                                  <TechnicalTile
                                    data={product}
                                    fullWidth={!showFilter}
                                    staticTexts={staticTexts}
                                    highlightText={searchKeyword}
                                    page="technicalspecs"
                                    currFilters={currFilters}
                                  />
                                </LazyLoad>
                              </div>
                            </React.Fragment>
                          ))
                        ) : (
                          <React.Fragment>
                            <div className="no-result__title" role="listitem">
                              <span>{staticTexts.notFoundText}</span>
                            </div>
                          </React.Fragment>
                        )}
                      </div>

                      <ProductPagination
                        page={"technicalspecs"}
                        removeLoadAnimation={true}
                        {...{
                          totalResults,
                          staticTexts,
                          onLoadMore,
                          curRows,
                          data,
                          displayPrint,
                          displayShare,
                          productsPerPage,
                          onRows,
                          handleBackToTop,
                          width,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </main>
    </div>
  )
}

export default withOrientationChange(TechnicalSpecsView)
