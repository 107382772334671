import {
  sanitizeTextForAnalytics,
  getCurrentSkuCouponDiscounts,
  getShortenedUrlwithGeneral,
} from "@/utils/helper"

const getProductAnalyticsData = (props, discountedPrice, general) => {
  const {
    productFeatures,
    discountPercent,
    name,
    productCategory,
    customerFacingSKU,
    badgeVal,
    skuId,
    price,
    images,
  } = props
  const productAnalyticsData = {
    desc: productFeatures
      ? sanitizeTextForAnalytics(productFeatures.join(". "))
      : "n/a",
    discountPrice: discountPercent > 0 ? discountedPrice : 0,
    discountPriceState:
      discountPercent > 0 ? "percentOff|" + discountPercent : "regular price",
    unitPrice: price,
    category: sanitizeTextForAnalytics(productCategory),
    color: "n/a",
    sku: skuId,
    name: sanitizeTextForAnalytics(name),
    image: images,
    customerFacingSKU:
      customerFacingSKU ||
      (general?.siteName ? general.siteName[0] + "-" + skuId : ""),
    skuInitials: customerFacingSKU
      ? customerFacingSKU.split("-")[0] + "-"
      : general?.siteName
      ? general.siteName[0] + "-"
      : "",
    discountName: badgeVal,
    superSku: false,
    selectedColor: "n/a",
    productSaleable: true,
  }

  return productAnalyticsData
}

export const getEventInfoObject = (
  eventNameText,
  buttonName,
  general,
  checkZipCode,
  productName = ""
) => {
  const findaProLandingPagePath = getShortenedUrlwithGeneral(
    general?.findaProLandingPagePath ?? "/",
    general
  )
  const ProductTitle = productName?.toLocaleLowerCase()
  const eventText = `installation services:install service:${eventNameText}`
  return {
    clickInternalLinks: "true",
    eventAction: eventText,
    eventName: eventText,
    eventType: "navigation",
    internalLinkName: buttonName ?? eventNameText,
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkPosition: "find installation services",
    internalLinkType: `installation services:${
      !checkZipCode ? "navigation" : checkZipCode
    }`,
    internalLinkURL:
      eventNameText === "find a pro"
        ? (findaProLandingPagePath?.indexOf("http") === -1
            ? window.location.origin
            : "") + findaProLandingPagePath
        : "n/a",
    internalLinkZoneName: `installation services:${ProductTitle}`,
  }
}

export const getProductInfoForAnalytics = (
  product,
  quantity,
  general,
  cart
) => {
  const { skuId, discountPercent, price, discountedPrice } = product
  const {
    productCouponCode,
    productCoupondiscount,
    globalDiscountNames,
    globalDiscountValues,
  } = getCurrentSkuCouponDiscounts({ cart, sku: skuId })

  const productAnalyticsData = getProductAnalyticsData(
    product,
    discountedPrice,
    general
  )

  const {
    desc = "n/a",
    unitPrice = 0,
    discountPriceState,
    category,
    color,
    customerFacingSKU,
    name,
    discountName,
  } = productAnalyticsData

  const productPrice = discountPercent > 0 ? discountedPrice : price

  let globalOffer = [discountName]
  let globalDiscount = 0

  if (globalDiscountNames?.length && globalDiscountValues) {
    globalOffer = [discountName, ...globalDiscountNames]
    globalDiscount += globalDiscountValues
  }

  const productInfo = {
    description: desc,
    frequency: "n/a",
    globalPromotionPrice:
      discountedPrice > 0 ? Number((price - 0).toFixed(2)) : 0,
    globalOffer: globalOffer.join("|") || "n/a",
    globalDiscount: globalDiscount ? Number(globalDiscount.toFixed(2)) : 0,
    isRecommended: "n/a",
    isSendNow: "n/a",
    isSubscription: "n/a",
    priceState: discountPriceState,
    productBasePrice: Number(unitPrice) || "n/a",
    productCategory: category || "n/a",
    productColor: color || "n/a",
    productCoupondiscount: productCoupondiscount || 0,
    productCouponCode: productCouponCode.join("|") || "n/a",
    productID: customerFacingSKU || "n/a",
    productName: name || desc || category || "n/a",
    productPartnerBuyNow: "n/a",
    productRoom: "service" || "n/a",
    productSalePrice: Number(productPrice),
    productSaleable: true,
    productStatus: "in stock",
    productSuperSku: false,
    productTileName: name || "n/a",
    quantity: quantity[skuId] || "n/a",
    defaultImageName: productAnalyticsData.image || "n/a",
    ratings: "n/a",
    numberOfReviews: "n/a",
    pdpType: "regular finished goods & service parts",
    productFindingMethod: "n/a",
    productCollectionsName: category || "n/a",
    productBrand: general?.siteName?.toLowerCase(),
    itemType: "service" || "n/a",
  }
  return productInfo
}
