import { useTranslation } from "next-i18next"

const useLiteraturei18n = authorData => {
  const { t } = useTranslation()

  const i18n = {
    print: t("kf.plp.label.print"),
    share: t("kf.plp.label.share"),
    wishlist: t("kf.plp.label.wishlist"),
    showFilter: t("kf.plp.label.showFilter"),
    hideFilter: t("kf.plp.label.hideFilter"),
    filters: t("kf.plp.label.filters"),
    view: t("kf.plp.label.view"),
    all: t("kf.plp.label.all"),
    sort: t("kf.plp.label.sort"),
    sortbyExpanded: t("kf.plp.label.sortbyExpanded"),
    sortbyClosed: t("kf.plp.label.sortbyClosed"),
    selected: t("kf.plp.arialabel.selected"),
    altSort: t("kf.plp.label.img.altSort"),
    applyFilters: "Apply Filters",
    loadMore: t("kf.plp.label.loadMore"),
    backToTop: t("kf.plp.label.backToTop"),
    showMore: t("kf.plp.label.showMore"),
    showLess: t("kf.plp.label.showLess"),
    clearAll: t("kf.plp.label.clearAll"),
    relevancy: t("kf.plp.label.relevancy"),
    date: t("kf.plp.label.date"),
    atoz: t("kf.plp.label.atoz"),
    ztoa: t("kf.plp.label.ztoa"),
    loading: t("kf.search.label.loading"),
    resources: authorData?.resultstext || t("kf.literature.label.resources"),
    viewResults: t("kf.plp.label.viewResults"),
    results: t("kf.plp.label.results"),
    download: t("kf.literature.label.download"),
    viewLiterature: t("kf.literature.label.view"),
    favSuccessMsg: t("kf.favorites.success"),
    favErrorMsg: t("kf.favorites.error"),
    items: t("kf.plp.label.items"),
    etc: t("kf.plp.label.etc"),
    gridView: t("kf.plp.label.gridView"),
    columnView: t("kf.plp.label.columnView"),
    ariaLabel: {
      loadMore: t("kf.literature.aria.label.loadMore"),
      pdf: t("kf.literature.aria.label.pdf"),
      columnViewSelected: t("kf.productTab.aria.columnViewSelected"),
      columnViewNotSelected: t("kf.productTab.aria.columnViewNotSelected"),
      gridViewSelected: t("kf.productTab.aria.gridViewSelected"),
      gridViewNotSelected: t("kf.productTab.aria.gridViewNotSelected"),
    },
    arialLabelPrint: val => t("kf.plp.label.aria.print", { val: val }),
    arialLabelShare: val =>
      t("kf.plp.label.aria.share", { val: `${val} page` }),
    filterTitle: (facetName, facetCount) => {
      return t("kf.plp.ariaLabel.filterTitle", {
        facetName: facetName,
        facetCount: facetCount,
      })
    },
    newToOld: t("kf.literature.label.newToOld"),
    oldToNew: t("kf.literature.label.oldToNew"),
    removeItem: t("kf.favorites.removeItem"),
    removeItemError: t("kf.favorites.removeItemError"),
    sortByAria: count => t("kf.plp.label.sortByAria", { sortByCount: count }),
  }

  return i18n
}

export default useLiteraturei18n
