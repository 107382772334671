import { useTranslation } from "next-i18next"

const useMystorei18n = ({ authorData }) => {
  const { t } = useTranslation()
  const i18n = {
    countryLabel: authorData.countryLabel
      ? authorData.countryLabel
      : t("kf.browseallstore.label.countryLabel"),
    toggleFilter: t("kf.browseallstore.label.toggleFilter"),
    filters: authorData.toggleFilter
      ? authorData.toggleFilter
      : t("kf.plp.label.filters"),
    applyFilters: t("kf.findstore.label.applyFilters"),
    setAsMyStore: t("kf.findStoreUtilNav.label.setAsMystore"),
    clearAll: t("kf.plp.label.clearAll"),
    home: authorData.home ? authorData.home : t("kf.browseallstore.label.home"),
    findStore: authorData.findStore
      ? authorData.findStore
      : t("kf.browseallstore.label.findStore"),
    browseAllStore: authorData.browseAllStore
      ? authorData.browseAllStore
      : t("kf.browseallstore.label.browseAllStore"),
    myStore: t("kf.findStoreUtilNav.label.myStore"),
    viewResults: t("kf.plp.label.viewResults"),
    view: t("kf.plp.label.view"),
    results: t("kf.plp.label.results"),
    ariaLabel: {
      currentPage: t("kf.pdp.label.aria.currentPage"),
      page: t("kf.pdp.label.aria.page"),
    },
  }

  return i18n
}

export default useMystorei18n
